import { Alert, Snackbar } from '@mui/material';
import { createContext, useState } from 'react';

const defaultSettings = {
    show: false,
    duration: 5000,
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    alertVariant: 'filled',
    alertSeverity: 'success',
    alertText: '',
};

export const SnackbarContext = createContext({
    settings: defaultSettings,
});

const SnackbarProvider = ({ children }) => {
    const [settings, setSettings] = useState(defaultSettings);

    const showSnackbar = props => {
        setSettings(prev => ({ ...prev, ...props }));
        setTimeout(() => {
            setSettings(prev => ({ ...prev, show: true }));
        }, 100);
    };

    const handleClose = () => {
        setSettings(prev => ({ ...prev, show: false }));
        setTimeout(() => {
            setSettings(defaultSettings);
        }, 1000);
    };

    return (
        <SnackbarContext.Provider
            value={{
                showSnackbar,
            }}
        >
            {children}
            <Snackbar
                open={settings.show}
                autoHideDuration={settings.duration}
                onClose={handleClose}
                anchorOrigin={settings.anchorOrigin}
            >
                <Alert variant={settings.alertVariant} severity={settings.alertSeverity}>
                    {settings.alertText}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider;
