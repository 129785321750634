import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    countriesItems: [],
    routes: [],
    fareRules: [],
    included: [],
    recId: null,
    totalPrice: null,
    partnerFees: null,
    clientFees: null,
    isLoad: false,
    passengersList: null,
    filterString: null,
    flightType: null,
};

export const prebookSlice = createSlice({
    name: 'prebook',
    initialState,
    reducers: {
        setPrebookData: (state, action) => {
            return {
                ...state,
                routes: action.payload.routes,
                included: action.payload.included,
                recId: action.payload.recId,
                totalPrice: action.payload.totalPrice,
                partnerFees: action.payload.partnerFees,
                clientFees: action.payload.clientFees,
                fareRules: action.payload.fareRules,
                isLoad: action.payload.loadStatus,
                flightType: action.payload.flightType,
            };
        },
        setCountries: (state, action) => {
            return {
                ...state,
                countriesItems: action.payload,
            };
        },
        setPassengersList: (state, action) => {
            return {
                ...state,
                passengersList: action.payload,
            };
        },
        setPassengersListFilter: (state, action) => {
            return {
                ...state,
                filterString: action.payload.filterString,
            };
        },
    },
});

export const { setPrebookData, setCountries, setPassengersList, setPassengersListFilter } = prebookSlice.actions;

export default prebookSlice.reducer;
