import i18next from 'i18next';
import noun from 'plural-ru';
import { useSelector } from 'react-redux';
import { getSupplierIcon } from '../../../../../lib/getImage';
import FlightInfoIcons from '../../../pages/resultPage/flightInfoIcons';
import {
    AdditionalInfo,
    AirlineLogo,
    AirlineLogoBlock,
    DurationInfo,
    FlightIconsAndBaggage,
    FlightInfo,
    FlightPartInfo,
    SegmentBaggage,
    SegmentServiceClass,
    TransferBlock,
    TransferIconBlock,
    TransfersArrow,
    TransferTime,
    Wrapper,
} from './components';
import { ReactComponent as TransferIcon } from '../../../../../assets/somon_air/icons/transfer.svg';
import { getTransferTime } from '../../../pages/resultPage/utils';

const SegmentItem = ({ segment, index, route }) => {
    const { included } = useSelector(state => state.prebook);
    const { language, t } = i18next;

    const getDuration = () => {
        const hours = Math.trunc(segment.duration / 3600);
        const minutes = Math.floor((segment.duration - hours * 3600) / 60);

        return `${hours} ${t('hour_short')} ${minutes} ${t('minutes_short_3')}`;
    };

    const getServiceClass = () => {
        const { code, name } = segment.service_class;

        if (code) {
            return `${name} (${code})`;
        }

        return name;
    };

    const getTransferText = () => {
        const departureCityCode = segment.departure.city;
        const departureAirportCode = segment.departure.airport;
        const departureCountryCode = included.airport[departureAirportCode].country;

        return `${t('transfer')} ${getTransferTime(route, index, t('hour_short'), t('minutes_short_3'))} • ${
            included.city[departureCityCode].name[language]
        }, ${included.country[departureCountryCode].name[language]}`;
    };

    return (
        <>
            {index > 0 && (
                <TransferBlock>
                    <TransferIconBlock>
                        <TransferIcon />
                    </TransferIconBlock>
                    <TransferTime>{getTransferText()}</TransferTime>
                </TransferBlock>
            )}
            <Wrapper>
                <AirlineLogoBlock>
                    <AirlineLogo image={getSupplierIcon(segment.operation_supplier)} />
                </AirlineLogoBlock>

                <FlightInfo>
                    <FlightPartInfo>
                        <span className="date">{segment.departure.time.split(' ')[0]}</span>
                        <span className="time">{segment.departure.time.split(' ')[1]}</span>
                        <span className="city">{included.city[segment.departure.city].name[language]}</span>
                        <span className="airport">{included.airport[segment.departure.airport].name[language]}</span>
                        <span className="iataCode">{segment.departure.airport}</span>
                    </FlightPartInfo>

                    <DurationInfo>
                        <span className="duration">
                            {t('on_way')}: <span>{getDuration()}</span>
                        </span>

                        <TransfersArrow>
                            <span className="circle" />
                            <span className="line" />
                            <span className="circle-bottom" />
                        </TransfersArrow>
                    </DurationInfo>

                    <FlightPartInfo>
                        <span className="date">{segment.arrival.time.split(' ')[0]}</span>
                        <span className="time">{segment.arrival.time.split(' ')[1]}</span>
                        <span className="city">{included.city[segment.arrival.city].name[language]}</span>
                        <span className="airport">{included.airport[segment.arrival.airport].name[language]}</span>
                        <span className="iataCode">{segment.arrival.airport}</span>
                    </FlightPartInfo>
                </FlightInfo>

                <AdditionalInfo>
                    <SegmentServiceClass>
                        {t('class')}: <span>{getServiceClass()}</span>
                    </SegmentServiceClass>

                    <FlightIconsAndBaggage>
                        <FlightInfoIcons data={segment} marginBottom={0} />
                        <SegmentBaggage>
                            {segment.baggage
                                ? `${t('baggage')}: ${segment.baggage} ${noun(
                                      segment.baggage,
                                      t('place_one'),
                                      t('place_more'),
                                      t('place_many')
                                  )}`
                                : t('no_baggage')}
                        </SegmentBaggage>
                        {segment.hand_luggage ? (
                            <SegmentBaggage>
                                {t('hand_luggage')}: {segment.hand_luggage}{' '}
                                {noun(segment.hand_luggage, t('place_one'), t('place_more'), t('place_many'))}
                            </SegmentBaggage>
                        ) : null}
                    </FlightIconsAndBaggage>
                </AdditionalInfo>
            </Wrapper>
        </>
    );
};

export default SegmentItem;
