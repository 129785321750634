import { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import { forgotPassword } from '../../../../protocols/rest/services';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { Container } from '../../ui/common';
import { Wrapper, MainGrid, Item, Title, Label, Button } from './components.js';
import { CircularProgress } from '@mui/material';
import InputText from '../../ui/input';


const ForgotPasswordPage = () => {
    const { t } = i18next;
    const history = useNavigate();

    const { isAuth } = useSelector(state => state.authSettings);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { showSnackbar } = useContext(SnackbarContext);

    useEffect(() => {
        if (isAuth) {
            history('/');
        }
    }, []);
    const validationSchema = Yup.object().shape({
        email: Yup.string().trim().email(t('enter_valid_mail')).required(t('required')),
    });

    const handleForgotPasswordFormSubmit = values => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        forgotPassword(values)
            .then(response => {
                if (!response.error) {
                    setSuccess(true);
                    setIsLoading(false);
                    showSnackbar({
                        alertText: `${t('forgot_success_content')}`,
                    });
                } else {
                    forgotPasswordForm.setFieldError('email', response.message);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const forgotPasswordForm = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: handleForgotPasswordFormSubmit,
        validateOnBlur: true,
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = forgotPasswordForm;

    return (
        <Container>
            <Wrapper>
                <Title>{t('forgot_password')}</Title>
                <MainGrid>
                    <Item>
                        <Label>{t('email')}</Label>
                        <InputText
                            value={values.email}
                            onChange={handleChange('email')}
                            type="email"
                            required
                            onBlur={handleBlur('email')}
                            error={errors.email}
                            touched={touched.email}
                        />
                    </Item>
                </MainGrid>
                {(success || !values.email.length) ? (
                    <Button disabled>{t('reset')}</Button>
                ) : (
                    <Button onClick={handleSubmit}>
                        {isLoading ? <CircularProgress color="inherit" size={26} /> : `${t('reset')}`}
                    </Button>
                )}
            </Wrapper>
        </Container>
    );
};

export default ForgotPasswordPage;