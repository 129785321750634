import styled from 'styled-components';
import {
    BRIGHT_COLOR,
    ORANGE_COLOR,
    WHITE_COLOR,
    DIM_GREY_COLOR,
    DARK_COLOR,
    FLORAL_WHITE_COLOR,
    ZAMBEZI_COLOR,
    MIDNIGHT_COLOR,
    GREEN_COLOR,
    DARK_RED_COLOR,
    RHINO_COLOR,
} from '../../constants';
export const MainHeader = styled.h1`
    height: 41px;
    margin: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    font-family: Raleway;
    font-weight: bold;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    opacity: 1;
    @media (max-width: 959px) {
        font-size: 21px;
        margin: 44px auto;
    }
`;

export const TableBlock = styled.div`
    overflow-y: auto;
    margin-bottom: 24px;
    box-shadow: 0px 0px 1px ${MIDNIGHT_COLOR};
    border-radius: 5px;
`;

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 16px;
    min-width: 550px;
    overflow-x: scroll;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
`;

export const TableHead = styled.thead`
    height: 79px;
`;

export const Tr = styled.tr`
    height: 75px;
    &:nth-child(even) {
        background-color: ${FLORAL_WHITE_COLOR};
    }
`;

export const Th = styled.th`
    height: 31px;
    font: normal normal bold 27px/31px Raleway;
    letter-spacing: 0.27px;
    color: ${DARK_COLOR};
    border: none;
    text-align: center;
    background-color: ${ORANGE_COLOR};
    &:nth-child(2) {
        border-right: 2px solid ${DIM_GREY_COLOR};
        border-left: 2px solid ${DIM_GREY_COLOR};
    }
    &:nth-child(3) {
        border-right: 2px solid ${DIM_GREY_COLOR};
        border-left: 2px solid ${DIM_GREY_COLOR};
    }
    &:nth-child(4) {
        border-right: 2px solid ${DIM_GREY_COLOR};
        border-left: 2px solid ${DIM_GREY_COLOR};
    }
    @media (max-width: 959px) {
        font-size: 18px;
        min-width: 160px;
    }
`;

export const Td = styled.td`
    border: none;
    padding: 30px;
    text-align: left;
    font-size: 25px;
    font-family: Rubik;
    font-weight: 300;
    letter-spacing: 0px;

    &:first-child() {
        color: ${ZAMBEZI_COLOR};
    }
    &:nth-child(2) {
        color: ${GREEN_COLOR};
        border-right: 2px solid ${DIM_GREY_COLOR};
        border-left: 2px solid ${DIM_GREY_COLOR};
    }
    &:nth-child(3) {
        color: ${DARK_RED_COLOR};
        border-right: 2px solid ${DIM_GREY_COLOR};
        border-left: 2px solid ${DIM_GREY_COLOR};
    }
    &:nth-child(4) {
        color: ${DARK_COLOR};
        border-right: 2px solid ${DIM_GREY_COLOR};
        border-left: 2px solid ${DIM_GREY_COLOR};
    }
    &:nth-child(5) {
        letter-spacing: 0px;
        color: ${ZAMBEZI_COLOR};
    }
    @media (max-width: 959px) {
        font-size: 16px;
    }
`;

export const NoResultsBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 20px;
    font-weight: bold;
    color: ${RHINO_COLOR};
`;

export const PaginationBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
`;
