import styled from 'styled-components';
import { MIDNIGHT_COLOR, WHITE_COLOR } from '../../../constants';

export const Wrapper = styled.div`
    max-width: 629px;
    margin-right: 42px;

    @media (max-width: 1279px) {
        top: unset;
        bottom: 100px;
        margin-bottom: 0;
        width: 50px;
        margin: auto;
        box-shadow: none;
    }
`;

export const DesktopBlock = styled.div`
    display: block;
    padding: 8px 16px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 10px ${MIDNIGHT_COLOR};
    border-radius: 5px;
    background-color: ${WHITE_COLOR};
    max-width: 429px;

    @media (max-width: 1279px) {
        display: none;
    }
`;
export const ContainerFilter = styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 899px) {
        flex-direction: column;
    }
`;
export const MobileBlock = styled.div`
    display: none;

    @media (max-width: 1279px) {
        display: block;
    }
`;
