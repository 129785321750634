import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterBlock from '../filterBlock';
import { setFilterParams } from '../../../../../../../store/filtersSlice';
import { BY_MAX_PRICE, BY_MIN_PRICE } from '../../../../../../../containers/result/constants';
import { Block, Row, Text, Checkbox } from './components';
import i18next from 'i18next';

const SortingFilter = () => {
    const { filterParams } = useSelector(state => state.filters);
    const { sorting } = filterParams;
    const { t } = i18next;
    const dispatch = useDispatch();

    const sortingTypes = [
        { value: BY_MIN_PRICE, label: t('label_price_by_min') },
        { value: BY_MAX_PRICE, label: t('label_price_by_max') },
    ];

    const handleOnClick = value => {
        dispatch(
            setFilterParams({
                type: 'sorting',
                values: {
                    isActive: value === BY_MAX_PRICE,
                    value,
                },
            })
        );
    };

    return (
        <FilterBlock title={t('sorting') || 'Сортировка'} isOpen borderBottom>
            <Block>
                {sortingTypes.map(type => (
                    <Row key={`${type.value}`} onClick={() => handleOnClick(type.value)}>
                        <Checkbox className={type.value === sorting.value && 'active'} />
                        <Text className={type.value === sorting.value && 'active'}>{type.label}</Text>
                    </Row>
                ))}
            </Block>
        </FilterBlock>
    );
};

export default SortingFilter;
