import i18next from 'i18next';
import { useSelector } from 'react-redux';
import {
    Block,
    Blocks,
    BlockTitle,
    BlockValues,
    BookingInfoBlock,
    BookingInfoRow,
    BookingInfoTitle,
    BookingInfoValue,
    BuyerInfoBlock,
    DividerLine,
    Wrapper,
} from './components';
import Passengers from './passengers';

const OrderInfo = () => {
    const { t } = i18next;
    const { booking_number, airline_booking_number, payer_email, payer_phone } = useSelector(
        state => state.order.orderData
    );

    return (
        <Wrapper>
            <Blocks>
                <Block>
                    <BlockTitle>{t('book')}</BlockTitle>

                    <BlockValues>
                        <BookingInfoBlock>
                            <BookingInfoRow>
                                <BookingInfoTitle>{t('pnr')}</BookingInfoTitle>
                                <BookingInfoValue>{booking_number}</BookingInfoValue>
                            </BookingInfoRow>

                            <BookingInfoRow>
                                <BookingInfoTitle>{t('online_registration_locator')}</BookingInfoTitle>
                                <BookingInfoValue>{airline_booking_number}</BookingInfoValue>
                            </BookingInfoRow>
                        </BookingInfoBlock>
                    </BlockValues>
                </Block>

                <DividerLine />
                
                <Block>
                    <BlockTitle>{t('passengers')}</BlockTitle>
                    <BlockValues>
                        <Passengers />
                    </BlockValues>
                </Block>
                
                <DividerLine />
                
                <Block>
                    <BlockTitle>{t('buyer')}</BlockTitle>
                    <BlockValues>
                        <BuyerInfoBlock>
                            <div>{payer_email}</div>
                            <div>{payer_phone}</div>
                        </BuyerInfoBlock>
                    </BlockValues>
                </Block>
            </Blocks>
        </Wrapper>
    );
};

export default OrderInfo;
