import { useCallback, useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Button, Item, MainGrid, SubGrid, Title, Wrapper } from './components';
import { Container } from '../../ui/common';
import { useDispatch, useSelector } from 'react-redux';
import InputText from '../../ui/input';
import { setAuthSettings } from '../../../../store/authSettingsSlice';
import { editProfile } from '../../../../protocols/rest/services/user/index';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CircularProgress } from '@mui/material';
import { setCurrencySettings } from '../../../../store/currencySettingsSlice';
import { useNavigate } from 'react-router';
import { SnackbarContext } from '../../../../contexts/snackbarContext';

function UserEdit(props) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { firstName, lastName, fatherName, email, login, phone, balance } = useSelector(state => state.authSettings);
    const { currency } = useSelector(state => state.currencySettings);
    const { showSnackbar } = useContext(SnackbarContext);

    const { t } = i18next;
    const history = useNavigate();

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleKeyPress);
        };
    }, []);

    const handleKeyPress = useCallback(e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            handleSubmit();
        }
    }, []);

    const validationSchema = Yup.object().shape({
        login: Yup.string().trim().email(t('enter_valid_mail')).required(t('required')),
        phone: Yup.string().required(t('required')),
        first_name: Yup.string().trim().min(2, t('min_2_symbol')).required(t('required')),
        last_name: Yup.string().trim().min(2, t('min_2_symbol')).required(t('required')),
        father_name: Yup.string().trim().min(2, t('min_2_symbol')),
        password: Yup.string().min(6, t('min_6_symbol')),
        confirmationPassword: Yup.string()
            .min(6, t('min_6_symbol'))
            .oneOf([Yup.ref('password'), null], t('passwords_must_match')),
    });

    const SubmitUpdates = values => {
        if (isLoading) {
            return;
        }

        let newValues = { ...values };
        if (newValues.phone) {
            newValues.phone = `${values.phone.replace(/[^\d]/g, '')}`;
        }
        setIsLoading(true);

        editProfile(newValues)
            .then(response => {
                dispatch(
                    setAuthSettings({
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        fatherName: response.data.father_name,
                        email: response.data.email,
                        phone: response.data.phone,
                    })
                );
                setIsLoading(false);
                showSnackbar({
                    alertText: `${t('profile_changed')}!`,
                });
                history('/profile');
            })
            .catch(err => {
                console.log('error');
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            login: login,
            phone: phone,
            first_name: firstName,
            last_name: lastName,
            father_name: fatherName,
            email: email,
            password: '',
            confirmationPassword: '',
        },
        validationSchema,
        onSubmit: SubmitUpdates,
        validateOnBlur: true,
    });

    return (
        <Container>
            <Wrapper>
                <Title>{t('edit_personal_data')}</Title>
                <MainGrid>
                    <SubGrid>
                        <InputText
                            value={values.first_name}
                            onChange={handleChange('first_name')}
                            type="text"
                            required
                            error={errors.first_name}
                        />
                        <InputText
                            value={values.last_name}
                            onChange={handleChange('last_name')}
                            type="text"
                            required
                            error={errors.first_name}
                        />
                        <InputText
                            placeholder={t('father_name')}
                            value={values.father_name}
                            onChange={handleChange('father_name')}
                            type="text"
                            required
                            error={errors.father_name}
                        />
                        <InputText
                            placeholder={t('email')}
                            value={values.email}
                            onChange={handleChange('email')}
                            type="email"
                            required
                            error={errors.email}
                        />
                        <InputText placeholder={t('login')} value={values.login} disabled />
                        <InputText placeholder={`${balance} ${currency}`} value={values.balance} disabled />
                        <InputText
                            placeholder={t('phone')}
                            value={values.phone}
                            onChange={handleChange('phone')}
                            type="text"
                            onBlur={handleBlur('phone')}
                            touched={touched.phone}
                            required
                            error={errors.phone}
                        />
                        <InputText
                            placeholder={t('password_text')}
                            value={values.password}
                            onChange={handleChange('password')}
                            type="password"
                            onBlur={handleBlur('password')}
                            touched={touched.password}
                            error={errors.password}
                        />
                        <InputText
                            placeholder={t('confirmation_activation_password')}
                            value={values.confirmationPassword}
                            onChange={handleChange('confirmationPassword')}
                            type="password"
                            onBlur={handleBlur('confirmationPassword')}
                            touched={touched.confirmationPassword}
                            error={errors.confirmationPassword}
                        />

                        <Button type="button" onClick={handleSubmit}>
                            {isLoading ? <CircularProgress color="inherit" size={26} /> : t('save')}
                        </Button>
                    </SubGrid>
                </MainGrid>
            </Wrapper>
        </Container>
    );
}

export default UserEdit;
