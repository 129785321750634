import { authorizedInstance, baseParams, basicInstance } from '../..';

const paymentSystemsResultFormat = data => {
    return data.map(item => ({
        id: item['id'],
        code: item['code'],
        logo: item['logo'],
        name: item['name'],
        description: JSON.parse(item['description']),
    }));
};

export const getPaymentSystems = async () => {
    return basicInstance()
        .get('paymentSystems', { params: baseParams })
        .then(response => paymentSystemsResultFormat(response.data.data));
};

export const ticketing = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return authorizedInstance()
        .post('ticketing', params)
        .then(response => response.data.data);
};

export const getTransactions = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return authorizedInstance()
        .get('transactions', { params })
        .then(response => response.data);
};

export const addBalance = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return authorizedInstance()
        .post('add-balance', params)
        .then(response => response.data);
};
