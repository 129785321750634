import { CircularProgress } from '@mui/material';
import { BRIGHTER_YELLOW_COLOR, BRIGHT_COLOR, DARK_YELLOW_COLOR } from '../../constants';
import { FilledButton, IconButton, OutlineButton, TextButton, TransitionIcon } from './components';

const Button = ({
    title = '',
    onClick = () => null,
    type = 'button',
    style = 'filled',
    icon = null,
    iconTransition = false,
    iconBackgroundColor = BRIGHTER_YELLOW_COLOR,
    height = 64,
    backgroundColor = DARK_YELLOW_COLOR,
    borderRadius = 5,
    borderColor = DARK_YELLOW_COLOR,
    fontSize = 18,
    mobileFontSizeMultiplier = 0.8,
    fontWeight = 'bold',
    textTransform = 'uppercase',
    color = BRIGHT_COLOR,
    loading = false,
    loadingColor = BRIGHT_COLOR,
    loadingSize = 28,
    padding = '0',
    mobilePadding = '0',
    disabled = false,
}) => {
    const Loading = () => <CircularProgress style={{ color: loadingColor }} size={loadingSize} />;

    if (style === 'outline') {
        return (
            <OutlineButton
                onClick={onClick}
                height={height}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                borderRadius={borderRadius}
                fontSize={fontSize}
                mobileFontSizeMultiplier={mobileFontSizeMultiplier}
                fontWeight={fontWeight}
                textTransform={textTransform}
                color={color}
                transitionIcon={iconTransition}
                className={iconTransition && !loading ? 'transition' : ''}
                type={type}
                disabled={disabled}
            >
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {title}
                        {iconTransition ? (
                            <TransitionIcon
                                borderColor={borderColor}
                                borderRadius={borderRadius}
                                backgroundColor={iconBackgroundColor}
                                className="transition"
                            >
                                {icon}
                            </TransitionIcon>
                        ) : (
                            icon
                        )}
                    </>
                )}
            </OutlineButton>
        );
    }

    if (style === 'text') {
        return (
            <TextButton
                onClick={onClick}
                fontSize={fontSize}
                mobileFontSizeMultiplier={mobileFontSizeMultiplier}
                fontWeight={fontWeight}
                textTransform={textTransform}
                color={color}
                type={type}
                disabled={disabled}
            >
                {loading ? <Loading /> : title}
            </TextButton>
        );
    }

    if (style === 'icon') {
        return (
            <IconButton
                onClick={onClick}
                height={height}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                borderRadius={borderRadius}
                fontSize={fontSize}
                mobileFontSizeMultiplier={mobileFontSizeMultiplier}
                fontWeight={fontWeight}
                textTransform={textTransform}
                color={color}
                type={type}
                disabled={disabled}
            >
                {loading ? <Loading /> : icon}
            </IconButton>
        );
    }

    return (
        <FilledButton
            onClick={onClick}
            height={height}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            borderRadius={borderRadius}
            fontSize={fontSize}
            mobileFontSizeMultiplier={mobileFontSizeMultiplier}
            fontWeight={fontWeight}
            textTransform={textTransform}
            color={color}
            transitionIcon={iconTransition}
            className={iconTransition && !loading ? 'transition' : ''}
            type={type}
            padding={padding}
            mobilePadding={mobilePadding}
            disabled={disabled}
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    {title}
                    {iconTransition ? (
                        <TransitionIcon
                            borderColor={borderColor}
                            borderRadius={borderRadius}
                            backgroundColor={iconBackgroundColor}
                            className="transition"
                        >
                            {icon}
                        </TransitionIcon>
                    ) : (
                        icon
                    )}
                </>
            )}
        </FilledButton>
    );
};

export default Button;
