import React, { Suspense } from 'react';
import MainLoading from './components/somon_air/mainLoading';
import RoutesBlock from './routes';

const App = () => {
    return (
        <Suspense fallback={<MainLoading />}>
            <RoutesBlock />
        </Suspense>
    );
};

export default App;
