import styled from 'styled-components';
import {
    BRIGHT_COLOR,
    ORANGE_COLOR,
    DARK_COLOR,
    GREY_DARKER_COLOR,
    MIDNIGHT_COLOR,
    LIGHT_GREY_COLOR,
} from '../../constants';

export const Wrapper = styled.main``;

export const Title = styled.div`
    margin: 100px auto 56px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    font-family: Raleway;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};

    @media (max-width: 1279px) {
        margin: 30px auto;
    }

    @media (max-width: 959px) {
        font-size: 21px;
    }
`;
export const MainGrid = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 1rem;
    @media (max-width: 959px) {
        grid-template-rows: 1fr;
    }
`;
export const SubGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    row-gap: 2rem;
    @media (max-width: 959px) {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
`;
export const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    height: 70px;
    background: ${LIGHT_GREY_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px ${MIDNIGHT_COLOR};
    border-radius: 5px;
`;
export const Label = styled.div`
    font: italic normal 300 16px/19px Raleway;
    letter-spacing: 0px;
    color: ${GREY_DARKER_COLOR};


`;
export const Value = styled.div`
    text-align: left;
    font: normal normal bold 20px/30px Raleway;

    @media screen and (max-width: 600px){
        font-size: 18px
    }

    letter-spacing: 0.25px;
    color: ${DARK_COLOR};
`;

export const Button = styled.button`
    height: 70px;
    background: ${ORANGE_COLOR} 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    text-align: center;
    font: normal normal bold 20px/30px Raleway;
    letter-spacing: 0.25px;
    color: ${DARK_COLOR};
    padding: 22px;
`;
