import styled from 'styled-components';
import { DOVE_GRAY, RUBIK_FONT, ZAMBEZI_COLOR } from '../../../constants';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 690px;

    @media (min-width: 1280px) and (max-width: 1529px) {
        width: calc(600px + (690 - 600) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(420px + (600 - 420) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        width: calc(390px + (590 - 390) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        width: 100%;
    }
`;

export const PassengerItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const PassengerRow = styled.div`
    display: flex;

    @media (max-width: 599px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
`;

export const PassengerName = styled.div`
    font-family: ${RUBIK_FONT};
    font-size: 22px;
    color: ${ZAMBEZI_COLOR};

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: calc(18px + (22 - 18) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        font-size: calc(18px + (22 - 18) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        font-size: 18px;
    }
`;

export const PassengerParamTitle = styled.div`
    width: 230px;
    font-family: ${RUBIK_FONT};
    color: ${DOVE_GRAY};
    font-weight: 300;

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(150px + (230 - 150) * ((100vw - 900px) / (1280 - 900)));
        font-size: 14px;
    }

    @media (min-width: 600px) and (max-width: 899px) {
        width: calc(150px + (230 - 150) * ((100vw - 600px) / (900 - 600)));
        font-size: 14px;
    }

    @media (max-width: 599px) {
        width: auto;
        font-size: 14px;
    }
`;

export const PassengerParamValue = styled.div`
    flex-grow: 1;
    font-family: ${RUBIK_FONT};
    color: ${DOVE_GRAY};

    @media (min-width: 1280px) and (max-width: 1529px) {
        width: calc(370px + (460 - 370) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(270px + (370 - 270) * ((100vw - 900px) / (1280 - 900)));
        font-size: 14px;
    }

    @media (min-width: 600px) and (max-width: 899px) {
        width: calc(250px + (360 - 250) * ((100vw - 600px) / (900 - 600)));
        font-size: 14px;
    }

    @media (max-width: 599px) {
        width: auto;
        font-size: 14px;
    }
`;
