import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/tg';
import 'moment/locale/ru';

import { getChangeBaggage } from '..';
import { authorizedInstance, baseParams, basicInstance } from '../..';
import { mainConfigs } from '../../../../configs';
import {
    INITIAL_CODE,
    BOOKING_FAIL_CODE,
    PAY_FAIL_CODE,
    BALANCE_PAYMENT_APPROVED_CODE,
    BALANCE_PAYMENT_REQUEST_CODE,
    ORDER_FAIL_CODE,
    BOOK_DONE_CODE,
    BOOK_STATUS_CODES,
    BOOKING_DONE_CODE,
    IN_PROGRESS_BOOK_CODE,
    BOOK_STATUS_COLOR,
    PARTIAL_REFUND_CODE,
    APPLICATION_REFUND_CODE,
    PAY_SUCCESS_CODE,
    TICKETING_FAIL_CODE,
    VOID_CODE,
    REFUND_CODE,
    TICKETING_DONE_CODE,
    CERTIFICATE_ISSUED_CODE,
} from '../../../../utils/constants';
import { formattedDate, formattedDateWithTimeZone } from '../../../../utils/dateTime';

const orderResultFormat = order => {
    let currentOrder = { ...order };
    currentOrder['timestamp'] = formattedDateWithTimeZone(currentOrder['timestamp']);

    if (_.isNull(order['booking_id'])) {
        currentOrder['brokenOrder'] = true;
        currentOrder['order_status'] = ORDER_FAIL_CODE;
    } else {
        if (currentOrder['timelimit']) {
            const timelimitWithoutTz = currentOrder['timelimit'].split('+');
            const convertedUTCTime = moment.utc(timelimitWithoutTz[0]).tz(moment.tz.guess());
            const formatedTimelimitTime = formattedDateWithTimeZone(convertedUTCTime);
            const dateNow = moment();
            const momentTimelimit = moment(formatedTimelimitTime, mainConfigs.webTransferTimeFormat).endOf('seconds');
            currentOrder['timelimit'] = momentTimelimit.diff(dateNow, 'seconds');
        } else {
            currentOrder['timelimit'] = -1;
        }

        currentOrder['status_at'] = formattedDateWithTimeZone(currentOrder['status_at']);

        if (currentOrder['order_status'] === BALANCE_PAYMENT_REQUEST_CODE) {
            currentOrder['booking_status'] = BALANCE_PAYMENT_REQUEST_CODE;
        }

        if (currentOrder['order_status'] === BALANCE_PAYMENT_APPROVED_CODE) {
            currentOrder['booking_status'] = BALANCE_PAYMENT_APPROVED_CODE;
        }

        if (_.isNull(currentOrder['airline_booking_number'])) {
            currentOrder['airline_booking_number'] = [];
        }

        currentOrder['routes'].forEach(route => {
            route['segments'].forEach(segment => {
                segment['departure']['time'] = formattedDate(segment['departure']['time']);
                segment['arrival']['time'] = formattedDate(segment['arrival']['time']);

                let techStops = [];

                _.map(segment['tech_stops'], function (techStop) {
                    const formatTime = 'YYYY-MMM-DD HH:mm:ss';
                    let cityCode = order.included.airport[techStop.airport_code].city;
                    let countryCode = order.included.airport[techStop.airport_code].country;
                    let momentObj = moment(techStop.arrival_at, formatTime);
                    const prevDateUnix = momentObj.unix();
                    const actualDateUnix = moment(techStop.departure_at, formatTime).unix();
                    const unixDifference = actualDateUnix - prevDateUnix;
                    const hours = Math.trunc(unixDifference / 3600);
                    const minutes = Math.floor((unixDifference - hours * 3600) / 60);

                    techStops = [
                        ...techStops,
                        {
                            city: {
                                code: cityCode,
                            },
                            country: {
                                code: countryCode,
                            },
                            duration: {
                                hour: hours,
                                minute: minutes,
                            },
                            arrival: {
                                time: momentObj.format('HH:mm'),
                            },
                        },
                    ];
                });
                segment['tech_stops'] = techStops;
            });
        });
    }

    currentOrder = getChangeBaggage(currentOrder);

    return currentOrder;
};

const ordersResultFormat = items => {
    const { data } = items;

    const groups = data.reduce((groups, orders) => {
        const date = moment(orders.timestamp, mainConfigs.formatDateTime).format(mainConfigs.formatForGroupOrders);

        orders['timestamp'] = formattedDateWithTimeZone(orders['timestamp']);
        orders['showActionButtons'] = false;

        if (_.isNull(orders['booking_id'])) {
            orders['brokenOrder'] = true;
            orders['order_status'] = ORDER_FAIL_CODE;
        } else {
            if (orders['timelimit']) {
                const timelimitWithoutTz = orders['timelimit'].split('+');
                const convertedUTCTime = moment.utc(timelimitWithoutTz[0]).tz(moment.tz.guess());
                const formatedTimelimitTime = formattedDateWithTimeZone(convertedUTCTime);
                const dateNow = moment();
                const momentTimelimit = moment(formatedTimelimitTime, mainConfigs.webTransferTimeFormat).endOf(
                    'seconds'
                );
                orders['timelimit'] = momentTimelimit.diff(dateNow, 'seconds');
            } else {
                orders['timelimit'] = -1;
            }

            if (orders['order_status'] === BALANCE_PAYMENT_REQUEST_CODE) {
                orders['booking_status'] = BALANCE_PAYMENT_REQUEST_CODE;
            }

            if (orders['order_status'] === BALANCE_PAYMENT_APPROVED_CODE) {
                orders['booking_status'] = BALANCE_PAYMENT_APPROVED_CODE;
            }

            orders['included'] = items.included;

            _.forEach(orders['routes'], function (route) {
                _.forEach(route['segments'], function (segment) {
                    segment['departure'] = {};
                    segment['arrival'] = {};
                    segment['departure']['time'] = formattedDate(segment['departure_at']);
                    segment['departure']['airport'] = formattedDate(segment['departure_airport_code']);
                    segment['departure']['city'] = items.included.airport[segment['departure_airport_code']].city_code;

                    segment['arrival']['time'] = formattedDate(segment['arrival_at']);
                    segment['arrival']['airport'] = formattedDate(segment['arrival_airport_code']);
                    segment['arrival']['city'] = items.included.airport[segment['arrival_airport_code']].city_code;
                });
            });
        }

        if (!groups[date]) {
            groups[date] = [];
        }

        groups[date].push(orders);

        return groups;
    }, {});

    items['data'] = Object.keys(groups).map(date => {
        return {
            sortDate: date,
            orders: groups[date],
        };
    });

    return items;
};

export const getOrder = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return authorizedInstance()
        .post('order', params)
        .then(response => orderResultFormat(response.data.data));
};

export const getOrdersList = async data => {
    const params = {
        ...baseParams,
        ...data,
        limit: mainConfigs.countOrdersLimit,
    };

    return authorizedInstance()
        .post('order-list', params)
        .then(response => ordersResultFormat(response.data));
};

export const getDocument = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return authorizedInstance()
        .post('document', params)
        .then(response => response.data.data);
};

export const searchOrder = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .post('search-book', params)
        .then(response => response.data)
        .catch(err => err.response.data);
};

export const getBookStatus = (bookingStatus, orderStatus, t) => {
    if (bookingStatus === null && orderStatus !== ORDER_FAIL_CODE) {
        bookingStatus = IN_PROGRESS_BOOK_CODE;
    }

    if (bookingStatus === null && orderStatus === ORDER_FAIL_CODE) {
        bookingStatus = ORDER_FAIL_CODE;
    }

    const bookStatus = _.find(BOOK_STATUS_CODES, { value: bookingStatus });

    return t(bookStatus.code);
};

export const getColorStatus = status => {
    if (status === null) {
        status = IN_PROGRESS_BOOK_CODE;
    }

    const bookStatus = _.find(BOOK_STATUS_COLOR, { value: status });

    return bookStatus.color;
};

export const checkAllowedMK = bookStatus => {
    return (
        bookStatus === BOOKING_DONE_CODE ||
        bookStatus === PARTIAL_REFUND_CODE ||
        bookStatus === APPLICATION_REFUND_CODE ||
        bookStatus === PAY_SUCCESS_CODE ||
        bookStatus === PAY_FAIL_CODE ||
        bookStatus === TICKETING_FAIL_CODE ||
        bookStatus === VOID_CODE ||
        bookStatus === REFUND_CODE ||
        bookStatus === TICKETING_DONE_CODE ||
        bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
        bookStatus === BALANCE_PAYMENT_APPROVED_CODE ||
        bookStatus === CERTIFICATE_ISSUED_CODE
    );
};

export const checkIsAllowedCancel = (bookStatus, orderStatus) => {
    return (
        (bookStatus === BOOKING_DONE_CODE ||
            bookStatus === INITIAL_CODE ||
            bookStatus === null ||
            bookStatus === BOOKING_FAIL_CODE ||
            bookStatus === PAY_FAIL_CODE ||
            bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
            bookStatus === BALANCE_PAYMENT_APPROVED_CODE) &&
        orderStatus !== ORDER_FAIL_CODE
    );
};

export const checkIsAllowBalancePaymentApproved = bookStatus => {
    return bookStatus === BALANCE_PAYMENT_REQUEST_CODE;
};

export const checkInProgressStatus = (bookStatus, orderStatus) => {
    return (
        (bookStatus === BOOKING_DONE_CODE || bookStatus === INITIAL_CODE || bookStatus === null) &&
        orderStatus !== ORDER_FAIL_CODE
    );
};

export const checkInAccessToPaymentStatus = (bookStatus, orderStatus) => {
    return (
        (bookStatus === BOOK_DONE_CODE ||
            bookStatus === INITIAL_CODE ||
            bookStatus === null ||
            bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
            bookStatus === BALANCE_PAYMENT_APPROVED_CODE) &&
        orderStatus !== ORDER_FAIL_CODE
    );
};

export const checkIsAllowedRefund = status => {
    return status === TICKETING_DONE_CODE;
};

export const cancelOrder = (data) => {
    const params = {
        ...baseParams,
        ...data
    };

    return authorizedInstance()
        .post('cancel-book', params)
        .then(response => response.data)
        .catch(err => err.response.data);
}

export const downloadMK = (data) => {
    const params = {
        ...baseParams,
        ...data
    };

    return authorizedInstance()
        .post('document', params)
        .then(response => response.data.data)
        .catch(err => err.response.data);
}

export function checkForMIMEType(response) {
    let blob = converBase64toBlob(response, 'application/pdf');

    let blobURL = URL.createObjectURL(blob);

    window.open(blobURL);
}

function converBase64toBlob(content, contentType) {
    contentType = contentType || '';

    let sliceSize = 512;
    let byteCharacters = window.atob(content);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {

        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, {
        type: contentType
    });

    return blob;
}