import React, { useState } from 'react';
import { Container } from '../../ui/common';
import i18next from 'i18next';
import { MainGrid, SubGrid, Title, Wrapper, Item, Label, Value, Button } from './components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function ProfilePage() {
    const { t } = i18next;
    const history = useNavigate();
    const { firstName, lastName, fatherName, email, login, phone, balance } = useSelector(state => state.authSettings);
    const { currency } = useSelector(state => state.currencySettings);

    const redirect = () => {
        history('/profile-edit');
    };
    
    return (
        <Container>
            <Wrapper>
                <Title>{t('personal_data')}</Title>
                <MainGrid>
                    <SubGrid>
                        <Item>{firstName ? <Value>{firstName}</Value> : <Label>{t('first_name')}</Label>}</Item>
                        <Item>{lastName ? <Value>{lastName}</Value> : <Label>{t('last_name')}</Label>}</Item>
                        <Item>{fatherName ? <Value>{fatherName}</Value> : <Label>{t('father_name')}</Label>}</Item>
                        <Item>{email ? <Value>{email}</Value> : <Label>{t('email')}</Label>}</Item>
                        <Item>{login ? <Value>{login}</Value> : <Label>{t('login')}</Label>}</Item>
                        <Item>{phone ? <Value>{phone}</Value> : <Label>{t('phone')}</Label>}</Item>
                        <Item>
                            <Value>
                                {balance} &nbsp;
                                {currency}
                            </Value>
                        </Item>
                        <Button onClick={redirect}>{t('edit')}</Button>
                    </SubGrid>
                </MainGrid>
            </Wrapper>
        </Container>
    );
}

export default ProfilePage;
