import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getRouteCity } from '../../../../../utils/booking';
import {
    Info,
    LineDivider,
    LocalTimeInfo,
    LocalTimeText,
    RouteDepartureCities,
    RouteDuration,
    RouteInfo,
    Segments,
    VerticalLine,
    Wrapper,
} from './components';
import { ReactComponent as InfoIcon } from '../../../../../assets/somon_air/icons/info.svg';
import SegmentItem from '../segmentItem';

const RouteItem = ({ route }) => {
    const { included } = useSelector(state => state.order.orderData);
    const { language, t } = i18next;
    const [departureCity, arrivalCity, departureTime] = getRouteCity(route, included, language);

    const getDuration = () => {
        const hours = Math.trunc(route.duration / 3600);
        const minutes = Math.floor((route.duration - hours * 3600) / 60);

        return `${hours} ${t('hour_short')} ${minutes} ${t('minutes_short_3')}`;
    };

    return (
        <Wrapper>
            <Info>
                <LocalTimeInfo>
                    <InfoIcon />
                    <LocalTimeText>{t('everywhere_local_time')}</LocalTimeText>
                </LocalTimeInfo>
                <RouteInfo>
                    <RouteDepartureCities>
                        <span className="label">
                            {t('departure_title')} {departureTime}
                        </span>
                        <span className="value">
                            {departureCity} - {arrivalCity}
                        </span>
                    </RouteDepartureCities>
                    <VerticalLine />
                    <RouteDuration>
                        <span className="label">{t('travel_time')}</span>
                        <span className="value">{getDuration()}</span>
                    </RouteDuration>
                </RouteInfo>
            </Info>

            <LineDivider />

            <Segments>
                {route.segments.map((segment, index) => (
                    <SegmentItem key={index} segment={segment} index={index} route={route} />
                ))}
            </Segments>
        </Wrapper>
    );
};

export default RouteItem;
