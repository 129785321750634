import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import noun from 'plural-ru';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    valuesContainer: {
        display: 'flex',

        '@media (max-width: 899px)': {
            marginBottom: '8px',
        },

        '@media (max-width: 599px)': {
            marginBottom: '0',
            justifyContent: 'center',
            gap: '18px',
        },
    },
    timelimitValueBlock: {
        width: '70px',
        height: '70px',
        borderRadius: '5px',
        backgroundColor: '#f2f4f5',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '16px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 599px)': {
            width: '62px',
            height: '62px',
            margin: '0',
        }
    },
    timelimitValueItem: {
        paddingTop: '3px',
        fontWeight: '500',
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#404B5A',

        '@media (max-width: 599px)': {
            fontSize: '26px',
        }
    },
    timelimitLabelItem: {
        color: '#000000',
        fontSize: '12px',
        color: '#404B5A',
    },
    timelimitLabelsContainer: {
        paddingTop: '12px',
        fontSize: '14px',
        color: '#7e878b',
    },
    timelimitExpired: {
        // paddingTop: '4px',
        fontWeight: '500',
        fontSize: '16px',
        color: '#D60C0C',
    },
});

export default function TimelimitCounter(props) {
    const classes = useStyles();
    const { t } = i18next;

    const { orderData } = useSelector(state => state.order);
    const [timelimitSecond, setTimelimitSecond] = useState(orderData?.timelimit);

    useEffect(() => {
        let counter = setTimeout(() => setTimelimitSecond(timelimitSecond - 1), 1000);

        return () => {
            clearTimeout(counter);
        };
    });

    if (timelimitSecond < 0) {
        orderData?.disableTimer();

        return <div className={classes.timelimitExpired}>{t('payment_timed_out')}</div>;
    }

    let seconds = Math.floor(timelimitSecond % 60);
    let minutes = Math.floor((timelimitSecond / 60) % 60);
    let hours = Math.floor((timelimitSecond / (60 * 60)) % 24);
    let days = Math.floor(timelimitSecond / (60 * 60 * 24));

    return (
        <div className={classes.valuesContainer}>
            <div className={classes.timelimitValueBlock}>
                <div className={classes.timelimitValueItem}>{days}</div>
                <div className={classes.timelimitLabelItem}>{getDaysString(days, t)}</div>
            </div>
            <div className={classes.timelimitValueBlock}>
                <div className={classes.timelimitValueItem}>{hours}</div>
                <div className={classes.timelimitLabelItem}>{getHoursString(hours, t)}</div>
            </div>
            <div className={classes.timelimitValueBlock}>
                <div className={classes.timelimitValueItem}>{minutes}</div>
                <div className={classes.timelimitLabelItem}>{getMinutesString(minutes, t)}</div>
            </div>
            <div className={classes.timelimitValueBlock}>
                <div className={classes.timelimitValueItem}>{seconds}</div>
                <div className={classes.timelimitLabelItem}>{getSecondsString(seconds, t)}</div>
            </div>
        </div>
    );
}

const getDaysString = (count, t) => {
    return noun(count, t('day_one'), t('day_more'), t('days'));
};

const getHoursString = (count, t) => {
    return noun(count, t('hour_one'), t('hour_more'), t('hours'));
};

const getMinutesString = (count, t) => {
    return noun(count, t('minute_one'), t('minute_more'), t('minutes'));
};

const getSecondsString = (count, t) => {
    return noun(count, t('second_one'), t('second_more'), t('seconds'));
};
