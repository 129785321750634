import { useEffect, useRef, useState } from 'react';
import { Dropdown, Wrapper } from './components';

const TransitionDropdown = ({ children, show, onClickOutside, noPadding = false }) => {
    const node = useRef();
    const [wrapperShow, setWrapperShow] = useState(false);
    const [dropdownShow, setDropdownShow] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    useEffect(() => {
        if (show) {
            setWrapperShow(true);
            setDropdownShow(true);
        }
    }, [show]);

    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }

        handleClose();
    };

    const handleClose = () => {
        setDropdownShow();
        setTimeout(() => {
            setWrapperShow(false);
            onClickOutside();
        }, 300);
    };

    return (
        <Wrapper ref={node} active={wrapperShow}>
            <Dropdown active={dropdownShow} noPadding={noPadding}>
                {children}
            </Dropdown>
        </Wrapper>
    );
};

export default TransitionDropdown;
