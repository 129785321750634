import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import i18next from 'i18next';
import { useState, Fragment } from 'react';
import { Container, Session, TitleBlock, TitleContent } from './components';

function ErrorModal(props) {
    const { t } = i18next;
    const { error_code, error_desc, custom_error, sessionId, withArea } = props;
    const [open] = useState(true);

    const handleClose = () => {
        window.location.href = '/';
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('error')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>
                            <span>{t('code')}:</span>
                            <span>
                                <TitleContent>{error_code}</TitleContent>
                            </span>
                        </div>
                        <div>
                            <span>{t('message')}:</span>
                            <span>
                                <TitleContent>{error_desc}</TitleContent>
                            </span>
                        </div>
                        <div>{custom_error || RenderMessage(sessionId)}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={handleClose}>
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const RenderMessage = sessionId => {
    const { t } = i18next;

    if (sessionId) {
        return (
            <Fragment>
                <div>
                    {t('error_message_part_1')} {t('error_message_part_2')}:
                    <span>
                        <Session>{sessionId}</Session>
                    </span>
                    .
                </div>
                <div>{t('error_message_part_3')}</div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div>{t('error_message_part_1')}.</div>
            <div>{t('error_message_part_3')}</div>
        </Fragment>
    );
};

ErrorModal.defaultProps = {
    error_code: null,
    error_desc: null,
    sessionId: null,
    withArea: true,
};

export default ErrorModal;
