import styled from 'styled-components';

export const OrderStatusBlock = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 499px) {
        margin: 8px 0;
        width: 100%;
    }
`;

export const Circle = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: ${props => props.statusColor};
`;

export const StatusString = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const StatusStringBlock = styled.span`
    font-size: 16px;
    padding-left: 12px;
    display: inline-block;
    color: ${props => props.statusColor};
`;

export const IconBlock = styled.span`
    width: 25px;
    height: 25px;
    text-align: center;
    background-color: ${props => props.statusColor};
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 5px;
    
    & svg {
        fill: #fff;
    }
`;

export const Span = styled.span`
    padding-right: 8px;
`;