import { DesktopBlock, MobileBlock, Wrapper } from './components';
import FiltersDesktop from './desktop';
import FiltersMobile from './mobile';

function FilterPage(props) {
    return (
        <Wrapper>
            <DesktopBlock>
                <FiltersDesktop {...props} />
            </DesktopBlock>

            <MobileBlock>
                <FiltersMobile {...props} />
            </MobileBlock>
        </Wrapper>
    );
}

export default FilterPage;
