import _ from 'lodash';
import moment from 'moment';
import { mainConfigs } from '../../configs/mainConfigs';

const webTransferTimeFormat = mainConfigs.webTransferTimeFormat;

export const byBaggage = (recommendations, filtersData) => {
    const { withoutBaggage, withBaggage } = filtersData;
    if (!withBaggage && !withoutBaggage) {
        return [];
    } else {
        const { withoutBaggage, withBaggage } = filtersData;

        return _.filter(recommendations, function (recommendation) {
            const { baggage } = recommendation.routes[0].segments[0];

            if (withBaggage) {
                return baggage;
            }

            if (withoutBaggage) {
                return !baggage;
            }
        });
    }
};

export const byTransfers = (recommendations, filtersData) => {
    const { withoutTransfers, oneTransfer, manyTransfers } = filtersData;
    let list = [];

    if (withoutTransfers) {
        const filterData = _.filter(recommendations, function (recommendation) {
            let filterStatus = true;

            _.forEach(recommendation.routes, function (route) {
                if (route.segments.length > 1) {
                    filterStatus = false;
                }
            });

            return filterStatus;
        });

        list = [...list, ...filterData];
    }

    if (oneTransfer) {
        const filterData = _.filter(recommendations, function (recommendation) {
            let filterStatus = false;

            _.forEach(recommendation.routes, function (routes) {
                if (routes.segments.length === 2) {
                    filterStatus = true;
                }
            });

            _.forEach(recommendation.routes, function (routes) {
                if (routes.segments.length > 2) {
                    filterStatus = false;
                }
            });

            return filterStatus;
        });

        list = [...list, ...filterData];
    }

    if (manyTransfers) {
        const filterData = _.filter(recommendations, function (recommendation) {
            let filterStatus = false;

            _.forEach(recommendation.routes, function (routes) {
                if (routes.segments.length > 2) {
                    filterStatus = true;
                }
            });

            return filterStatus;
        });

        list = [...list, ...filterData];
    }

    return _.uniq(list);
};

export const byAirlines = (recommendations, airlines) => {
    return _.filter(recommendations, function (recommendation) {
        const { validating_supplier } = recommendation;

        return airlines.indexOf(validating_supplier) > -1;
    });
};

export const byAirports = (recommendations, filtersData) => {
    return _.filter(recommendations, function (recommendation) {
        let status = true;

        _.forEach(filtersData, function (item) {
            let departureStatus = true;
            let arrivalStatus = true;

            if (item.departures.length !== 0) {
                departureStatus =
                    item.departures.indexOf(
                        _.first(recommendation.routes[item.routeIndex].segments).departure.airport
                    ) !== -1;
            }

            if (item.arrivals.length !== 0) {
                arrivalStatus =
                    item.arrivals.indexOf(_.last(recommendation.routes[item.routeIndex].segments).arrival.airport) !==
                    -1;
            }

            if (!departureStatus || !arrivalStatus) {
                status = false;
            }
        });

        return status;
    });
};

export const byFlightNumbers = (recommendations, filtersData) => {
    return _.filter(recommendations, function (recommendation) {
        let status = false;

        _.forEach(recommendation.routes, function (route) {
            _.forEach(route.segments, function (segment) {
                if (filtersData.indexOf(segment.carrier_number) !== -1) {
                    status = true;
                }
            });
        });

        return status;
    });
};

export const byDuration = (recommendations, filtersData) => {
    return _.filter(recommendations, function (recommendation) {
        let status = true;

        _.forEach(filtersData, function (item) {
            const { min, max } = item.values;
            const duration = recommendation.routes[item.routeIndex].duration;
            const durationStatus = min <= duration && duration <= max;

            if (!durationStatus) {
                status = false;
            }
        });

        return status;
    });
};

export const byDepartureTime = (recommendations, filtersData) => {
    return _.filter(recommendations, function (recommendation) {
        let status = true;

        _.forEach(filtersData, function (item) {
            const { min, max } = item.values;
            const departureTimeParsed = _.first(recommendation.routes[item.routeIndex].segments).departure.time.split(
                ' '
            );
            const departureTime = moment(_.last(departureTimeParsed), 'HH:mm').diff(moment().startOf('day'), 'seconds');
            const departureTimeStatus = min <= departureTime && departureTime <= max;

            if (!departureTimeStatus && min && max) {
                status = false;
            }
        });

        return status;
    });
};

export const byArrivalTime = (recommendations, filtersData) => {
    return _.filter(recommendations, function (recommendation) {
        let status = true;

        _.forEach(filtersData, function (item) {
            const { min, max } = item.values;
            const arrivalTimeParsed = _.last(recommendation.routes[item.routeIndex].segments).arrival.time.split(' ');
            const arrivalTime = moment(_.last(arrivalTimeParsed), 'HH:mm').diff(moment().startOf('day'), 'seconds');
            const arrivalTimeStatus = min <= arrivalTime && arrivalTime <= max;

            if (!arrivalTimeStatus && min && max) {
                status = false;
            }
        });

        return status;
    });
};

export const byPrice = (recommendations, priceRange, currency) => {
    const { min, max } = priceRange;

    return _.filter(recommendations, function (recommendation) {
        const totalPrice = recommendation['total_price'][currency];

        return min <= totalPrice && totalPrice <= max;
    });
};

export const byPricesTable = (recommendations, values, currency) => {
    const { type, supplier, price } = values;

    return _.filter(recommendations, recommendation => {
        if (recommendation.validating_supplier !== supplier) return false;

        if (recommendation.total_price[currency] !== price) return false;

        if (type === 'direct' && checkFlightHasTransfers(recommendation)) return false;

        if (type === 'transfers' && !checkFlightHasTransfers(recommendation)) return false;

        return true;
    });
};

export const getDataForFilter = recommendations => {
    const templateRoutesData = getRoutesCity(_.first(recommendations));

    let routesData = _.cloneDeep(templateRoutesData);

    _.map(recommendations, recommendation => {
        const { routes } = recommendation;

        _.map(routes, route => {
            const { segments, index } = route;
            const segmentDeparture = _.first(segments).departure;
            const segmentArrival = _.last(segments).arrival;
            const departureAirport = segmentDeparture.airport;
            const arrivalAirport = segmentArrival.airport;
            const departureTime = segmentDeparture.time;
            const arrivalTime = segmentArrival.time;

            let departureAirportsArray = routesData[index].departureAirports;
            let arrivalAirportsArray = routesData[index].arrivalAirports;

            if (
                _.findIndex(departureAirportsArray, o => {
                    return o === departureAirport;
                }) === -1
            ) {
                routesData[index].departureAirports = [...departureAirportsArray, departureAirport];
            }

            if (
                _.findIndex(arrivalAirportsArray, o => {
                    return o === arrivalAirport;
                }) === -1
            ) {
                routesData[index].arrivalAirports = [...arrivalAirportsArray, arrivalAirport];
            }

            routesData[index].departureTime = {
                defaultRange: [
                    ...routesData[index].departureTime.defaultRange,
                    moment(departureTime, webTransferTimeFormat),
                ],
            };

            routesData[index].arrivalTime = {
                defaultRange: [
                    ...routesData[index].arrivalTime.defaultRange,
                    moment(arrivalTime, webTransferTimeFormat),
                ],
            };

            routesData[index].departureTime.defaultRange = [
                _.min(routesData[index].departureTime.defaultRange),
                _.max(routesData[index].departureTime.defaultRange),
            ];

            routesData[index].arrivalTime.defaultRange = [
                _.min(routesData[index].arrivalTime.defaultRange),
                _.max(routesData[index].arrivalTime.defaultRange),
            ];

            routesData[index].duration = {
                defaultRange: [...routesData[index].duration.defaultRange, route.duration],
            };

            routesData[index].duration.defaultRange = [
                _.min(routesData[index].duration.defaultRange),
                _.max(routesData[index].duration.defaultRange),
            ];
        });
    });

    return [templateRoutesData, routesData];
};

const getRoutesCity = recommendation => {
    const { routes } = recommendation;

    return _.map(routes, (route, index) => {
        const departureCityCode = _.first(route.segments).departure.city;
        const arrivalCityCode = _.last(route.segments).arrival.city;

        return {
            index,
            departureCityCode,
            arrivalCityCode,
            departureAirports: [],
            arrivalAirports: [],
            duration: {
                defaultRange: [],
            },
            departureTime: {
                defaultRange: [],
            },
            arrivalTime: {
                defaultRange: [],
            },
        };
    });
};

const checkFlightHasTransfers = flight => {
    const { routes } = flight;

    for (const route of routes) {
        if (route.segments.length > 1) return true;
    }

    return false;
};
