import styled from 'styled-components';

export const TextBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
`;

export const LogoBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 32px;
    
    @media (max-width: 599px) {
        padding: 32px 0;
    }
`;

export const Logo = styled.img`
    max-width: 200px;

    @media (max-width: 599px) {
        max-width: 160px;
    }
`;

export const EventText = styled.div`
    font-size: 20px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 599px) {
        font-size: 18px;
    }
`;

export const ButtonBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 0;

    @media (max-width: 599px) {
        padding: 32px 0;
    }
`;

export const Button = styled.button`
    border-radius: 12px;
    background: linear-gradient(to right bottom,#f8d023,#e65c01);
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    padding: 12px 24px;
    text-decoration: none;

    @media (max-width: 599px) {
        font-size: 16px;
    }
`;
