import i18next from 'i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterParams } from '../../../../../../../store/filtersSlice';
import FormCheckbox from '../../../../../../ui/FormCheckbox';
import FilterBlock from '../filterBlock';
import { CheckboxBlock, Label } from './components';

const BaggageFilter = () => {
    const { t } = i18next;
    const dispatch = useDispatch();
    const { filterParams } = useSelector(state => state.filters);
    const { values } = filterParams.baggage;

    const handleChange = (value, type) => {
        const filters = {
            ...values,
            [type]: value,
        };

        dispatch(
            setFilterParams({
                type: 'baggage',
                values: {
                    isActive: !filters.withBaggage || !filters.withoutBaggage,
                    values: filters,
                },
            })
        );
    };

    return (
        <FilterBlock title={t('tariff_options')} isOpen borderBottom>
            <CheckboxBlock>
                <FormCheckbox onSelect={value => handleChange(value, 'withBaggage')} isChecked={values.withBaggage} />
                <Label>{t('with_luggage')}</Label>
            </CheckboxBlock>

            <CheckboxBlock>
                <FormCheckbox
                    onSelect={value => handleChange(value, 'withoutBaggage')}
                    isChecked={values.withoutBaggage}
                />
                <Label>{t('without_luggage')}</Label>
            </CheckboxBlock>
        </FilterBlock>
    );
};

export default BaggageFilter;
