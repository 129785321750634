import styled from 'styled-components';
import { RHINO_COLOR } from '../../../../../constants';

export const RouteBlock = styled.div`
    margin-top: 8px;

    &:nth-of-type(1) {
        margin-top: 2px;
    }
`;

export const RouteCities = styled.div`
    font-size: 15px;
    margin-bottom: 4px;
    font-weight: bold;
    color: ${RHINO_COLOR};
`;

export const RouteFlightTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    color: ${RHINO_COLOR};
`;

export const CheckboxBlock = styled.div`
    @media (max-width: 959px) {
        padding: 4px 0;
    }
`;

export const Label = styled.label`
    margin-left: 8px;
    vertical-align: 6px;
    font-size: 14px;
`;
