import { CheckboxBlock } from './components';
import { ReactComponent as CheckMark } from '../../../../assets/somon_air/icons/checkMark.svg';

const Checkbox = ({ checked = false, onClick = () => null }) => {
    return (
        <CheckboxBlock isChecked={checked ? 1 : 0} onClick={onClick}>
            {checked && <CheckMark />}
        </CheckboxBlock>
    );
};

export default Checkbox;
