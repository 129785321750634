import styled from 'styled-components';
import { ORANGE_COLOR } from '../../constants';

export const CheckboxBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: 2px solid ${ORANGE_COLOR};
    border-radius: 5px;
    background-color: ${props => (props.isChecked ? ORANGE_COLOR : 'transparent')};
`;
