import i18next from 'i18next';
import noun from 'plural-ru';
import { ADT, CHD, INF, INS } from '../constants';

export const normalizePrice = price => {
    price += '';
    let x = price.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;

    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }

    return x1 + x2;
};

export const getPassTypeCountString = (count, type) => {
    const { t } = i18next;

    switch (type) {
        case ADT:
            return noun(count, t('one_adt'), t('more_adt'), t('many_adt'));
        case CHD:
            return noun(count, t('one_chd'), t('more_chd'), t('many_chd'));
        case INS:
        case INF:
            return noun(count, t('one_inf'), t('more_inf'), t('many_inf'));
    }
};
