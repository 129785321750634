import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1530px;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
`;

export const ShowInDesktop = styled.div`
    display: ${props => props.display || 'block'};

    @media (max-width: 899px) {
        display: none;
    }
`;

export const ShowInMobile = styled.div`
    display: none;

    @media (max-width: 899px) {
        display: ${props => props.display || 'block'};
    }
`;
