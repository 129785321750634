import { Item as FormItem, Title as FormTitle, Label as FormLabel, Button as FormButton } from '../../resetPassword/components';
import InputText from '../../../ui/input';
import styled from 'styled-components';

export const Input = styled(InputText)`
    width: calc(100% - 10px);
 `;

export const Item = styled(FormItem)`
    width: calc(100% - 10px);
`;

export const Title = styled(FormTitle)``;

export const Label = styled(FormLabel)``;

export const ButtonBlock = styled.div`
    display: flex;
    gap: 0.5rem;
    width: calc(100% - 10px);
`;

export const Button = styled(FormButton)`
    width: 49%;
    margin: 10px 0;

    @media (max-width: 899px) {
        max-width: 50%;
        width: 49%;
    }
`;

export const ClearButton = styled(FormButton)`
    background-color: #FFEFEF;
    width: 49%;
    margin: 10px 0;
    border: 1px solid #FFB4B4;

    @media (max-width: 899px) {
        max-width: 50%;
        width: 49%;
    }
`;

export const DateBlockContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(100% - 10px);
`;

export const DateItem = styled(FormItem)`
    width: 49%;
`;