import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showHeader: true,
    isStickyHeader: false,

    showSearchBlock: true,
    isStickySearchBlock: false,
    isSmallSearchBlock: false,
    isFullScreenSearchBlock: true,
    shouldMinimize: true,

    showFooter: true,
};

export const designSettingsSlice = createSlice({
    name: 'designSettings',
    initialState,
    reducers: {
        setDesignSettings: (state, action) => ({ ...state, ...action.payload }),
    },
});

export const { setDesignSettings } = designSettingsSlice.actions;

export default designSettingsSlice.reducer;
