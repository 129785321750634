import styled from 'styled-components';
import { DARK_GREY_COLOR, WHITE_COLOR } from '../../../../constants';

export const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-color: ${WHITE_COLOR};
`;

export const BrandsWrapper = styled.div`
    white-space: nowrap;
`;

export const BrandsContainer = styled.div`
    background-color: ${WHITE_COLOR};
    padding: 16px 0 0;
`;

export const BrandsMessageBlock = styled.div`
    color: ${DARK_GREY_COLOR};
    font-size: 16px;
    padding-left: 78px;
`;

export const BrandsMessageIcon = styled.div`
    display: inline-block;
`;

export const BrandsMessageTitle = styled.div`
    display: inline-block;
    position: relative;
    bottom: 4px;
    margin-left: 6px;
`;

export const BrandsBlock = styled.div`
    padding: 16px 0 24px 0;
`;
