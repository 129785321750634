import React from 'react';

import FlightDetails from '../../resultPage/desktop/flightDetails';
import DesktopTariffsBlock from '../tariffsBlock/desktop';

const AdditionallyForm = props => {
    const {
        brandFares,
        route,
        showTariffs,
        showDetails,
        routeIndex,
        recId,
        brandRecId,
        getFareRules,
        brandName,
        recommendation,
        currency,
        included,
    } = props;
    const hasBrandedTariffs = recommendation.has_branded_tariffs;

    return (
        <div>
            {showDetails && (
                <div>
                    <FlightDetails
                        route={route}
                        recommendation={recommendation}
                        included={included}
                        routeIndex={routeIndex}
                    />
                </div>
            )}
            {showTariffs && hasBrandedTariffs && (
                <DesktopTariffsBlock
                    recId={recId}
                    brandRecId={brandRecId}
                    brandFares={brandFares}
                    brandName={brandName}
                    getFareRules={getFareRules}
                    currency={currency}
                />
            )}
        </div>
    );
};

export default AdditionallyForm;
