import { Container } from '../ui/common';
import BookingForm from './bookingForm';
import { InfoBlock, PriceBlock, Wrapper } from './components';
import Price from './price';
import RoutesData from './routesData';

const Booking = props => {
    return (
        <Container>
            <Wrapper>
                <InfoBlock>
                    <RoutesData />
                </InfoBlock>
                <PriceBlock>
                    <Price />
                </PriceBlock>
            </Wrapper>
            
            <BookingForm {...props} />
        </Container>
    );
};

export default Booking;
