import i18next from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { B2C } from '../../../../containers/order/constants';
import {
    Block,
    BlockTitle,
    ButtonContainer,
    PaymentMethodsBlock,
    TimeCounterBlock,
    TimeExpire,
    TimeLeft,
} from './components';
import PaymentMethods from '../paymentMethods';
import TimelimitCounter from '../../ui/timelimitCounter/TimelimitCounter';
import { parserLangCode } from '../../../result/common';
import { mainConfigs } from '../../../../configs';
import Button from '../../ui/button';
import { DARK_GREEN_COLOR, GREEN_COLOR, WHITE_COLOR } from '../../constants';
import { ReactComponent as TickIcon } from '../../../../assets/somon_air/icons/tick.svg';

function PaymentForm(props) {
    const { timelimit, sendPaymentRequest, paymentMethod } = props;
    const { isAuth } = useSelector(state => state.authSettings);
    const { availablePaymentMethods, orderData } = useSelector(state => state.order);
    const { t } = i18next;

    const [timerActive, setTimerActive] = useState(orderData?.timelimit > 0);

    const disableTimer = () => {
        setTimerActive(false);
    };

    // if (timerActive) {
    //     return (
    //         <Block>
    //             <BlockTitle>Способ оплаты</BlockTitle>
    //         </Block>
    //     );
    // }

    return (
        <Block>
            <BlockTitle>{t('payment_method')}</BlockTitle>

            <PaymentMethodsBlock>
                {availablePaymentMethods.map((item, index) => {
                    if (!isAuth && item.code === B2C) {
                        return null;
                    }

                    return <PaymentMethods key={index} checkedPaymentMethod={paymentMethod} methodData={item} />;
                })}
            </PaymentMethodsBlock>

            <TimeCounterBlock>
                <TimelimitCounter timelimit={timelimit} disableTimer={disableTimer} />
                <TimelimitLabels timelimit={timelimit} />
                {!_.isNull(orderData?.paymentMethod) && (
                    <PaymentButton sendPaymentRequest={sendPaymentRequest} paymentMethod={paymentMethod} />
                )}
            </TimeCounterBlock>
        </Block>
    );
}

export default PaymentForm;

const TimelimitLabels = props => {
    const { t, language } = i18next;
    const { timelimit } = props;
    const timelimitTime = moment()
        .locale(parserLangCode(language))
        .add(timelimit, 'seconds')
        .format(mainConfigs.serverDateTimeFormat);

    return (
        <div>
            <TimeLeft>{t('left_before_the_time_limit')}</TimeLeft>
            <TimeExpire>
                {t('expires')} {timelimitTime}
            </TimeExpire>
        </div>
    );
};

const PaymentButton = props => {
    const { t } = i18next;
    const { paymentMethod, sendPaymentRequest } = props;
    let buttonTitle = t('pay_for_the_order');

    return (
        <ButtonContainer>
            <Button
                fontSize={17}
                height="50"
                opacity="1"
                type="submit"
                backgroundColor={GREEN_COLOR}
                iconBackgroundColor={DARK_GREEN_COLOR}
                borderColor={GREEN_COLOR}
                textTransform="none"
                icon={<TickIcon />}
                iconTransition
                color={WHITE_COLOR}
                onClick={() => sendPaymentRequest(paymentMethod)}
                title={buttonTitle}
            />
        </ButtonContainer>
    );
};
