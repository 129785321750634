import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getOrdersList } from "../../../../protocols";
import { setOrdersFilters, setOrdersData, setShowActionsForOrder } from "../../../../store/ordersSlice";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import OrderContainer from "./OrderContainer";
import { OrderMainBlock, MainContainer, FilterMainBlock, OrdersTitle, Wrapper } from "./components";
import Filters from "./filters";

const Orders = () => {
    const { ordersData, isLoad, filters, childPartners, totalOrdersCount } = useSelector(state => state.ordersSlice);
    const [page, setPage] = useState(1);
    const { isAuth } = useSelector(state => state.authSettings);
    const [withoutFilters, setWithoutFilters] = useState(true);
    const dispatch = useDispatch();
    const { t } = i18next;
    const history = useNavigate();

    useEffect(() => {
        getOrders(page, filters);

        return () => {
            setWithoutFilters(true);
        };
    }, []);



    const getOrders = (pageNumber, filters) => {
        getOrdersList({
            page: pageNumber,
            ...filters,
        }).then(response => {
            dispatch(
                setOrdersData({
                    ordersData: response,
                    isLoad: true
                })
            );
        });
    };

    if (!isAuth) {
        history('/');
    }

    return (
        <>
            <OrdersTitle>{t('orders')}</OrdersTitle>
            <MainContainer>
                <Wrapper>
                    <OrderMainBlock>
                        {ordersData?.data?.map((item, index) => (
                            <OrderContainer
                                item={item}
                                key={index}
                            />
                        ))}
                    </OrderMainBlock> 
                    <FilterMainBlock>
                        <Filters />
                    </FilterMainBlock>
                </Wrapper>
            </MainContainer>
        </>
    );
}

export default Orders;