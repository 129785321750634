import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterParams } from '../../../../../../../store/filtersSlice';
import FormCheckbox from '../../../../../../ui/FormCheckbox';
import FilterBlock from '../filterBlock';
import { RouteBlock, RouteCities, RouteFlightTitle, CheckboxBlock, Label } from './components';

const AirportsFilter = () => {
    const { t, language } = i18next;
    const dispatch = useDispatch();
    const { filterParams, filtersData } = useSelector(state => state.filters);
    const { included } = useSelector(state => state.resultData);
    const { isActive, values } = filterParams.airports;
    const { routesData } = filtersData;

    const handleChangeDeparture = (routeIndex, value) => {
        let params = JSON.parse(JSON.stringify(values));
        const index = params.findIndex(param => param.routeIndex === routeIndex);

        if (index > -1) {
            if (params[index].departures.includes(value)) {
                params[index].departures = params[index].departures.filter(item => item !== value);

                if (params[index].departures.length === 0 && params[index].arrivals.length === 0) {
                    params = params.filter((_, ind) => ind !== index);
                }
            } else {
                params[index].departures.push(value);
            }
        } else {
            params.push({ routeIndex, departures: [value], arrivals: [] });
        }

        dispatch(
            setFilterParams({
                type: 'airports',
                values: {
                    isActive: params.length > 0,
                    values: params,
                },
            })
        );
    };

    const handleChangeArrival = (routeIndex, value) => {
        let params = JSON.parse(JSON.stringify(values));
        const index = params.findIndex(param => param.routeIndex === routeIndex);

        if (index > -1) {
            if (params[index].arrivals.includes(value)) {
                params[index].arrivals = params[index].arrivals.filter(item => item !== value);

                if (params[index].departures.length === 0 && params[index].arrivals.length === 0) {
                    params = params.filter((_, ind) => ind !== index);
                }
            } else {
                params[index].arrivals.push(value);
            }
        } else {
            params.push({ routeIndex, departures: [], arrivals: [value] });
        }

        dispatch(
            setFilterParams({
                type: 'airports',
                values: {
                    isActive: params.length > 0,
                    values: params,
                },
            })
        );
    };

    const renderRoute = route => {
        return (
            <RouteBlock key={`${route.routeIndex}-${route.departureCityCode}-${route.arrivalCityCode}`}>
                <RouteCities>
                    {included.city[route.departureCityCode].name[language]} →{' '}
                    {included.city[route.arrivalCityCode].name[language]}
                </RouteCities>

                <RouteFlightTitle>{t('departure')}</RouteFlightTitle>

                {route.departureAirports.map(airport => (
                    <CheckboxBlock key={airport}>
                        <FormCheckbox
                            onSelect={value => handleChangeDeparture(route.index, airport)}
                            isChecked={
                                values.findIndex(x => x.routeIndex === route.index && x.departures.includes(airport)) >
                                -1
                            }
                        />

                        <Label>{included.airport[airport].name[language]}</Label>
                    </CheckboxBlock>
                ))}

                <RouteFlightTitle>{t('arrival')}</RouteFlightTitle>

                {route.arrivalAirports.map(airport => (
                    <CheckboxBlock key={airport}>
                        <FormCheckbox
                            onSelect={value => handleChangeArrival(route.index, airport)}
                            isChecked={
                                values.findIndex(x => x.routeIndex === route.index && x.arrivals.includes(airport)) > -1
                            }
                        />

                        <Label>{included.airport[airport].name[language]}</Label>
                    </CheckboxBlock>
                ))}
            </RouteBlock>
        );
    };

    return (
        <FilterBlock title={t('airports')} isOpen={isActive} borderBottom>
            {routesData.map(route => renderRoute(route))}
        </FilterBlock>
    );
};

export default AirportsFilter;
