import { useEffect } from 'react';
import i18next from 'i18next';

import Modal from '../ui/modal';
import {
    TextBlock,
    LogoBlock,
    Logo,
    EventText,
    ButtonBlock,
    Button,
} from './components';

import visaLogoImage from '../../../assets/somon_air/images/otherLogos/visa.png';

const VisaPaymentPopup = ({ showPopup, handleClose, data }) => {
    const { t } = i18next;

    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            if (showPopup) {
                document.body.classList.add('hidden');
            } else {
                document.body.classList.remove('hidden');
            }
        };
    }, [showPopup]);

    if (!showPopup) return null;

    return (
        <Modal show={showPopup} onClose={handleClose} maxWidth={600} fixedWidth position='center'>
            <TextBlock>
                <LogoBlock>
                    <Logo src={visaLogoImage} alt="dc-logo" />
                </LogoBlock>

                <EventText>{t('confirm_actions')}</EventText>

                <form action={data.payUrl} method="POST">
                    <input name="appendix" value={data.appendix} hidden />
                    <input name="PostLink" value={data.PostLink} hidden />
                    <input name="BackLink" value={data.BackLink} hidden />
                    <input name="email" value={data.email} hidden />
                    <input name="Signed_Order_B64" value={data.Signed_Order_B64} hidden />
                    <ButtonBlock>
                        <Button type="submit">{t('confirm')}</Button>
                    </ButtonBlock>
                </form>
            </TextBlock>
        </Modal>
    );
};

export default VisaPaymentPopup;
