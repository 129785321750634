import styled from 'styled-components';
import active from '../../../assets/somon_air/icons/checkboxCheckedIcon.svg';
import inactive from '../../../assets/somon_air/icons/checkboxBackground.svg';

export const CheckboxItem = styled.button`
    height: 24px;
    width: 24px;
    background-color: ${props => props.backgroundColor || '#f2f4f5'};
    background-image: url(${props => (props.isActive ? active : inactive)});
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: center center;
    border: none;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;
