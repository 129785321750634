import React, { useState, useContext } from 'react';
import moment from 'moment';
import i18next from 'i18next';
import RoutesInfo from '../routesInfo';
import _ from 'lodash';
import {
    Date,
    Title,
    TitleBlock,
    Wrapper,
    StatusButtonBlock,
    Button,
    ActionBlock,
    IconBlock,
    ActionsBlock,
    ActionBlocksButton,
} from './components';
import BookingStatus from '../bookingStatus';
import { mainConfigs as config } from '../../../../../configs/mainConfigs';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronIcon } from '../../../../../assets/somon_air/icons/chevron.svg';
import { ReactComponent as CartIcon } from '../../../../../assets/somon_air/icons/cart.svg';
import { ReactComponent as CircleCrossIcon } from '../../../../../assets/somon_air/icons/circle-cross.svg';
import { ReactComponent as MKIcon } from '../../../../../assets/somon_air/icons/mk.svg';
import {
    cancelOrder,
    checkAllowedMK,
    checkForMIMEType,
    checkInAccessToPaymentStatus,
    checkInProgressStatus,
    checkIsAllowedCancel,
    downloadMK,
    getOrdersList,
} from '../../../../../protocols';
import { setOrdersData } from '../../../../../store/ordersSlice';
import { useDispatch } from 'react-redux';
import { SnackbarContext } from '../../../../../contexts/snackbarContext';

const Order = props => {
    const { order, orderRedirect } = props;

    const { order_id, routes, included, timestamp, booking_status, timelimit, order_status, session_id } = order;

    const { t, language } = i18next;
    const [actionButtonsBlock, setActionButtonsBlock] = useState(false);
    const [rotate, setRotate] = useState(false);
    const history = useNavigate();
    const dispatch = useDispatch();
    const { showSnackbar } = useContext(SnackbarContext);

    const orderPay = () => {
        history(`/order/${order_id}`);
    };

    const orderCancel = () => {
        const params = {
            session_id,
            order_id,
        };

        cancelOrder({ ...params })
            .then(response => {
                getOrdersList().then(response => {
                    dispatch(
                        setOrdersData({
                            ordersData: response,
                        })
                    );
                });
                setActionButtonsBlock(false);
                showSnackbar({
                    alertText: t('order_cancel_successfully_mess'),
                });
            })
            .catch(err => console.log(err));
    };

    const getMK = () => {
        downloadMK({ order_id, session_id, type: 'mk' })
            .then(response => {
                const { pdf } = response;

                checkForMIMEType(pdf);
            })
            .catch(err => console.log(err.message));
    };

    const toggleActionsBlock = () => {
        setActionButtonsBlock(!actionButtonsBlock);
        setRotate(!rotate);
    };

    const formatDateWithoutYear = (date, language) => {
        const convertedDate = moment(date, config.webTransferTimeFormat).locale(language);

        return convertedDate.format(config.serverDateTimeFormatWithoutYear).replace('.', '');
    };

    const getDepartmentTime = (routes, language) => {
        return routes.map(route => {
            const firstSegment = _.first(route.segments);

            if (firstSegment) {
                const convertedDate = moment(firstSegment.departure.time, config.webTransferTimeFormat).locale(
                    language
                );
                return convertedDate.format(config.dateFormatWithoutPoint).replace('.', '');
            }
        });
    };

    const getSeparator = routes => {
        const count = routes.length;

        if (count === 0) return null;

        const departureSegment = _.first(_.first(routes).segments);
        const arrivalSegment = _.last(_.last(routes).segments);

        if (_.isUndefined(departureSegment) || _.isUndefined(arrivalSegment)) {
            return ', ';
        }

        const departureAirport = departureSegment.departure.airport;
        const arrivalAirport = arrivalSegment.arrival.airport;

        if (count === 2 && departureAirport === arrivalAirport) {
            return ' - ';
        }

        return ', ';
    };

    const departureTime = getDepartmentTime(routes, language);
    const separator = getSeparator(routes);
    const canPay = checkInProgressStatus(booking_status, order_status);
    const canMK = checkAllowedMK(booking_status, order_status);
    const canCancel = checkIsAllowedCancel(booking_status, order_status);

    return (
        <Wrapper background={order_status == 'success' ? 'success' : 'cancelled'}>
            <TitleBlock onClick={() => orderRedirect(order_id)}>
                <Title>
                    {t('order')} {order_id}{' '}
                </Title>
                <RoutesInfo routes={routes} included={included} />
            </TitleBlock>
            <TitleBlock>
                <Date>
                    {t('created')} {formatDateWithoutYear(timestamp, language)}
                </Date>
            </TitleBlock>
            <StatusButtonBlock>
                <BookingStatus bookingStatus={booking_status} orderStatus={order_status} timelimit={timelimit} />
                <Button onClick={toggleActionsBlock}>
                    <ActionBlock>{t('action')}</ActionBlock>
                    <IconBlock rotate={rotate ? 1 : 0}>
                        <ChevronIcon />
                    </IconBlock>
                </Button>
            </StatusButtonBlock>
            {actionButtonsBlock && (
                <ActionsBlock>
                    <ActionBlocksButton
                        backgroundColor="#EBEBEB"
                        borderRadius="5"
                        onClick={orderPay}
                        disabled={canPay ? 0 : 1}
                    >
                        {t('to_pay')}
                        <CartIcon />
                    </ActionBlocksButton>
                    <ActionBlocksButton
                        backgroundColor="#EBEBEB"
                        borderRadius="5"
                        onClick={orderCancel}
                        disabled={canCancel ? 0 : 1}
                    >
                        {t('cancel')}
                        <CircleCrossIcon />
                    </ActionBlocksButton>
                    <ActionBlocksButton
                        backgroundColor="#EBEBEB"
                        borderRadius="5"
                        onClick={getMK}
                        disabled={canMK ? 0 : 1}
                    >
                        {t('itinerary_receipt')}
                        <MKIcon />
                    </ActionBlocksButton>
                </ActionsBlock>
            )}
        </Wrapper>
    );
};

export default Order;
