import styled from 'styled-components';
import {
    GREY_GAINSBORO_COLOR,
    GUARDSMAN_RED_COLOR,
    ORANGE_COLOR,
    RHINO_COLOR,
    RUBIK_FONT,
    WHITE_COLOR,
} from '../../constants';

export const PaymentMethodsBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    flex-wrap: wrap;
    height: 170px;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px ${GREY_GAINSBORO_COLOR};
    border-radius: 5px;
    border-bottom: 12px solid ${props => (props.checked ? ORANGE_COLOR : GREY_GAINSBORO_COLOR)};

    @media (max-width: 899px) {
        width: 100%;
        height: 140px;
    }
`;

export const PaymentImgBG = styled.img`
    max-height: 120px;
    max-width: 200px;

    @media (max-width: 899px) {
        max-height: 100px;
    }
`;

export const PaymentDescription = styled.span`
    font-size: 22px;
    font-family: ${RUBIK_FONT};
    color: ${RHINO_COLOR};
    text-align: center;
`;

export const NotEnoughBalanceText = styled.span`
    color: ${GUARDSMAN_RED_COLOR};
    text-align: center;
    padding: 0 10px;
`;
