import { useEffect } from 'react';
import i18next from 'i18next';

import Modal from '../ui/modal';
import {
    TextBlock,
    LogoBlock,
    Logo,
    EventText,
    ButtonBlock,
    Button,
} from './components';

import dcLogoImage from '../../../assets/somon_air/images/otherLogos/dcPayment.png';

const DcPaymentPopup = ({ showPopup, handleClose, payUrl, payform }) => {
    const { t } = i18next;

    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            if (showPopup) {
                document.body.classList.add('hidden');
            } else {
                document.body.classList.remove('hidden');
            }
        };
    }, [showPopup]);

    if (!showPopup) return null;

    return (
        <Modal show={showPopup} onClose={handleClose} maxWidth={600} fixedWidth>
            <TextBlock>
                <LogoBlock>
                    <Logo src={dcLogoImage} alt="dc-logo" />
                </LogoBlock>

                <EventText>{t('confirm_actions')}</EventText>

                <form action={payUrl} method="POST">
                    <textarea name="Request" hidden>
                        {payform}
                    </textarea>
                    <ButtonBlock>
                        <Button type="submit">{t('confirm')}</Button>
                    </ButtonBlock>
                </form>
            </TextBlock>
        </Modal>
    );
};

export default DcPaymentPopup;
