import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setDesignSettings } from '../store/designSettingsSlice';

import UserEdit from '../components/somon_air/pages/userEdit';

function ProfileEdit() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setDesignSettings({
                isStickyHeader: true,
                showSearchBlock: true,
                isSmallSearchBlock: true,
            })
        );
    }, []);

    return <UserEdit />;
}

export default withTranslation()(ProfileEdit);
