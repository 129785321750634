import styled from 'styled-components';

import { LIGHT_GREY_COLOR, RED_COLOR, BLACK_COLOR, RUBIK_FONT } from '../../constants';

export const InputGroup = styled.div`
    width: 100%;
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    font-family: ${RUBIK_FONT};

    & > span {
        color: ${RED_COLOR};
    }

    @media (max-width: 899px) {
        font-size: calc(1rem * 0.9);
    }
`;

export const InputBlock = styled.div`
    display: flex;
    height: 64px;
    align-items: center;
    border-radius: ${props => props.borderRadius}px;
    border: ${props => (props.boxShadow ? 'none' : `1px solid ${props => props.borderColor}`)};
    background-color: ${LIGHT_GREY_COLOR};
    box-shadow: ${props => (props.boxShadow ? `0px 0px 10px ${BLACK_COLOR}33` : 'none')};

    @media (max-width: 899px) {
        height: 50px;
    }
`;

export const IconBlock = styled.div`
    margin-left: 12px;

    @media (max-width: 899px) {
        margin-left: 8px;
    }
`;

export const SelectElement = styled.select`
    font-size: 16px;
    border: none;
    line-height: 1;
    padding: 16px;
    flex-grow: 1;
    font-family: ${RUBIK_FONT};

    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 12px);
    background-position-y: calc(50% - 1px);

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }

    @media (max-width: 899px) {
        padding: 8px;
        font-size: calc(16px * 0.9);
    }
`;

export const Error = styled.div`
    color: ${RED_COLOR};
    font-size: 14px;
    margin-top: 4px;
`;
