import styled from 'styled-components';
import { MIDNIGHT_COLOR, ORANGE_COLOR, RUBIK_FONT, WHITE_COLOR, ZAMBEZI_COLOR } from '../../constants';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: ${WHITE_COLOR};
    margin-top: 50px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px ${MIDNIGHT_COLOR};

    @media (min-width: 900px) and (max-width: 1279px) {
        padding: calc(15px + (30 - 15) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        padding: calc(15px + (30 - 15) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        padding: 15px;
    }
`;

export const Blocks = styled.div``;

export const Block = styled.div`
    display: flex;

    @media (max-width: 599px) {
        flex-direction: column;
        gap: 15px;
    }
`;

export const BlockTitle = styled.div`
    width: 280px;
    font-size: 28px;
    font-weight: 500;
    font-family: ${RUBIK_FONT};
    color: ${ZAMBEZI_COLOR};
    line-height: 1;

    @media (min-width: 1280px) and (max-width: 1529px) {
        font-size: calc(22px + (28 - 22) * ((100vw - 1280px) / (1530 - 1280)));
        width: calc(220px + (280 - 220) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: calc(18px + (22 - 18) * ((100vw - 900px) / (1280 - 900)));
        width: calc(150px + (220 - 150) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        font-size: calc(18px + (22 - 18) * ((100vw - 600px) / (900 - 600)));
        width: calc(150px + (220 - 150) * ((100vw - 600px) / (900 - 600)));
    }
    
    @media (max-width: 599px) {
        width: 100%;
        font-size: 18px;
        text-align: center;
    }
`;

export const BlockValues = styled.div`
    flex-grow: 1;
`;

export const DividerLine = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${ORANGE_COLOR};
    border-radius: 2px;
    margin: 20px 0;
`;

export const BookingInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px 0;
    width: 100%;

    @media (max-width: 599px) {
        gap: 15px;
    }
`;

export const BookingInfoRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    @media (max-width: 599px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
    }
`;

export const BookingInfoTitle = styled.div`
    font-family: ${RUBIK_FONT};
    font-size: 22px;
    font-weight: 300;

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: calc(16px + (22 - 16) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        font-size: calc(16px + (22 - 16) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        font-size: 16px;
    }
`;

export const BookingInfoValue = styled.div`
    font-family: Rubik;
    font-size: 22px;
    font-weight: 300;

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: calc(16px + (22 - 16) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        font-size: calc(16px + (22 - 16) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        font-size: 18px;
    }
`;

export const BuyerInfoBlock = styled.div`
    display: flex;
    flex-wrap: wrap;

    & > div {
        font-family: ${RUBIK_FONT};
        font-size: 22px;
        color: ${ZAMBEZI_COLOR};
    }

    & > div:nth-of-type(1) {
        margin-right: 30px;
    }

    @media (min-width: 900px) and (max-width: 1279px) {
        & > div {
            font-size: calc(16px + (22 - 16) * ((100vw - 900px) / (1280 - 900)));
        }
    }

    @media (min-width: 600px) and (max-width: 899px) {
        & > div {
            font-size: calc(16px + (22 - 16) * ((100vw - 600px) / (900 - 600)));
        }
    }

    @media (max-width: 599px) {
        & > div {
            font-size: 16px;
        }
    }
`;
