import { useState, useEffect } from 'react';
import DesktopRecommendation from './desktop/recommendation';
import { ContainerFilter } from './filter/components';
import MobileRecommendation from './mobile/recommendation';
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};
function ResultPage({ recommendation, index, onBookingClick, getFareRules, getBrandFares, specialText = null }) {
    const { width } = useWindowDimensions();

    return (
        <div>
            <ContainerFilter>
                {width >= 900 && (
                    <DesktopRecommendation
                        index={index}
                        recommendation={recommendation}
                        onBookingClick={onBookingClick}
                        getFareRules={getFareRules}
                        getBrandFares={getBrandFares}
                        specialText={specialText}
                    />
                )}
                {width < 900 && (
                    <MobileRecommendation
                        index={index}
                        recommendation={recommendation}
                        onBookingClick={onBookingClick}
                        getFareRules={getFareRules}
                        getBrandFares={getBrandFares}
                        specialText={specialText}
                    />
                )}
            </ContainerFilter>
        </div>
    );
}

export default ResultPage;
