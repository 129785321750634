import styled from 'styled-components';
import { RHINO_COLOR } from '../../../../../constants';

export const RouteBlock = styled.div`
    margin-top: 8px;

    &:nth-of-type(1) {
        margin-top: 2px;
    }
`;

export const RouteCities = styled.div`
    font-size: 15px;
    margin-bottom: 4px;
    font-weight: bold;
    color: ${RHINO_COLOR};
`;
