import styled from 'styled-components';

const Button = styled.button`
    font-size: ${props => props.fontSize}px;
    font-weight: ${props => props.fontWeight};
    text-transform: ${props => props.textTransform};
    color: ${props => props.color};
    width: 100%;
    position: relative;
    transition: padding-right 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }

    &.transition:hover {
        padding-right: ${props => (props.transitionIcon ? '56px' : 0)};
        transition: padding-right 0.3s ease-in-out;
    }

    &:hover > .transition {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
    }

    &:disabled {
        cursor: default;
        opacity: .7;
    }

    @media (max-width: 899px) {
        font-size: calc(${props => props.fontSize}px * ${props => props.mobileFontSizeMultiplier});
        padding: 0;

        &.transition:hover {
            padding-right: 0;
            transition: none;
        }

        &:hover > .transition {
            transition: none;
            opacity: 0;
        }
    }
`;

export const FilledButton = styled(Button)`
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};
    border-radius: ${props => props.borderRadius}px;
    height: ${props => props.height}px;
    padding: ${props => props.padding};

    @media (max-width: 899px) {
        height: ${props => props.height * 0.8}px;
        padding: ${props => props.mobilePadding};
    }
`;

export const OutlineButton = styled(Button)`
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};
    border-radius: ${props => props.borderRadius}px;
    height: ${props => props.height}px;

    @media (max-width: 899px) {
        height: ${props => props.height * 0.8}px;
    }
`;

export const TextButton = styled(Button)`
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    display: inline-block;
    width: auto;
`;

export const IconButton = styled(Button)`
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};
    border-radius: ${props => props.borderRadius}px;
    height: ${props => props.height}px;

    @media (max-width: 899px) {
        height: ${props => props.height * 0.8}px;
    }
`;

export const TransitionIcon = styled.span`
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border-top-right-radius: ${props => props.borderRadius}px;
    border-bottom-right-radius: ${props => props.borderRadius}px;
    border-left: 1px solid ${props => props.borderColor};
    background-color: ${props => props.backgroundColor};
    width: 56px;
    transition: opacity 0.3s ease-in-out;
`;
