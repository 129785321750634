import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import envConfigs from '../configs/envConfigs';

import PrivateRoute from './privateRoute';

import ArticlePage from '../pages/article';
import AuthPage from '../pages/auth';
import BookingPage from '../pages/booking';
import FaqPage from '../pages/faq';
import ForgotPasswordPage from '../pages/forgotPassword';
import HomePage from '../pages/home';
import NewsPage from '../pages/news';
import NotFoundPage from '../pages/notFound';
import OrderPage from '../pages/order';
import OrdersPage from '../pages/orders';
import PartnerPage from '../pages/partner';
import PartnersPage from '../pages/partners';
import PopularDestinationsPage from '../pages/popularDestinations';
import ProfilePage from '../pages/profile';
import RefillPage from '../pages/refill';
import RegisterPage from '../pages/register';
import ResetPasswordPage from '../pages/resetPassword';
import ResultPage from '../pages/result';
import StaticPage from '../pages/static';
import TourPage from '../pages/tour';
import ToursPage from '../pages/tours';
import TransactionsPage from '../pages/transactions';
import UserEdit from '../pages/profileEdit';
import RefundOrderPage from '../pages/refundOrder';

const RoutesBlock = () => {
    const Layout = React.lazy(() => import(`../components/somon_air/layout`));

    return (
        <Suspense>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route exact path="/auth" element={<AuthPage />} />
                    <Route exact path="/register" element={<RegisterPage />} />
                    <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route exact path="/reset/:token" element={<ResetPasswordPage />} />
                    <Route exact path="/result/:searchId" element={<ResultPage />} />
                    <Route exact path="/booking" element={<BookingPage />} />
                    <Route exact path="/order/:orderId" element={<OrderPage />} />
                    <Route exact path="/news" element={<NewsPage />} />
                    <Route exact path="/article/:id" element={<ArticlePage />} />
                    <Route exact path="/tours" element={<ToursPage />} />
                    <Route exact path="/tour/:id" element={<TourPage />} />
                    <Route exact path="/static/:id" element={<StaticPage />} />
                    <Route exact path="/partners" element={<PartnersPage />} />
                    <Route exact path="/partner/:slug" element={<PartnerPage />} />
                    <Route exact path="/popular-destinations" element={<PopularDestinationsPage />} />
                    <Route exact path="/useful-info" element={<FaqPage />} />
                    <Route exact path="/refund-order" element={<RefundOrderPage />} />

                    <Route exact path="/profile" element={<PrivateRoute />}>
                        <Route exact path="/profile" element={<ProfilePage />} />
                    </Route>
                    <Route exact path="/profile-edit" element={<PrivateRoute />}>
                        <Route exact path="/profile-edit" element={<UserEdit />} />
                    </Route>

                    <Route exact path="/orders" element={<PrivateRoute />}>
                        <Route exact path="/orders" element={<OrdersPage />} />
                    </Route>

                    <Route exact path="/refill" element={<PrivateRoute />}>
                        <Route exact path="/refill" element={<RefillPage />} />
                    </Route>

                    <Route exact path="/transactions" element={<PrivateRoute />}>
                        <Route exact path="/transactions" element={<TransactionsPage />} />
                    </Route>
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default RoutesBlock;
