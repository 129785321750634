import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import * as Img from '../../lib/getImage';
import noun from 'plural-ru';
import verb from 'plural-ru';
import { ADT, CHD, INS, INF } from '../../containers/search/constants';
import {
    BOOK_STATUS_COLOR,
    IN_PROGRESS_BOOK_CODE,
    BOOKING_DONE_CODE,
    INITIAL_CODE,
    BOOKING_FAIL_CODE,
    PAY_FAIL_CODE,
    TICKETING_DONE_CODE,
    PARTIAL_REFUND_CODE,
    ORDER_FAIL_CODE,
    APPLICATION_REFUND_CODE,
    BOOK_STATUS_CODES,
    PAY_SUCCESS_CODE,
    TICKETING_FAIL_CODE,
    VOID_CODE,
    REFUND_CODE,
    BALANCE_PAYMENT_REQUEST_CODE,
    BALANCE_PAYMENT_APPROVED_CODE,
    CERTIFICATE_ISSUED_CODE,
} from '../../containers/order/constants';
import { NP, PS, PSP, SR } from '../../containers/booking/constants';
import { mainConfigs } from '../../configs/mainConfigs';
import i18next from 'i18next';

const TransferTimeFormat = mainConfigs.webTransferTimeFormat;
const formatForGroupOrders = mainConfigs.formatForGroupOrders;

export const RenderDurationTime = props => {
    const { t } = i18next;

    const hours = Math.trunc(props.duration / 3600);
    const minutes = Math.floor((props.duration - hours * 3600) / 60);

    return (
        <span>
            {hours} {t.hour_short} {minutes} {t.minutes_short_3}
        </span>
    );
};

export const GetTime = data => {
    const timeArray = data.time.split(' ');
    return timeArray[1];
};

export const RenderAirlineLogo = data => {
    const { supplier, horizontally } = data;
    if (supplier) {
        const imgUrl = Img.getSupplierIcon(supplier);
        return <div horizontally={horizontally || 'right'} imgUrl={imgUrl} />;
    }
    return <div />;
};

export const GetCityString = data => {
    const { language } = i18next;
    const { included, items } = data;
    const { city, airport } = items;
    const cityName = included.city[city].name[language];
    const airportName = included.airport[airport].name[language];

    if (cityName === airportName) {
        return cityName;
    } else {
        return (
            <span>
                {airportName}, {cityName}
            </span>
        );
    }
};

export const RenderTechStops = data => {
    const { t, language } = i18next;
    const { items, included } = data;

    const tehStops = items
        .map(item => {
            let time = '';
            if (item.duration.hour > 0) {
                time = item.duration.hour + ' ' + t.hour_short + ' ';
            }
            time += item.duration.minute + ' ' + t.minutes_short_3;

            const cityCode = item.city.code;

            return included.city[cityCode].name[language] + ' ' + time;
        })
        .join(', ');

    return (
        <div>
            {t.teh_stop}: {tehStops}
        </div>
    );
};

export const FormattedDateTime = (data, withTime = false) => {
    const { language } = i18next;
    const momentObj = getMonetObj(data.time, language);

    let dateFormat = mainConfigs.serverDateFormat;

    if (withTime) {
        dateFormat = mainConfigs.serverDateFormat;
    }

    return momentObj.format(dateFormat).replace('.', '');
};

export const FormattedDateTimeWithoutYear = (data, language) => {
    const momentObj = getMonetObj(data, language);

    return momentObj.format(mainConfigs.serverDateTimeFormatWithoutYear).replace('.', '');
};

export const FormattedForOrdersGroup = (data, language) => {
    return data.locale(parserLangCode(language)).format(formatForGroupOrders);
};

export const getDayMonthYearFormat = (data, language) => {
    const momentObj = getMonetObj(data, language);

    return momentObj.format(mainConfigs.dateFormatWithoutPoint).replace('.', '');
};

export const getCreatedTime = (data, language) => {
    const momentObj = getMonetObj(data, language);
    const newFormat = 'DD MMM YYYY, HH:mm';

    return momentObj.format(newFormat).replace('.', '');
};

const getMonetObj = (data, language) => {
    return moment(data, TransferTimeFormat).locale(parserLangCode(language));
};

export const GetTransferTime = (route, index, t) => {
    const prevDate = route.segments[index - 1].arrival.time;
    const actualDate = route.segments[index].departure.time;
    const prevDateUnix = moment(prevDate, TransferTimeFormat).unix();
    const actualDateUnix = moment(actualDate, TransferTimeFormat).unix();
    const unixDifference = actualDateUnix - prevDateUnix;
    const hours = Math.trunc(unixDifference / 3600);
    const minutes = Math.floor((unixDifference - hours * 3600) / 60);

    return hours + ' ' + t('hour_short') + ' ' + minutes + ' ' + t('minutes_short_3');
};

export const getPassTypeCountString = (count, type, t) => {
    switch (type) {
        case ADT:
            return noun(count, t('one_adt'), t('more_adt'), t('many_adt'));
        case CHD:
            return noun(count, t('one_chd'), t('more_chd'), t('many_chd'));
        case INS:
        case INF:
            return noun(count, t('one_inf'), t('more_inf'), t('many_inf'));
    }
};

export const getBookStatus = (bookingStatus, orderStatus, t) => {
    if (bookingStatus === null && orderStatus !== ORDER_FAIL_CODE) {
        bookingStatus = IN_PROGRESS_BOOK_CODE;
    }

    if (bookingStatus === null && orderStatus === ORDER_FAIL_CODE) {
        bookingStatus = ORDER_FAIL_CODE;
    }

    const bookStatus = _.find(BOOK_STATUS_CODES, { value: bookingStatus });

    return t(`${bookStatus?.code}`);
};

export const getColorStatus = status => {
    if (status === null) {
        status = IN_PROGRESS_BOOK_CODE;
    }

    const bookStatus = _.find(BOOK_STATUS_COLOR, { value: status });

    return bookStatus?.color;
};

export const getBaggageCountString = (count, t) => {
    return noun(count, t('place_one'), t('place_more'), t('place_many'));
};

export const getFlightsCountString = (count, t) => {
    return noun(count, t('flight_one'), t('flights_more'), t('flights_many'));
};

export const getFlightsVebString = (count, t) => {
    return verb(count, t('found_one'), t('found_more'), t('found_more'));
};

export const checkAllowedMK = bookStatus => {
    return (
        bookStatus === BOOKING_DONE_CODE ||
        bookStatus === PARTIAL_REFUND_CODE ||
        bookStatus === APPLICATION_REFUND_CODE ||
        bookStatus === PAY_SUCCESS_CODE ||
        bookStatus === PAY_FAIL_CODE ||
        bookStatus === TICKETING_FAIL_CODE ||
        bookStatus === VOID_CODE ||
        bookStatus === REFUND_CODE ||
        bookStatus === TICKETING_DONE_CODE ||
        bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
        bookStatus === BALANCE_PAYMENT_APPROVED_CODE ||
        bookStatus === CERTIFICATE_ISSUED_CODE
    );
};

export const checkIsAllowedCancel = (bookStatus, orderStatus) => {
    return (
        (bookStatus === BOOKING_DONE_CODE ||
            bookStatus === INITIAL_CODE ||
            bookStatus === null ||
            bookStatus === BOOKING_FAIL_CODE ||
            bookStatus === PAY_FAIL_CODE ||
            bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
            bookStatus === BALANCE_PAYMENT_APPROVED_CODE) &&
        orderStatus !== ORDER_FAIL_CODE
    );
};

export const checkIsAllowBalancePaymentApproved = bookStatus => {
    return bookStatus === BALANCE_PAYMENT_REQUEST_CODE;
};

export const checkInProgressStatus = (bookStatus, orderStatus) => {
    return (
        (bookStatus === BOOKING_DONE_CODE || bookStatus === INITIAL_CODE || bookStatus === null) &&
        orderStatus !== ORDER_FAIL_CODE
    );
};

export const checkInAccessToPaymentStatus = (bookStatus, orderStatus) => {
    return (
        (bookStatus === BOOKING_DONE_CODE ||
            bookStatus === INITIAL_CODE ||
            bookStatus === null ||
            bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
            bookStatus === BALANCE_PAYMENT_APPROVED_CODE) &&
        orderStatus !== ORDER_FAIL_CODE
    );
};

export const checkIsAllowedRefund = status => {
    return status === TICKETING_DONE_CODE;
};

export const setAuthDataToStore = props => {
    const { token_type, access_token, client_code, partner_code } = props;

    localStorage.setItem('token_type', token_type);
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('client_code', client_code);
    localStorage.setItem('partner_code', partner_code);
};

export const removeAuthDataInStore = () => {
    localStorage.removeItem('token_type');
    localStorage.removeItem('access_token');
    localStorage.removeItem('client_code');
    localStorage.removeItem('partner_code');
};

export const getRouteCity = (route, included, language) => {
    const departureSegment = _.first(route.segments);
    const arrivalSegment = _.last(route.segments);
    const departureTime = departureSegment.departure.time;
    const departureCode = departureSegment.departure.city;
    const arrivalCode = arrivalSegment.arrival.city;
    const arrivalCity = included.city[arrivalCode].name[language];
    const departureCity = included.city[departureCode].name[language];

    return [departureCity, arrivalCity, departureTime];
};

export const getSegmentCity = (segment, included, language) => {
    const departureTime = segment.departure.time;
    const departureCode = segment.departure.city;
    const arrivalCode = segment.arrival.city;
    const arrivalCity = included.city[arrivalCode].name[language];
    const departureCity = included.city[departureCode].name[language];

    return [departureCity, arrivalCity, departureTime];
};

export const renderServiceClass = (serviceClass, t) => {
    const { code, name } = serviceClass;

    if (!_.isEmpty(code)) {
        return `${t.class}: ${name} (${code})`;
    }

    return `${t.class}: ${name}`;
};

export const getAge = (dateOfBirth, format = 'YYYY-MM-DD') => {
    return moment().diff(moment(dateOfBirth, format), 'years');
};

export const getAgeMonths = (dateOfBirth, format = 'YYYY-MM-DD') => {
    return moment().diff(moment(dateOfBirth, format), 'months');
};

export const getMonthsString = (age, t) => {
    return noun(age, '%d ' + t.month_one, '%d ' + t.months_more, '%d ' + t.months);
};

export const getAgeString = (age, t) => {
    return noun(age, '%d ' + t.year_one, '%d ' + t.year_more, '%d ' + t.years);
};

export const getNoteString = (count, t) => {
    return noun(count, '%d ' + t.record_one, '%d ' + t.record_more, '%d ' + t.record_many);
};

export const getDocumentType = type => {
    const { t } = i18next;

    const DOCUMENTS_ITEMS = [
        { value: PS, label: t('russian_passport') },
        { value: SR, label: t('birth_certificate') },
        { value: PSP, label: t('international_passport') },
        { value: NP, label: t('foreign_document') },
    ];

    const documentType = _.find(DOCUMENTS_ITEMS, { value: type });

    return documentType.label;
};

export const formatDates = data => {
    const dataArray = data.split('-');

    return dataArray[2] + '-' + dataArray[1] + '-' + dataArray[0];
};

export const getAgeType = type => {
    const { t } = i18next;

    return type === INS ? `${t(`${type}_main_label`)} (${t('inf_with_seat_second_label')})` : t(`${type}_main_label`);
};

export const getGender = type => {
    const { t } = i18next;

    const GENDER_ITEMS = [
        { value: 'male', label: t('male') },
        { value: 'female', label: t('female') },
    ];

    const genderString = _.find(GENDER_ITEMS, { value: type });

    return genderString.label;
};

export const parserLangCode = language => {
    return language === 'tj' ? 'tg' : language;
};
