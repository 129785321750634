export const authRoutes = {
    orders: '/orders',
    profile: '/profile',
    profileEdit: '/profile-edit',
    transactions: '/transactions',
    refill: '/refill',
};

export const nonAuthRoutes = {
    home: '/',
    auth: '/auth',
    register: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset/:token',
    result: '/result/:searchId',
    booking: '/booking',
    order: '/order/:orderId',
    news: '/news',
    article: '/article/:id',
    tours: '/tours',
    tour: '/tours/:id',
    partners: '/partners',
    partner: '/partners/:slug',
    static: '/static/:id',
    popularDestinations: '/popular-destinations',
    usefulInfo: '/useful-info',
    notFound: '*',
};
