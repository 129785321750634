import {
    BALANCE_PAYMENT_APPROVED_CODE,
    BALANCE_PAYMENT_REQUEST_CODE,
    BOOKING_DONE_CODE,
    INITIAL_CODE,
    ORDER_FAIL_CODE,
} from '../constants';

export const checkInAccessToPaymentStatus = (bookStatus, orderStatus) => {
    return (
        (bookStatus === BOOKING_DONE_CODE ||
            bookStatus === INITIAL_CODE ||
            bookStatus === null ||
            bookStatus === BALANCE_PAYMENT_REQUEST_CODE ||
            bookStatus === BALANCE_PAYMENT_APPROVED_CODE) &&
        orderStatus !== ORDER_FAIL_CODE
    );
};
