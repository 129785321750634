export const RALEWAY_FONT = 'Raleway';
export const RUBIK_FONT = 'Rubik';
export const BACKGROUND_COLOR = '#EBEBEB';
export const WHITE_COLOR = '#FFF';
export const FLORAL_WHITE_COLOR = '#FFFAEF';

export const RED_COLOR = '#FF0000';
export const RED_SANGRIA_COLOR = '#97000A';

export const DARK_RED_COLOR = '#870000';

export const GREEN_COLOR = '#00A542';

export const DARK_COLOR = '#272727';
export const BRIGHT_COLOR = '#414141';
export const MEDIUM_DARK_COLOR = '#343434';

export const GREY_COLOR = '#C4C4C4';
export const GREY86_COLOR = '#00000024';
export const DARK_GREY_COLOR = '#AAA';
export const LIGHT_GREY_COLOR = '#F6F6F6';
export const GREY81_COLOR = '#CFCFCF';
export const DIM_GREY_COLOR = '#707070';
export const SUVA_GREY_COLOR = '#8b8b8b';

export const LIGHT_GREEN_COLOR = '#5abf82';
export const DARK_GREEN_COLOR = '#006400';

export const YELLOW_COLOR = '#FFBA33';
export const DARK_YELLOW_COLOR = '#FFC446';
export const LIGHT_YELLOW_COLOR = '#FFF1D4';
export const BRIGHTER_YELLOW_COLOR = '#FFDA8D';

export const ORANGE_COLOR = '#F1B740';
export const DARK_ORANGE = '#E89C00';
export const LIGHT_ORANGE_COLOR = '#FFD88D';
export const LIGHTER_ORANGE_COLOR = '#FFF5E2';

export const DARK_BLUE = '#04152D';
export const REGAL_BLUE_COLOR = '#003B6E';
export const BLACK_COLOR = '#000000';
export const MAIN_BLUE_COLOR = '#2d82cc';

export const MIDNIGHT_COLOR = '#00000033';
export const ZAMBEZI_COLOR = '#5D5D5D';
export const DARKER_GREY_COLOR = '#A4A4A4';
export const GREY_DARK_COLOR = '#A5A5A5';
export const GAINSBORO_COLOR = '#E0E0E0';
export const DARK_CERULEAN_COLOR = '#044476';
export const RHINO_COLOR = '#404B5A';
export const NIGHT_RIDER_COLOR = '#2C2C2C';
export const GREY_GAINSBORO_COLOR = '#e2e2e2';
export const GREY_DARKER_COLOR = '#878787';
export const TRANSPARENT_COLOR = '#ffffff00';
export const NERO_COLOR = '#212121';
export const NOBEL_COLOR = '#9d9d9d';
export const PINK_SWAN_COLOR = '#b4b4b4';
export const PANTONE_COLOR = '#00000029';
export const SPECIAL_GREEN_COLOR = '#5ba554';
export const FUN_GREEN_COLOR = '#006A2A';
export const ALTO_COLOR = '#D2D2D2';
export const LINEN_COLOR = '#FBE9E7';
export const ROMANTIC_COLOR = '#FFCCBC';
export const BRIGHT_RED_COLOR = '#A40000';
export const EUNRY_COLOR = '#CB9D9D';
export const REGAL_BLUE_FOR_BACKGROUND_COLOR = '#014375';
export const DOVE_GRAY = '#636363';
export const GUARDSMAN_RED_COLOR = '#CE0000';
