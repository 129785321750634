import styled from 'styled-components';
import { BLACK_COLOR, ORANGE_COLOR, RUBIK_FONT, WHITE_COLOR, ZAMBEZI_COLOR } from '../../constants';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 38px 25px;
    background-color: ${WHITE_COLOR};
    border-radius: 5px;
    box-shadow: 0px 0px 10px ${BLACK_COLOR}33;

    @media (min-width: 900px) and (max-width: 1279px) {
        padding: 25px 15px;
    }
    
    @media (min-width: 600px) and (max-width: 899px) {
        padding: 35px 20px;
    }

    @media (max-width: 599px) {
        padding: 25px 15px;
    }
`;

export const Top = styled.div``;

export const PriceItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const PriceTitle = styled.span`
    font-family: ${RUBIK_FONT} !important;
    font-size: 18px;
    color: ${ZAMBEZI_COLOR};
    font-weight: 500;

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: 14px;
    }
    
    @media (min-width: 599px) and (max-width: 899px) {
        font-size: 18px;
    }

    @media (max-width: 599px) {
        font-size: 14px;
    }
`;

export const PriceValue = styled.span`
    font-family: ${RUBIK_FONT} !important;
    font-size: 18px;
    color: ${ZAMBEZI_COLOR};
    font-weight: 500;

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: 14px;
    }
    
    @media (min-width: 599px) and (max-width: 899px) {
        font-size: 18px;
    }

    @media (max-width: 599px) {
        font-size: 14px;
    }

    & > span {
        font-size: 11px;
        font-weight: 700;

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: 9px;
        }
        
        @media (min-width: 599px) and (max-width: 899px) {
            font-size: 11px;
        }

        @media (max-width: 599px) {
            font-size: 9px;
        }
    }
`;

export const LineDivider = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${ORANGE_COLOR};
    border-radius: 2px;
    margin: 25px 0;
`;

export const Bottom = styled.div``;

export const TotalItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TotalTitle = styled.span`
    font-family: ${RUBIK_FONT} !important;
    font-size: 25px;
    color: ${ZAMBEZI_COLOR};
    font-weight: 500;

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: 20px;
    }
    
    @media (min-width: 599px) and (max-width: 899px) {
        font-size: 25px;
    }

    @media (max-width: 599px) {
        font-size: 20px;
    }
`;

export const TotalValue = styled.span`
    font-family: ${RUBIK_FONT} !important;
    font-size: 25px;
    color: ${ORANGE_COLOR};
    font-weight: 500;

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: 20px;
    }
    
    @media (min-width: 599px) and (max-width: 899px) {
        font-size: 25px;
    }

    @media (max-width: 599px) {
        font-size: 20px;
    }

    & > span {
        color: ${ZAMBEZI_COLOR};
        font-size: 14px;
        font-weight: 700;

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: 12px;
        }
        
        @media (min-width: 599px) and (max-width: 899px) {
            font-size: 14px;
        }

        @media (max-width: 599px) {
            font-size: 12px;
        }
    }
`;
