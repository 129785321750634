import { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../../../protocols';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { Container } from '../../ui/common';
import { Wrapper, Item, Title, Label, Button } from './components';
import { CircularProgress } from '@mui/material';
import InputText from '../../ui/input';
import { useSelector } from 'react-redux';

const ResetPassword = () => {
    const { t } = i18next;
    const history = useNavigate();
    const { isAuth } = useSelector(state => state.authSettings);

    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useContext(SnackbarContext);
    const { token } = useParams();

    useEffect(() => {
        if (isAuth) {
            history('/');
        }
    }, []);

    const validationSchemaReset = Yup.object().shape({
        password: Yup.string().min(6, t('min_6_symbol')).required(t('required')),
        confirmationPassword: Yup.string()
            .min(6, t('min_6_symbol'))
            .required(t('required'))
            .oneOf([Yup.ref('password'), null], t('passwords_must_match')),
    });


    const handleActivateFormSubmit = values => {
        if (isLoading) {
            return;
        }

        let newValues = {
            ...values,
            token
        };

        setIsLoading(true);

        resetPassword({ ...newValues })
            .then(response => {
                if (!response.error) {
                    showSnackbar({
                        alertText: `${t('reset_success_title')}`,
                    });
                    history('/');
                } else {
                    resetForm.setFieldError('password', response.message);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const resetForm = useFormik({
        initialValues: {
            password: '',
            confirmationPassword: '',
        },
        validationSchema: validationSchemaReset,
        onSubmit: handleActivateFormSubmit,
        validateOnBlur: true,
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = resetForm;

    return (
        <Container>
            <Wrapper>
                <Title>{t('reset_password')}</Title>
                <Item>
                    <Label>{t('password_text')}</Label>
                    <InputText
                        value={values.password}
                        onChange={handleChange('password')}
                        type="password"
                        required
                        error={errors.password}
                        onBlur={handleBlur('password')}
                        touched={touched.password}
                    />
                </Item>
                <Item>
                    <Label>{t('confirmation_activation_password')}</Label>
                    <InputText
                        value={values.confirmationPassword}
                        onChange={handleChange('confirmationPassword')}
                        type="password"
                        required
                        error={errors.confirmationPassword}
                        onBlur={handleBlur('confirmationPassword')}
                        touched={touched.confirmationPassword}
                    />
                </Item>
                {(values.password.length && values.confirmationPassword.length) ? (
                    <Button onClick={handleSubmit} type="submit">
                        {isLoading ? <CircularProgress color="inherit" size={26} /> : `${t('reset')}`}
                    </Button>
                ) : (
                    <Button disabled>
                        {t('reset')}
                    </Button>
                )}
            </Wrapper>
        </Container>
    );
};

export default ResetPassword;