import { useDispatch, useSelector } from 'react-redux';

import { setBrandFareRecId } from '../../../../../../../store/resultDataSlice';
import { Container } from '../../../../../ui/common';
import { ResultContainer } from '../../../desktop/flightDetails/components';

import TariffItem from '../tariffItem';
import { ScrollBlock, ScrollContainer } from './components';

const Tariffs = ({ brandRecId, recId, getFareRules, brandFares, brandName, currency }) => {
    const dispatch = useDispatch();
    const { brandRules } = useSelector(state => state.resultData);

    const handleCheckTariffs = value => {
        dispatch(setBrandFareRecId({ recId, brandRecId: value }));
    };

    const handleGetFareRules = recId => {
        getFareRules(recId);
    };

    const tariffsItems = brandFares.map((brandFare, index) => {
        let isChecked = false;

        if (brandRecId) {
            isChecked = brandFare.rec_id === brandRecId;
        } else {
            isChecked = brandFare.brand_name === brandName;
        }
        return (
            <TariffItem
                key={index}
                brandFare={brandFare}
                isChecked={isChecked}
                checkTariffs={handleCheckTariffs}
                handleGetFareRules={handleGetFareRules}
                brandRules={brandRules}
                currency={currency}
            />
        );
    });

    return (
        <ScrollBlock id="Block">
            <ScrollContainer>{tariffsItems}</ScrollContainer>
        </ScrollBlock>
    );
};

export default Tariffs;
