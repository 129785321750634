import { createSlice } from '@reduxjs/toolkit';
import { BY_MIN_PRICE } from '../containers/result/constants';
import { handleFilterParamsChange } from '../lib/filters';

const initialState = {
    recommendations: [],
    brandFares: {},
    brandRules: {},
    included: {},
    filterParams: {
        baggage: {
            withBaggage: true,
            withoutBaggage: true,
        },
        transfers: {
            withoutTransfers: true,
            oneTransfers: true,
            manyTransfers: true,
        },
        allTransfers: true,
        sortType: BY_MIN_PRICE,
        airlines: [],
        airports: false,
        duration: false,
        arrivalTime: false,
        departureTime: false,
        flightNumbers: false,
        routesData: [],
        priceRange: {},
        flightNumbersList: null,
        recId: null,
    },
    session: null,
    isLoad: false,
    searchData: {
        searchParams: null,
        formField: null,
        passengersData: null,
    },
};

export const resultDataSlice = createSlice({
    name: 'resultData',
    initialState,
    reducers: {
        setRecommendations: (state, action) => {
            return {
                ...state,
                recommendations: action.payload.flights,
                included: action.payload.included,
                session: action.payload.session,
                isLoad: action.payload.loadStatus,
            };
        },
        setBrandFares: (state, action) => {
            return { ...state, brandFares: { ...state.brandFares, [action.payload.recId]: action.payload.flights } };
        },
        setBrandRules: (state, action) => {
            return { ...state, brandRules: { ...state.brandRules, [action.payload.recId]: action.payload.data } };
        },
        setBrandFareRecId: (state, action) => {
            return {
                ...state,
                recommendations: state.recommendations.map(recommendation => {
                    if (recommendation.rec_id === action.payload.recId) {
                        return {
                            ...recommendation,
                            brandRecId: action.payload.brandRecId,
                        };
                    }
                    return {
                        ...recommendation,
                    };
                }),
            };
        },
        setFilters: (state, action) => {
            handleFilterParamsChange(action.payload);
            return { ...state, filterParams: { ...state.filterParams, [action.payload.type]: action.payload.status } };
        },
        setAllFilters: (state, action) => {
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    ...action.payload,
                },
            };
        },
        resetAllFilters: (state, action) => {
            return {
                ...state,
                filterParams: {
                    baggage: {
                        withBaggage: true,
                        withoutBaggage: true,
                    },
                    transfers: {
                        withoutTransfers: true,
                        oneTransfers: true,
                        manyTransfers: true,
                    },
                    allTransfers: true,
                    sortType: BY_MIN_PRICE,
                    airlines: [],
                    airports: false,
                    duration: false,
                    arrivalTime: false,
                    departureTime: false,
                    flightNumbers: false,
                    routesData: [],
                    priceRange: {},
                    flightNumbersList: null,
                },
            };
        },
        setAdditionallyOptions: (state, action) => {
            return {
                ...state,
                recommendations: state.recommendations.map(recommendation => {
                    if (recommendation.rec_id === action.payload.recId) {
                        return {
                            ...recommendation,
                            routes: recommendation.routes.map(item => {
                                if (item.index === action.payload.routeIndex) {
                                    return {
                                        ...item,
                                        [action.payload.type]: action.payload.status,
                                    };
                                }
                                return {
                                    ...item,
                                };
                            }),
                        };
                    }
                    return {
                        ...recommendation,
                    };
                }),
            };
        },
        setSearchData: (state, action) => {
            return { ...state, searchData: action.payload };
        },
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const {
    setRecommendations,
    setBrandFares,
    setBrandRules,
    setBrandFareRecId,
    setFilters,
    setAllFilters,
    resetAllFilters,
    setAdditionallyOptions,
    setSearchData,
} = resultDataSlice.actions;

export default resultDataSlice.reducer;
