import i18next from 'i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import RefundOrderComponents from '../components/somon_air/pages/refundOrder';
import envConfigs from '../configs/envConfigs';
import { setDesignSettings } from '../store/designSettingsSlice';
import { setShowLoading } from '../store/loading';

const RefundOrderPage = () => {
    const { t } = i18next;
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = `${t('refund_order')} | ${envConfigs.appTitle}`;

        dispatch(
            setDesignSettings({
                isStickyHeader: true,
                showSearchBlock: true,
                isSmallSearchBlock: true,
            })
        );

        window.scrollTo({ behavior: 'smooth', top: 0 });
        dispatch(setShowLoading(false));
    }, []);

    return <RefundOrderComponents />;
};

export default RefundOrderPage;
