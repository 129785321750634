import styled from 'styled-components';
import {
    WHITE_COLOR,
    ORANGE_COLOR,
    MIDNIGHT_COLOR,
    BRIGHT_COLOR,
    ZAMBEZI_COLOR,
    LIGHT_GREY_COLOR,
    DARKER_GREY_COLOR,
    GAINSBORO_COLOR,
    DARK_CERULEAN_COLOR,
    RHINO_COLOR,
    GREY_GAINSBORO_COLOR,
    RED_COLOR,
} from '../../constants';

export const Wrapper = styled.main`
    background-color: ${WHITE_COLOR};
    padding: 50px;
    margin-bottom: 92px;
    left: 210px;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px ${MIDNIGHT_COLOR};
    border-radius: 5px;
    opacity: 1;

    @media (max-width: 1100px) {
        padding: 30px;
    }

    @media (max-width: 599px) {
        padding: 15px;
    }
`;

export const MainHeader = styled.h1`
    margin: 60px 0;
    text-align: center;
    font-size: 36px;
    font-family: Raleway;
    font-weight: bold;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    opacity: 1;

    @media (max-width: 899px) {
        font-size: 30px;
    }

    @media (max-width: 599px) {
        font-size: 26px;
        margin: 60px 0;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 599px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const CurrentBalance = styled.h3`
    text-align: left;
    font-size: 32px;
    font-family: Rubik;
    letter-spacing: 0px;
    color: ${ZAMBEZI_COLOR};
    opacity: 1;
    margin: 0;

    @media (max-width: 959px) {
        font-size: 24px;
        text-align: left;
    }

    @media (max-width: 599px) {
        text-align: center;
        font-size: 20px;
        margin-bottom: 5px;
    }
`;

export const Balance = styled.div`
    margin-right: 0.5rem;
    text-align: left;
    font-size: 46px;
    font-family: Rubik;
    font-weight: bold;
    color: ${ORANGE_COLOR};
    line-height: 1;
`;

export const Currency = styled.div`
    text-align: right;
    font-family: Rubik;
    font-weight: bold;
    font-size: 33px;
    color: ${ZAMBEZI_COLOR};
    line-height: 1.25;
`;

export const Left = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
    
    @media (max-width: 599px) {
        justify-content: flex-start;
    }
`;

export const Line = styled.div`
    margin-top: 30px;
    left: 258px;
    height: 0px;
    border: 2px solid ${ORANGE_COLOR};
    opacity: 1;

    @media (max-width: 599px) {
        margin-top: 15px;
    }
`;

export const PaymentMethod = styled.h3`
    margin: 50px auto;
    text-align: center;
    font: normal normal bold 32px/38px Rubik;
    font-size: 32px;
    color: ${ZAMBEZI_COLOR};
    opacity: 1;

    @media (max-width: 959px) {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 24px;
    }

    @media (max-width: 599px) {
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 35px;
    }
`;

export const PaymentMethodsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    @media (max-width: 1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 899px) {
        grid-template-columns: 1fr;
    }
`;

export const PaymentMethodsBlock = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px ${GREY_GAINSBORO_COLOR};
    border-radius: 5px;
    gap: 16px;
    cursor: pointer;
    border-bottom: 13px solid ${props => (props.active ? ORANGE_COLOR : GREY_GAINSBORO_COLOR)};

    @media (max-width: 959px) {
        width: 100%;
        height: 180px;
    }
`;

export const AmountBlockAll = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;

    @media (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }
`;

export const AmountBlock = styled.div`
    position: relative;
    background: ${LIGHT_GREY_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px ${MIDNIGHT_COLOR};
    border-radius: 5px;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;

    @media (max-width: 1100px) {
        max-width: 100%;
    }
`;

export const ButtonContainer = styled.div`
    width: 360px;

    @media (max-width: 1100px) {
        width: 100%;
        margin-bottom: 28px;
    }
`;

export const InputLabel = styled.label`
    color: ${ZAMBEZI_COLOR};
    font-size: 20px;
    font-weight: bold;
    
    @media (max-width: 599px) {
        font-size: 16px;
    }
`;

export const Input = styled.input`
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: ${ZAMBEZI_COLOR};
    font-weight: bold;
    width: 100%;
    padding: 0;
    line-height: 1;
    height: 44px;

    @media (max-width: 599px) {
        font-size: 16px;
    }
`;

export const InputCurrency = styled.label`
    text-align: right;
    font-size: 30px;
    font-weight: 600;
    font-family: Raleway;
    letter-spacing: 0px;
    color: ${DARKER_GREY_COLOR};
    opacity: 1;
    @media (max-width: 1100px) {
        margin-right: 0.5rem;
        top: 24px;
    }
    @media (max-width: 959px) {
        margin-right: 0.5rem;
        top: 28px;
        font-size: 1rem;
    }
`;

export const InputBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8px;
    position: relative;
    border-radius: 12px;
    border: 1px solid ${GAINSBORO_COLOR};
    width: 12%;
    margin-right: 4%;
    margin-bottom: 4%;

    &.active {
        border-color: ${DARK_CERULEAN_COLOR};
    }

    &:hover {
        cursor: pointer;
    }

    &:nth-of-type(4n) {
        margin-right: 0;
    }

    @media (max-width: 959px) {
        width: 48%;
        margin-right: 4%;

        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    @media (max-width: 599px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
    }
`;

export const InputError = styled.div`
    width: 100%;
    margin-top: 5px;
    color: ${RED_COLOR};
`;

export const PaymentIMG = styled.img`
    max-height: 80px;
    @media (max-width: 959px) {
        max-height: 100px;
    }
`;

export const PaymentImgBG = styled.img`
    max-height: 120px;
    max-width: 220px;

    @media (max-width: 959px) {
        max-height: 100px;
    }
`;

export const PaymentDiscription = styled.p`
    text-align: center;
    color: ${RHINO_COLOR};
    margin: 0;
`;
