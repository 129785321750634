import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Component, SelectBlock, Value, Icon, DropdownBlock, Item } from './components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Select = ({ value = {}, items = [], onSelect = item => null, textBold = false, textColor = '#000' }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const select = useRef();

    useEffect(() => {
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const handleClick = useCallback(e => {
        if (select.current.contains(e.target)) {
            return;
        }

        setShowDropdown(false);
    }, []);

    const handleSelect = useCallback(item => {
        onSelect(item);
        setShowDropdown(false);
    }, []);

    return (
        <Component ref={select}>
            <SelectBlock onClick={() => setShowDropdown(!showDropdown)}>
                <Value isBold={textBold} color={textColor}>
                    {value.label}
                </Value>

                <Icon className={showDropdown && 'active'}>
                    <ExpandMoreIcon />
                </Icon>
            </SelectBlock>

            <DropdownBlock isActive={showDropdown}>
                {items.map(item => {
                    return (
                        <Item
                            key={`item-${item.value}`}
                            isActive={item.value === value.value}
                            onClick={() => handleSelect(item)}
                        >
                            {item.label}
                        </Item>
                    );
                })}
            </DropdownBlock>
        </Component>
    );
};

export default Select;
