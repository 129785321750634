import styled from "styled-components";

export const InlineBlock = styled.div`
    display: flex;
`;

export const Block = styled.div`
    display: flex;
    align-items: center;
`;

export const RouteTitle = styled.h3`
    margin: 0;
    padding: 0;
`;