import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setDesignSettings } from '../store/designSettingsSlice';

import RefillPage from '../components/somon_air/pages/refill';

const Refill = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setDesignSettings({
                isStickyHeader: true,
                showSearchBlock: true,
                isSmallSearchBlock: true,
            })
        );
    }, []);

    return <RefillPage />;
};

export default withTranslation()(Refill);
