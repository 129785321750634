import { useContext, useState } from 'react';
import i18next from 'i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import envConfigs from '../../../../configs/envConfigs';
import { refundOrder } from '../../../../protocols/rest/services/order';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { GREEN_COLOR, WHITE_COLOR } from '../../constants';

import { Container } from '../../ui/common';
import Input from '../../ui/input';
import Select from '../../ui/select';
import FileInput from '../../ui/fileInput';
import Button from '../../ui/button';
import {
    FormGroup,
    PassengerAddButtonBlock,
    PassengerFormInput,
    PassengerItem,
    PassengerRemoveBlock,
    PassengerRemoveButton,
    PassengersTitle,
    SubmitButtonBlock,
    Title,
    Wrapper,
} from './components';

const RefundOrderComponents = () => {
    const { t } = i18next;
    const { showSnackbar } = useContext(SnackbarContext);

    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const validationSchema = Yup.object().shape({
        order_id: Yup.string().trim().required(t('required')),
        refund_reason: Yup.string().trim().required(t('required')),
        payment_code: Yup.string().trim().required(t('required')),
        payer_email: Yup.string().trim().email(t('enter_valid_mail')).required(t('required')),
        payer_phone: Yup.string().trim().required(t('required')),
        passengers: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().trim().required(t('required')),
                passport_number: Yup.string().trim().required(t('required')),
                passport_copy: Yup.mixed().required(t('required')),
            })
        ),
        refund_payment_code: Yup.string()
            .trim()
            .when('payment_type', payment_type => {
                if (payment_type === 'card') return Yup.string().required(t('required'));
            }),
    });

    const onSubmitFunction = values => {
        const formData = new FormData();
        formData.append('order_id', values.order_id);
        formData.append('refund_reason', values.refund_reason);
        formData.append('payment_type', values.payment_type);
        formData.append('payment_code', values.payment_code);
        formData.append('payer_email', values.payer_email);
        formData.append('payer_phone', values.payer_phone);
        values.passengers.forEach((val, ind) => {
            formData.append(`passengers[${ind}][name]`, val.name);
            formData.append(`passengers[${ind}][passport_number]`, val.passport_number);
            formData.append(`passengers[${ind}][passport_copy]`, val.passport_copy);
        });
        formData.append('refund_payment_code', values.refund_payment_code || values.payment_code);
        formData.append('company_req_id', envConfigs.companyRequisitesId);

        setIsLoading(true);

        refundOrder(formData).then(response => {
            if (response.status > 399) {
                showSnackbar({
                    alertText: t('error_refund_order_request'),
                    alertSeverity: 'error',
                });
                setDisabled(false);
            } else {
                showSnackbar({
                    alertText: t('success_refund_order_request'),
                });
                setDisabled(true);
            }
            setIsLoading(false);
        });
    };

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            order_id: '',
            refund_reason: '',
            payment_type: 'balance',
            payment_code: '', // User ID or 4 last digits of card - depends on payment_type
            payer_email: '',
            payer_phone: '',
            passengers: [
                {
                    name: '',
                    passport_number: '',
                    passport_copy: '',
                },
            ],
            refund_payment_code: '',
        },
        validationSchema,
        onSubmit: onSubmitFunction,
    });

    const handlePhoneChange = value => {
        const trimmedValue = value.replace(/[^0-9\.]+/g, '');

        setFieldValue('payer_phone', trimmedValue);
    };

    const handleRemovePassenger = index => {
        setFieldValue(
            'passengers',
            values.passengers.filter((_, ind) => ind !== index)
        );
    };

    const handleAddPassenger = () => {
        setFieldValue('passengers', [
            ...values.passengers,
            {
                name: '',
                passport_number: '',
                passport_copy: '',
            },
        ]);
    };

    const paymentTypes = [
        { value: 'balance', label: t('payment_from_client_balance') },
        { value: 'card', label: t('payment_from_card') },
    ];

    return (
        <Container>
            <Wrapper>
                <Title>{t('refund_order')}</Title>

                <FormGroup>
                    <Input
                        value={values.order_id}
                        onChange={handleChange('order_id')}
                        placeholder={t('order_num')}
                        label={t('order_num')}
                        error={errors.order_id}
                        onBlur={handleBlur('order_id')}
                        touched={touched.order_id}
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Input
                        value={values.refund_reason}
                        onChange={handleChange('refund_reason')}
                        placeholder={t('refund_reason')}
                        label={t('refund_reason')}
                        error={errors.refund_reason}
                        onBlur={handleBlur('refund_reason')}
                        touched={touched.refund_reason}
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Select
                        value={values.payment_type}
                        items={paymentTypes}
                        onChange={item => setFieldValue('payment_type', item)}
                        label={t('order_payment_method')}
                    />
                </FormGroup>

                <FormGroup>
                    <Input
                        value={values.payment_code}
                        onChange={handleChange('payment_code')}
                        placeholder={
                            values.payment_type === 'balance' ? t('payer_user_id') : t('four_last_digits_of_card')
                        }
                        label={values.payment_type === 'balance' ? t('user_id') : t('four_last_digits_of_card')}
                        error={errors.payment_code}
                        onBlur={handleBlur('payment_code')}
                        touched={touched.payment_code}
                        mask={
                            values.payment_type === 'balance'
                                ? [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                                : [/\d/, /\d/, /\d/, /\d/]
                        }
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Input
                        value={values.payer_email}
                        onChange={handleChange('payer_email')}
                        type="email"
                        placeholder={t('buyer_email')}
                        label={t('buyer_email')}
                        error={errors.payer_email}
                        onBlur={handleBlur('payer_email')}
                        touched={touched.payer_email}
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Input
                        value={values.payer_phone}
                        onChange={handlePhoneChange}
                        type="text"
                        placeholder={t('buyer_phone')}
                        label={t('buyer_phone')}
                        error={errors.payer_phone}
                        onBlur={handleBlur('payer_phone')}
                        touched={touched.payer_phone}
                        mask={envConfigs.phoneMask}
                        required
                    />
                </FormGroup>

                {values.payment_type === 'card' && (
                    <FormGroup>
                        <Input
                            value={values.refund_payment_code}
                            onChange={handleChange('refund_payment_code')}
                            placeholder={t('requisite_of_account_to_return')}
                            label={t('requisite_of_account')}
                            error={errors.refund_payment_code}
                            onBlur={handleBlur('refund_payment_code')}
                            touched={touched.refund_payment_code}
                            required
                        />
                    </FormGroup>
                )}

                <PassengersTitle>{t('passengers')}</PassengersTitle>

                {values.passengers.map((value, index) => {
                    return (
                        <PassengerItem key={index}>
                            {index > 0 && (
                                <PassengerRemoveBlock>
                                    <PassengerRemoveButton onClick={() => handleRemovePassenger(index)}>
                                        {t('remove')}
                                    </PassengerRemoveButton>
                                </PassengerRemoveBlock>
                            )}

                            <PassengerFormInput>
                                <Input
                                    value={value.name}
                                    onChange={handleChange(`passengers[${index}].name`)}
                                    placeholder={t('passenger_name_and_surname')}
                                    label={t('passenger_name_and_surname')}
                                    error={errors?.passengers?.length > 0 && errors.passengers[index]?.name}
                                    onBlur={handleBlur(`passengers[${index}].name`)}
                                    touched={touched?.passengers?.length > 0 && touched.passengers[index]?.name}
                                    required
                                />
                            </PassengerFormInput>

                            <PassengerFormInput>
                                <Input
                                    value={value.passport_number}
                                    onChange={handleChange(`passengers[${index}].passport_number`)}
                                    placeholder={t('passport_serial_and_number')}
                                    label={t('passport_serial_and_number')}
                                    error={errors?.passengers?.length > 0 && errors.passengers[index]?.passport_number}
                                    onBlur={handleBlur(`passengers[${index}].passport_number`)}
                                    touched={
                                        touched?.passengers?.length > 0 && touched.passengers[index]?.passport_number
                                    }
                                    required
                                />
                            </PassengerFormInput>

                            <PassengerFormInput>
                                <FileInput
                                    onChange={e =>
                                        setFieldValue(`passengers[${index}].passport_copy`, e.currentTarget.files[0])
                                    }
                                    label={t('passport_copy')}
                                    error={errors?.passengers?.length > 0 && errors.passengers[index]?.passport_copy}
                                    onBlur={handleBlur(`passengers[${index}].passport_copy`)}
                                    touched={
                                        touched?.passengers?.length > 0 && touched.passengers[index]?.passport_copy
                                    }
                                    type="file"
                                    required
                                />
                            </PassengerFormInput>

                            {index === values.passengers.length - 1 && index < 8 && (
                                <PassengerAddButtonBlock>
                                    <div>
                                        <Button
                                            onClick={handleAddPassenger}
                                            title={`+ ${t('add')}`}
                                            height={44}
                                            padding="0 10px"
                                            textTransform="none"
                                        />
                                    </div>
                                </PassengerAddButtonBlock>
                            )}
                        </PassengerItem>
                    );
                })}

                <SubmitButtonBlock>
                    <Button
                        type="button"
                        onClick={handleSubmit}
                        title={t('send')}
                        loading={isLoading}
                        backgroundColor={GREEN_COLOR}
                        borderColor={GREEN_COLOR}
                        color={WHITE_COLOR}
                        loadingColor={WHITE_COLOR}
                        disabled={disabled}
                    />
                </SubmitButtonBlock>
            </Wrapper>
        </Container>
    );
};

export default RefundOrderComponents;
