import { useSelector } from 'react-redux';
import { Wrapper } from './components';
import RouteItem from './routeItem';

const RoutesData = () => {
    const { routes } = useSelector(state => state.prebook);

    return (
        <Wrapper>
            {routes.map((route, index) => (
                <RouteItem route={route} key={index} />
            ))}
        </Wrapper>
    );
};

export default RoutesData;
