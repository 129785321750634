import styled from 'styled-components';
import { DARK_GREY_COLOR } from '../../../../constants';

export const TariffsWrapper = styled.div`
    padding: 16px 0;
    width: 100%;
`;

export const TariffsText = styled.div`
    color: ${DARK_GREY_COLOR};
    font-size: 16px;
    padding: 8px 0;
    display: flex;
    align-items: center;
`;

export const TariffsBlock = styled.div`
    padding: 16px 0 0;
    display: flex;
    flex-direction: column;
`;

export const LoadingWrapper = styled.div`
    display: flex;
    min-height: 470px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
`;
