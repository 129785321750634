import i18next from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { checkInAccessToPaymentStatus, TICKETING_DONE_CODE } from '../../../utils';
import { getBookStatus, getColorStatus, getCreatedTime } from '../../result/common';
import RoutesData from './routesData';
import { Container } from '../ui/common';
import {
    InfoBlock,
    Order,
    OrderID,
    OrderTitleBlock,
    PriceBlock,
    StatusBlock,
    Wrapper,
    Title,
    StatusText,
    StatusDateBlock,
} from './components';
import PaymentForm from './paymentForm';
import Price from './price';
import OrderInfo from './orderInfo';

const OrderForm = props => {
    const { t } = i18next;
    const [voidStatus, setVoidStatus] = useState(false);

    const { orderData, paymentMethod, availablePaymentMethods } = useSelector(state => state.order);
    const { sendPayment, orderCancel, refundOrder, partnerCode, isChangedStatus, setIsChangedStatus } = props;
    const isAccessToPayment = checkInAccessToPaymentStatus(orderData?.booking_status, orderData?.order_status);

    return (
        <Container>
            <Wrapper>
                <InfoBlock>
                    <OrderNumber orderId={orderData?.order_id} />

                    <BookStatus
                        timestamp={orderData?.timestamp}
                        createdAt={orderData?.status_at}
                        bookStatus={orderData?.booking_status}
                        orderStatus={orderData?.order_status}
                    />

                    {isAccessToPayment && !voidStatus && (
                        <PaymentForm
                            timelimit={orderData?.timelimit}
                            paymentMethod={paymentMethod}
                            availablePaymentMethods={availablePaymentMethods}
                            sendPaymentRequest={sendPayment}
                        />
                    )}

                    <OrderInfo />

                    <Title>{t('flight_overview')}</Title>
                    <RoutesData />
                </InfoBlock>

                <PriceBlock>
                    <Price />
                </PriceBlock>
            </Wrapper>
        </Container>
    );
};

export const OrderNumber = props => {
    const { t } = i18next;
    const { orderId } = props;

    return (
        <div>
            <OrderTitleBlock>
                <Order>{t('order')} - </Order>
                <OrderID>{orderId}</OrderID>
            </OrderTitleBlock>
        </div>
    );
};

export const BookStatus = props => {
    const { t, language } = i18next;
    const { createdAt, timestamp, bookStatus, orderStatus } = props;
    const isTicketingDoneStatus = bookStatus === TICKETING_DONE_CODE;

    const statusColor = getColorStatus(bookStatus);

    return (
        <StatusBlock>
            <StatusText style={{ color: statusColor }}>
                {getBookStatus(bookStatus, orderStatus, t)}
                {isTicketingDoneStatus && getCreatedTime(createdAt, language)}
            </StatusText>

            <StatusDateBlock>
                {t('order_created')} {getCreatedTime(timestamp, language)}
            </StatusDateBlock>
        </StatusBlock>
    );
};

export default OrderForm;
