import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoad: false,
    ordersData: [],
    totalOrdersCount: 0,
    filters: {},
    childPartners: [],
};

export const ordersSlice = createSlice({
    name: 'ordersSlice',
    initialState,
    reducers: {
        setOrdersData: (state, action) => {
            return { ...state, ...action.payload };
        },

        setOrdersFilters: (state, action) => {
            return { ...state, ...action.payload };
        },

        setChildPartners: (state, action) => {
            return { ...state, ...action.payload };
        },

        setShowActionsForOrder: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setOrdersData, setOrdersFilters, setChildPartners, setShowActionsForOrder } =
    ordersSlice.actions;

export default ordersSlice.reducer;
