import styled from 'styled-components';
import { BRIGHT_COLOR, RALEWAY_FONT, RUBIK_FONT } from '../constants';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 40px;
    padding: 60px 0;

    @media (max-width: 1279px) {
        gap: calc(20px + (40 - 20) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (max-width: 899px) {
        flex-direction: column;
        gap: 20px;
        padding: 30px 0;
    }
`;

export const InfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 1030px;
    order: 1;

    @media (min-width: 1280px) and (max-width: 1529px) {
        width: calc(880px + (1030 - 880) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(600px + (880 - 600) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (max-width: 899px) {
        width: 100%;
        order: 2;
    }
`;

export const PriceBlock = styled.div`
    width: 430px;
    order: 2;

    @media (max-width: 1499px) {
        width: calc(330px + (430 - 330) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (max-width: 1279px) {
        width: calc(250px + (330 - 250) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (max-width: 899px) {
        width: 100%;
        order: 1;
    }
`;

export const OrderTitleBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px;

    @media (max-width: 599px) {
        margin-bottom: 20px;
    }
`;

export const Order = styled.div`
    font: normal normal bold 35px/41px ${RALEWAY_FONT};
    color: ${BRIGHT_COLOR};

    @media (max-width: 599px) {
        font-size: 22px;
    }
`;

export const OrderID = styled.div`
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 57px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000033;
    border-radius: 5px;
    text-align: center;
    font: normal normal bold 35px/41px ${RALEWAY_FONT};
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};

    @media (max-width: 599px) {
        font-size: 22px;
        padding: 2px 15px;
        width: auto;
        height: auto;
    }
`;

export const StatusBlock = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;

    @media (max-width: 599px) {
        gap: 10px;
    }
`;

export const StatusText = styled.div`
    color: ${props => props.color};
    font-size: 25px;
    font-weight: 500;
    font-family: ${RUBIK_FONT};
    margin-bottom: 5px;

    @media (max-width: 599px) {
        font-size: 18px;
        margin-bottom: 0;
        text-align: center;
    }
`;

export const StatusDateBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    background: #ebebeb 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000033;
    border-radius: 5px;
    font: normal normal normal 19px/23px Rubik;
    letter-spacing: 0px;
    color: #8f8f8f;

    @media (max-width: 599px) {
        font-size: 17px;
    }
`;

export const Title = styled.h3`
    margin: 0;
    margin: 70px;
    font-size: 35px;
    font-weight: bold;
    color: ${BRIGHT_COLOR};
    text-align: center;

    @media (max-width: 899px) {
        font-size: 25px;
    }

    @media (max-width: 599px) {
        font-size: 24px;
        margin: 30px 0;
    }
`;
