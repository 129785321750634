import { createSlice } from '@reduxjs/toolkit';
import { mainConfigs } from '../configs';

const initialState = {
    currency: mainConfigs.defaultWebCurrency,
    availableCurrencies: ['RUB', 'TJS', 'USD', 'UZS'],
};

export const currencySettingsSlice = createSlice({
    name: 'currencySettings',
    initialState,
    reducers: {
        setCurrencySettings: (state, action) => ({ ...state, ...action.payload }),
    },
});

export const { setCurrencySettings } = currencySettingsSlice.actions;

export default currencySettingsSlice.reducer;
