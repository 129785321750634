import styled from 'styled-components';
import { BRIGHT_COLOR } from '../../../constants';
export const Wrapper = styled.div`
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    font-family: Raleway;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    @media (max-width: 899px) {
        font-size: 21px;
    }
`;
