import styled from 'styled-components';
import {
    BRIGHT_COLOR,
    WHITE_COLOR,
    NOBEL_COLOR,
    ORANGE_COLOR,
    PANTONE_COLOR,
    PINK_SWAN_COLOR,
    GREEN_COLOR,
    GREY_DARK_COLOR,
    MIDNIGHT_COLOR,
    LIGHT_GREY_COLOR,
    ZAMBEZI_COLOR,
    RED_SANGRIA_COLOR,
    SPECIAL_GREEN_COLOR,
    FUN_GREEN_COLOR,
    REGAL_BLUE_COLOR,
    MAIN_BLUE_COLOR,
} from '../../../../constants';
import iconsClose from '../../../../../../images/krest.png';

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 100px auto;
    @media (max-width: 1279px) {
        margin: 38px auto;
    }
`;
export const Divider = styled.div`
    height: 0px;
    border: 1px solid ${ORANGE_COLOR};
    z-index: 100;
`;
export const Title = styled.div`
    font-size: 35px;
    font-weight: bold;
    font-family: Raleway;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};

    @media (max-width: 899px) {
        font-size: 21px;
    }
`;
export const TitleIcon = styled.img`
    background-color: transparent;
    margin-right: 25px;
    width: 43px;
    height: 43px;
    @media (max-width: 899px) {
        margin-right: 15px;
        width: 27px;
        height: 27px;
    }
`;
export const BBlock = styled.div`
    background: ${props => props.themeColor};
    width: 43px;
    height: 43px;
`;

export const ResultContainer = styled.div`
    max-width: 1029px;
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 899px) {
        display: none;
    }
`;
export const InfoCard = styled.div`
    background-color: red;
    max-width: 759px;
    height: 327px;
    padding: 21px;
    padding-right: 10px;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    display: flex;
    @media screen and (max-width: 1279px) {
        max-width: 929px;
    }
`;
export const RouteCard = styled.div`
    height: 100%;

    @media screen and (max-width: 1279px) {
        max-width: 929px;
    }
`;
export const DetailsCard = styled.div`
    width: 270px;
    padding: ${props => (props.roundTrip ? '55px 0' : '0 0 21px')};
    display: flex;
    justify-content: ${props => (props.roundTrip ? 'space-between' : 'center')};
    flex-direction: column;
    border-left: 1px dashed ${NOBEL_COLOR};
    /* margin: auto; */
    @media screen and (max-width: 1279px) {
        /* max-width: 929px; */
    }
`;

export const AirlineLogoBlock = styled.div`
    width: 204px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1350px) {
        width: 180px;
    }
`;

export const RouteTitle = styled.div`
    color: ${props => props.themeColor};
    font-size: 18px;
    font-weight: 500;
`;
export const AirlineLogo = styled.div`
    max-width: 141px;
    height: 48px;
    margin: auto 18px;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    @media screen and (max-width: 1279px) {
        width: 141px;
        height: 48px;
        margin: auto;
    }
`;
export const FlyTo = styled.div`
    display: flex;
    align-items: center;
    height: 20px;
    margin-left: 0;
    font: normal normal 600 17px/20px Rubik;
    letter-spacing: 0px;
`;
export const FlyToIcon = styled.img`
    width: 45px;
    height: 25px;
    margin-right: 9px;
    border-radius: 2px;
    background-color: ${props => props.themeColor};
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
`;
export const AirlineInfo = styled.div`
    width: 210px;
    height: 30px;
    text-align: left;
    font: italic normal 300 13px/15px Rubik;
    letter-spacing: 0px;
    color: ${GREY_DARK_COLOR};
    @media screen and (max-width: 1279px) {
        width: 141px;
        height: 50px;
        font-size: 11px;
    }
`;

export const FlightInfo = styled.div`
    max-width: 484px;
    height: 204px;
    border: 2px solid ${ORANGE_COLOR};
    border-radius: 30px;
    display: flex;
    align-items: center;
    margin-top: 36px;
    @media screen and (max-width: 1279px) {
        width: 400px;
    }
`;
export const FlightBlock = styled.div`
    /* padding: 20px; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
`;
export const InlineBlock = styled.div`
    display: inline-block;
`;
export const HideRouteTariffContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 17px;
    margin-top: 15px;
    text-align: center;
    font: normal normal normal 14px/17px Rubik;
    letter-spacing: 0px;
`;
export const StyledHideTariffsButton = styled.div`
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
    background-image: url(${iconsClose});
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center left;
    position: relative;
`;
export const ShortTariffInfo = styled.div`
    text-align: right;
    position: relative;
`;
export const TariffChangeBlock = styled.div`
    margin: 0 0 16px;
    font-size: 14px;
    font-weight: 500;
    color: ${RED_SANGRIA_COLOR};
    text-align: right;
    cursor: pointer;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;
export const ChangeTariffContainer = styled(ShortTariffInfo)`
    cursor: pointer;
    width: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
export const TariffCloseBlock = styled(TariffChangeBlock)`
    font-size: 14px;
`;
export const TransferBlock = styled.div`
    display: flex;
    justify-content: space-evenly;
    max-width: 50px;
`;
export const InfoBlock = styled.div`
    width: 143px;
    margin-top: 43px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1279px) {
        width: 103px;
    }
`;
export const DurationBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: normal normal normal 15px/18px Rubik;
    letter-spacing: 0px;
    color: ${ZAMBEZI_COLOR};
    width: 150px;
    margin: auto 5px;
    @media screen and (max-width: 1279px) {
        font-size: 14px;
    }
`;
export const RouteInfo = styled.div`
    text-align: center;
    font-size: 16px;
    font-family: Rubik;
    letter-spacing: 0px;
    color: ${ZAMBEZI_COLOR};
    /* center the arrival info */
    /* height: 19px; */
    @media screen and (max-width: 1279px) {
        font-size: 14px;
    }
`;

export const TimeRouteInfo = styled.div`
    text-align: center;
    font-weight: medium;
    font-size: 34px;
    font-family: Rubik;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    /* height: 41px; */
    @media screen and (max-width: 1279px) {
        font-size: 28px;
    }
`;

export const CityCode = styled.div`
    color: ${ZAMBEZI_COLOR};
    font: normal normal bold 15px/18px Rubik;
    text-align: center;
    margin-top: 5px;
    @media screen and (max-width: 1279px) {
        font-size: 11px;
    }
`;
export const DetailsBtn = styled.div`
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 17px;
    margin-right: -17px;
    height: 325px;
    margin-top: -21px;
    @media screen and (max-width: 1279px) {
        margin-left: 30px;
    }
`;

export const OrderInfo = styled.div`
    max-width: 270px;
    height: 204px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const TransfersArrow = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 20px;
    position: relative;
    & > .circle {
        border: 2px solid;
        background-color: ${ZAMBEZI_COLOR};
        border-radius: 50%;
        height: 5px;
        width: 5px;
        top: 10px;
        position: absolute;
    }

    & > .line {
        top: 11.4px;
        position: absolute;
        width: 100%;
        height: 0px;
        border: 1px solid ${ZAMBEZI_COLOR};
    }
    & > .circle-center {
        border: 2px solid;
        background-color: ${ZAMBEZI_COLOR};
        border-radius: 50%;
        height: 5px;
        width: 5px;
        top: 10px;
        position: absolute;
    }
    & > .circle-bottom {
        border: 2px solid;
        background-color: ${ZAMBEZI_COLOR};
        border-radius: 50%;
        height: 5px;
        width: 5px;
        right: 0px;
        top: 10px;
        position: absolute;
    }
`;
export const CircleBlock = styled.div`
    width: 50%;
    height: 10px;
    background-color: #fff;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const Circle = styled.div`
    border: 2px solid;
    background-color: ${ZAMBEZI_COLOR};
    border-radius: 50%;
    height: 5px;
    width: 5px;
    top: 10px;
    position: absolute;
`;
export const Circle2 = styled.div`
    border: 2px solid;
    background-color: ${ZAMBEZI_COLOR};
    border-radius: 50%;
    height: 5px;
    width: 5px;
    top: 10px;
    margin-left: 50%;
    position: absolute;
`;
export const Circle3 = styled.div`
    border: 2px solid;
    background-color: ${ZAMBEZI_COLOR};
    border-radius: 50%;
    height: 5px;
    width: 5px;
    top: 10px;
    margin-right: 50%;
    position: absolute;
`;
export const DetailsBlock = styled.div`
    min-height: 64px;
`;
export const ClassInfo = styled.div`
    height: 23px;
    color: ${BRIGHT_COLOR};
    font: normal normal normal 19px/23px Rubik;
    text-align: center;
    @media screen and (max-width: 1279px) {
        font-size: 16px;
    }
`;
export const AdditionalInfoIcons = styled.div`
    display: inline-block;
    margin-top: 14px;
    display: flex;
    justify-content: center;
`;
export const LowcostCharterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
export const Lowcost = styled.div`
    display: inline-table;
    color: ${WHITE_COLOR};
    width: 100px;
    border-radius: 4px;
    background-color: ${SPECIAL_GREEN_COLOR};
    text-align: center;
`;
export const Charter = styled.div`
    color: ${WHITE_COLOR};
    width: 100px;
    border-radius: 4px;
    background-color: ${SPECIAL_GREEN_COLOR};
    text-align: center;
    margin-right: 5px;
`;
export const IconContainer = styled.div`
    pointer-events: auto;
    position: relative;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-bottom: 19px;
    background: ${LIGHT_GREY_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px ${PANTONE_COLOR};
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Icon = styled.div`
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    width: 9px;
    height: 10px;
`;
export const AdditionalInfos = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 14px/17px Rubik;
    color: ${WHITE_COLOR};
    @media screen and (max-width: 1279px) {
        font-size: 11px;
    }
`;
export const PlaceAmount = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    max-width: 80px;
    height: 24px;
    background: ${MAIN_BLUE_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px ${PANTONE_COLOR};
    border-radius: 5px;
    @media screen and (max-width: 1279px) {
        width: 60px;
    }
`;
export const LuggageAmount = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    max-width: 115px;
    height: 24px;
    background: ${MAIN_BLUE_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px ${PANTONE_COLOR};
    border-radius: 5px;
    margin-left: 6px;
    @media screen and (max-width: 1279px) {
        width: 100px;
    }
`;
export const MoreDetails = styled.div`
    height: 17px;
    margin-top: 15px;
    text-align: center;
    font: normal normal normal 14px/17px Rubik;
    letter-spacing: 0px;
    color: ${ORANGE_COLOR};
    cursor: pointer;
`;
export const AmountContainer = styled.div`
    height: ${props => (props.roundTrip ? '124px' : 'auto')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const AmountBlock = styled.div`
    min-width: 196px;
    height: 46px;
    margin-top: -15px;
    text-align: center;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1279px) {
        width: 160px;
    }
`;
export const Balance = styled.div`
    margin-right: 5px;
    margin-top: 5px;
    font-family: Rubik;
    font-size: 220%;
    font-weight: 500;
    letter-spacing: 0px;
    color: ${ORANGE_COLOR};
    @media screen and (max-width: 1279px) {
        margin-top: 15px;
        font-size: 200%;
    }
`;
export const Result = styled.div`
    max-width: 1029;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px ${MIDNIGHT_COLOR};
    display: flex;
`;
export const Currency = styled.div`
    margin-top: 18px;
    height: 46px;
    font-family: Rubik;
    font-weight: 500;
    font-size: 21px;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    @media screen and (max-width: 1279px) {
        margin-top: 28px;
        font-size: 18px;
    }
`;
export const ChooseButton = styled.button`
    margin-top: 11px;
    width: 196px;
    height: 52px;
    background: ${GREEN_COLOR} 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    font-family: Rubik;
    font-weight: medium;
    font-size: 20px;
    letter-spacing: 0px;
    color: ${WHITE_COLOR};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: ${FUN_GREEN_COLOR};
    }

    @media screen and (max-width: 1279px) {
        width: 180px;
    }
`;
export const Details = styled.div``;
export const RouteAdditionallyInfo = styled.div`
    border-top: none;
`;
