import { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
    AmountBlock,
    Balance,
    Card,
    CityCode,
    Currency,
    Duration,
    DurationInfo,
    FlightInfo,
    Header,
    Info,
    Logo,
    OrderInfo,
    RouteInfo,
    Time,
    TimeBlock,
    Transfer,
    TransferCity,
    Wrapper,
} from './components';
import i18next from 'i18next';
import MobileItemModal from '../modal';
import { normalizePrice } from '../../../../../../lib/normalize.js';
import { getSupplierIcon } from '../../../../../../lib/getImage';

function MobileRecommendation({ recommendation, index, getBrandFares, getFareRules, onBookingClick, specialText }) {
    const { validating_supplier, routes, total_price, brandRecId, lowcost } = recommendation;
    const { currency } = useSelector(state => state.currencySettings);
    const { t } = i18next;
    const [showModal, setShowModal] = useState(false);
    const { brandFares } = useSelector(state => state.resultData);

    let actualTotalPrice = total_price;

    if (brandRecId) {
        const { rec_id } = recommendation;
        const actualBrandFare = _.find(brandFares[rec_id], { rec_id: brandRecId });

        actualTotalPrice = actualBrandFare.total_price;
    }
    const renderTransfers = segments => {
        const transfersCount = segments.length - 1;

        if (transfersCount === 0) {
            return t('no_transfers');
        }

        return segments
            .slice(0, -1)
            .map(segment => segment.arrival.airport)
            .join(', ');
    };

    const renderTransfersCountLabel = segments => {
        const transfersCount = segments.length - 1;

        if (transfersCount === 0) {
            return '';
        }

        if (transfersCount === 1) {
            return t('transfer_1');
        }

        if (transfersCount > 1 && transfersCount < 5) {
            return t('transfer_2_4');
        }

        return t('transfer_5');
    };
    const renderDurationTime = duration => {
        const hours = Math.trunc(duration / 3600);
        const minutes = Math.floor((duration - hours * 3600) / 60);

        return (
            <>
                {hours} {t('hour_short')} {minutes} {t('minutes_short_3')}
            </>
        );
    };

    return (
        <Wrapper>
            <Card onClick={() => setShowModal(true)}>
                <FlightInfo>
                    <Header>
                        <AmountBlock>
                            <Balance>{normalizePrice(actualTotalPrice[currency])}</Balance>
                            <Currency>{currency}</Currency>
                        </AmountBlock>
                        <Logo src={getSupplierIcon(validating_supplier)} alt={'supplier-icon'} />
                    </Header>

                    {routes.map((value, ind) => {
                        const { duration, segments } = value;
                        const first = _.first(segments).departure;
                        const last = _.last(segments).arrival;
                        return (
                            <div key={`mobile-item-${index}-route-${ind}`}>
                                <TimeBlock>
                                    <RouteInfo>
                                        <CityCode>
                                            {first.airport} - {last.airport}
                                        </CityCode>
                                        <Time>
                                            {first.time.split(' ')[1]} - {last.time.split(' ')[1]}
                                        </Time>
                                    </RouteInfo>
                                    <DurationInfo>
                                        <Info>{t('on_way')}</Info>
                                        <Duration>
                                            {renderDurationTime(duration, t('hour_short'), t('minutes_short_3'))}
                                        </Duration>
                                    </DurationInfo>
                                    <OrderInfo transfer={segments.length > 1 ? 1 : 0}>
                                        <Transfer>
                                            <Info>{`${
                                                segments.length > 1 ? segments.length - 1 : ''
                                            } ${renderTransfersCountLabel(segments)}`}</Info>
                                            <TransferCity>{renderTransfers(segments)}</TransferCity>
                                        </Transfer>
                                    </OrderInfo>
                                </TimeBlock>
                            </div>
                        );
                    })}
                </FlightInfo>
            </Card>
            {showModal && (
                <MobileItemModal
                    recommendation={recommendation}
                    getBrandFares={getBrandFares}
                    getFareRules={getFareRules}
                    onBookingClick={onBookingClick}
                    active={showModal}
                    onClose={() => setShowModal(false)}
                />
            )}
        </Wrapper>
    );
}

export default MobileRecommendation;
