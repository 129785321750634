import styled from 'styled-components';
import { RUBIK_FONT, ZAMBEZI_COLOR } from '../../../constants';

export const PassengerBlock = styled.div`
    margin-top: 5px;
`;

export const PassengerItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const PassengerAmountTitle = styled.div`
    font-family: ${RUBIK_FONT} !important;
    font-size: 18px;
    color: ${ZAMBEZI_COLOR};

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: 14px;
    }

    @media (min-width: 599px) and (max-width: 899px) {
        font-size: 18px;
    }

    @media (max-width: 599px) {
        font-size: 14px;
    }
`;

export const PassengerAmountValue = styled.div`
    font-family: ${RUBIK_FONT} !important;
    font-size: 18px;
    color: ${ZAMBEZI_COLOR};

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: 14px;
    }

    @media (min-width: 599px) and (max-width: 899px) {
        font-size: 18px;
    }

    @media (max-width: 599px) {
        font-size: 14px;
    }

    & > span {
        font-size: 11px;
        font-weight: 700;

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: 9px;
        }

        @media (min-width: 599px) and (max-width: 899px) {
            font-size: 11px;
        }

        @media (max-width: 599px) {
            font-size: 9px;
        }
    }
`;
