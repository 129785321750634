import React from 'react';
import { useNavigate } from 'react-router-dom';
import Order from '../order';
import { DateBlock } from './components';

const OrderContainer = props => {
    const { item } = props;

    const { orders, sortDate } = item;
    const history = useNavigate();

    const orderRedirect = orderId => {
        history(`/order/${orderId}`);
    };

    return (
        <>
            <DateBlock>{sortDate}</DateBlock>
            <div>
                {orders.map((item, index) => (
                    <Order order={item} key={index} orderRedirect={orderRedirect} />
                ))}
            </div>
        </>
    );
};

export default OrderContainer;
