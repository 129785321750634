import styled from 'styled-components';

export const ButtonBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
`;

export const Paragraph = styled.p`
    margin: 4px 0 8px;
    font-size: 14px;
`;
