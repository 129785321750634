import { useMemo } from 'react';
import { ReactComponent as ReturnIcon } from '../../../../../assets/somon_air/icons/resultPage/flightInfoIcons/reload.svg';
import { ReactComponent as ExchangeIcon } from '../../../../../assets/somon_air/icons/resultPage/flightInfoIcons/refresh.svg';
import { ReactComponent as BaggageIcon } from '../../../../../assets/somon_air/icons/resultPage/flightInfoIcons/travel-luggage.svg';
import { ReactComponent as HandLuggageIcon } from '../../../../../assets/somon_air/icons/resultPage/flightInfoIcons/suitcase.svg';
import { Wrapper, Icon } from './components';
import i18next from 'i18next';
import TooltipUI from '../../../../ui/TooltipUI';
import { ORANGE_COLOR, GREY81_COLOR } from '../../../constants';

const FlightInfoIcons = ({ data, marginBottom = 19 }) => {
    const { t } = i18next;
    const { is_refund, is_change, baggage, hand_luggage } = data;
    const getColor = status => {
        return status ? `${ORANGE_COLOR}` : `${GREY81_COLOR}`;
    };
    const returnIcon = useMemo(() => {
        const title = is_refund ? t('yes_refund') : t('no_refund');
        return (
            <TooltipUI title={title}>
                <Icon style={{ color: getColor(is_refund) }} marginBottom={marginBottom}>
                    <ReturnIcon />
                </Icon>
            </TooltipUI>
        );
    }, [is_refund, getColor]);

    const exchangeIcon = useMemo(() => {
        const title = is_change ? t('yes_change') : t('no_change');

        return (
            <TooltipUI title={title}>
                <Icon style={{ color: getColor(is_change) }} marginBottom={marginBottom}>
                    <ExchangeIcon />
                </Icon>
            </TooltipUI>
        );
    }, [is_change, getColor]);

    const baggageIcon = useMemo(() => {
        const title = baggage ? t('yes_baggage') : t('no_baggage');

        return (
            <TooltipUI title={title}>
                <Icon style={{ color: getColor(baggage) }} marginBottom={marginBottom}>
                    <BaggageIcon />
                </Icon>
            </TooltipUI>
        );
    }, [baggage, getColor]);

    const handLuggageIcon = useMemo(() => {
        const title = hand_luggage ? t('yes_hand_luggage') : t('no_hand_luggage');

        return (
            <TooltipUI title={title}>
                <Icon style={{ color: getColor(hand_luggage) }} marginBottom={marginBottom}>
                    <HandLuggageIcon />
                </Icon>
            </TooltipUI>
        );
    }, [hand_luggage, getColor]);

    return (
        <Wrapper>
            {returnIcon}
            {exchangeIcon}
            {baggageIcon}
            {handLuggageIcon}
        </Wrapper>
    );
};

export default FlightInfoIcons;
