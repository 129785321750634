import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';

import { setShowLoading } from '../store/loading';
import { getOrder, getPaymentSystems, ticketing } from '../protocols';
import { checkInAccessToPaymentStatus } from '../utils';
import { setAvailablePaymentMethods, setOrderData, setPaymentMethod } from '../store/orderSlice';
import envConfigs from '../configs/envConfigs';
import { B2C, EPA, PDC } from '../containers/order/constants';
import OrderForm from '../components/somon_air/order';
import { setDesignSettings } from '../store/designSettingsSlice';
import MainLoading from '../components/somon_air/mainLoading';
import { withTranslation } from 'react-i18next';
import DcPaymentPopup from '../components/somon_air/dcPaymentPopup';
import VisaPaymentPopup from '../components/somon_air/visaPaymentPopup';
import { getUserInfo } from '../protocols/rest/services/user';
import { setAuthSettings } from '../store/authSettingsSlice';

const OrderPage = () => {
    const { t, language } = i18next;
    const { isAuth } = useSelector(state => state.authSettings);
    const { showLoading } = useSelector(state => state.loading);
    const { isLoad, isLoadPaymentMethod, paymentMethod, availablePaymentMethods, orderData } = useSelector(
        state => state.order
    );
    const { orderId } = useParams();
    const dispatch = useDispatch();

    const [responseError, setResponseError] = useState(null);
    const [redirectToPayment, setRedirectToPayment] = useState(false);
    const [isChangedStatus, setIsChangedStatus] = useState(false);
    const [dcPaymentModal, setDcPaymentModal] = useState(null);
    const [visaPaymentModal, setVisaPaymentModal] = useState(null);

    useEffect(() => {
        dispatch(setShowLoading(true));

        getOrderData();

        dispatch(
            setDesignSettings({
                showSearchBlock: false,
                isStickyHeader: true,
            })
        );

        return () => {
            setOrderData({
                orderData: [],
                loadStatus: false,
            });

            setAvailablePaymentMethods({
                methods: [],
                loadStatus: false,
            });

            setPaymentMethod({
                method: null,
            });
        };
    }, []);

    useEffect(() => {
        if (isLoad && isLoadPaymentMethod && !redirectToPayment) {
            dispatch(setShowLoading(false));
        } else {
            dispatch(setShowLoading(true));
        }
    }, [isLoad, isLoadPaymentMethod, redirectToPayment]);

    useEffect(() => {
        document.title = `${t('page_order')} | ${envConfigs.appTitle}`;
    }, [language]);

    const refreshUserData = () => {
        getUserInfo().then(response => {
            dispatch(
                setAuthSettings({
                    balance: response.data.balance,
                })
            );
        });
    };

    const sendPayment = () => {
        const methodData = _.find(availablePaymentMethods, { code: paymentMethod });

        const { currency, order_id, session_id, total_price } = orderData;

        setRedirectToPayment(true);

        const params = {
            order_id,
            session_id,
            total_price: {
                [currency]: total_price,
            },
            payment_system_id: methodData.id,
            return_url: window.location.href,
            fail_url: window.location.href,
        };

        ticketing(params)
            .then(response => {
                if (paymentMethod === B2C) {
                    getOrderData();
                    refreshUserData();
                    setRedirectToPayment(false);
                } else if (paymentMethod === PDC) {
                    const { payment_link, payform } = response;

                    setDcPaymentModal({
                        showPopup: true,
                        handleClose: () => setDcPaymentModal(null),
                        payment_link,
                        payform,
                    });

                    setRedirectToPayment(false);
                } else if (paymentMethod === EPA) {
                    setVisaPaymentModal({
                        showPopup: true,
                        handleClose: () => setVisaPaymentModal(null),
                        data: response.payment_link,
                    });

                    setRedirectToPayment(false);
                } else {
                    window.location.replace(response['payment_link']);
                }
            })
            .catch(error => {
                setResponseError(error.response.data);
            });
    };

    const getOrderData = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        getOrder({
            order_id: orderId,
            key: urlParams.get('key'),
        })
            .then(response => {
                const { booking_status, order_status } = response;
                const isAccessToPayment = checkInAccessToPaymentStatus(booking_status, order_status);

                if (isAccessToPayment) {
                    getPaymentSystems().then(response => {
                        dispatch(
                            setAvailablePaymentMethods({
                                methods: response,
                                loadStatus: true,
                            })
                        );

                        const actualMethod = _.first(response);

                        dispatch(setPaymentMethod({ method: actualMethod ? actualMethod.code : null }));
                    });
                } else {
                    dispatch(
                        setAvailablePaymentMethods({
                            loadStatus: true,
                        })
                    );
                }

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

                dispatch(
                    setOrderData({
                        orderData: response,
                        loadStatus: true,
                    })
                );
            })
            .catch(error => {
                setResponseError({
                    error_code: error.response.data.code,
                    error_desc: error.response.data.message,
                    custom_error: t('do_not_find_order_message'),
                });
            });
    };

    if (showLoading) return <MainLoading />;

    return (
        <>
            <OrderForm
                availablePaymentMethods={availablePaymentMethods}
                sendPayment={sendPayment}
                partnerCode={orderData?.partner_code}
                setIsChangedStatus={setIsChangedStatus}
                isChangedStatus={isChangedStatus}
            />

            <DcPaymentPopup
                showPopup={dcPaymentModal?.showPopup}
                handleClose={dcPaymentModal?.handleClose}
                payUrl={dcPaymentModal?.payment_link}
                payform={dcPaymentModal?.payform}
            />

            <VisaPaymentPopup
                showPopup={visaPaymentModal?.showPopup}
                handleClose={visaPaymentModal?.handleClose}
                data={visaPaymentModal?.data}
            />
        </>
    );
};

export default withTranslation()(OrderPage);
