import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import loader from '../../../../../images/loader.gif';
import { GREY_COLOR, ORANGE_COLOR } from '../../../constants';

export const StyledCircularProgress = styled(CircularProgress)`
    position: relative;

    &.MuiCircularProgress-colorPrimary {
        color: ${ORANGE_COLOR};
    }
`;

export const LoadingContainer = styled.div`
    border-top: 1px solid ${GREY_COLOR};
`;

export const LoaderBlock = styled.div`
    padding: 16px 0 16px 0;
    width: 40px;
    height: 40px;
    background-image: url(${loader});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px;
    margin: auto;
`;
