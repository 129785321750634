import styled from 'styled-components';
import { ORANGE_COLOR, DARK_COLOR } from '../../somon_air/constants';
export const Wrapper = styled.div`
    width: 100%;
`;
export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
`;
export const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${DARK_COLOR};
    text-decoration: none;
    padding: 8px 12px;
    margin: 0 10px;
    font-size: 18px;
    transition: box-shadow 0.3s ease;

    &:hover {
        transition: box-shadow 0.3s ease;
        box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.19);
        cursor: pointer;
    }

    &.active {
        font-weight: bold;
        color: ${DARK_COLOR};
        cursor: default;
        background-color: ${ORANGE_COLOR};
    }

    &.active:hover {
        box-shadow: none;
        cursor: default;
    }

    @media screen and (max-width: 992px) {
        padding: 6px 9px;
        margin: 0 2px;
    }
`;
export const ButtonIcon = styled.img`
    height: 18px;
`;
