import React from 'react';
import noun from 'plural-ru';
import { Check, Clear } from '@mui/icons-material';
import { BrandFareInfoItem, BrandOptionIcon, BrandOptionTitle } from './components';
import i18next from 'i18next';

const TariffInfo = ({ brandFare }) => {
    const { routes } = brandFare;
    const { t } = i18next;

    const firstSegment = routes[0].segments[0];
    const { baggage, hand_luggage, is_refund, is_change, hand_luggage_weight, baggage_weight } = firstSegment;

    const baggageString = noun(baggage, t('place_one'), t('place_more'), t('place_many'));
    const handLuggageString = noun(hand_luggage, t('place_one'), t('place_more'), t('place_many'));

    let baggageText = baggage ? baggage + ' ' + baggageString : t.no;
    let handLuggageText = hand_luggage ? hand_luggage + ' ' + handLuggageString : t('no');

    if (baggage_weight && baggage) {
        baggageText += ` (${baggage_weight} ${t('weight_unit')})`;
    }

    if (hand_luggage_weight && hand_luggage) {
        handLuggageText += ` (${hand_luggage_weight} ${t('weight_unit')})`;
    }

    return (
        <div>
            <BrandFareInfoItem color={baggage}>
                <BrandOptionIcon>{baggage ? <Check fontSize="small" /> : <Clear fontSize="small" />}</BrandOptionIcon>
                <BrandOptionTitle>
                    {t('baggage')}: {baggageText}
                </BrandOptionTitle>
            </BrandFareInfoItem>

            <BrandFareInfoItem color={hand_luggage}>
                <BrandOptionIcon>
                    {hand_luggage ? <Check fontSize="small" /> : <Clear fontSize="small" />}
                </BrandOptionIcon>
                <BrandOptionTitle>
                    {t('hand_luggage')}: {handLuggageText}
                </BrandOptionTitle>
            </BrandFareInfoItem>

            <BrandFareInfoItem color={is_refund}>
                <BrandOptionIcon>{is_refund ? <Check fontSize="small" /> : <Clear fontSize="small" />}</BrandOptionIcon>
                <BrandOptionTitle>{t('refund_ticket')}</BrandOptionTitle>
            </BrandFareInfoItem>

            <BrandFareInfoItem color={is_change}>
                <BrandOptionIcon>{is_change ? <Check fontSize="small" /> : <Clear fontSize="small" />}</BrandOptionIcon>
                <BrandOptionTitle>{t('change')}</BrandOptionTitle>
            </BrandFareInfoItem>
        </div>
    );
};

export default TariffInfo;
