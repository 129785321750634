import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import i18next from 'i18next';

import { prebook, book } from '../protocols';
import { getRoutesCountries, getFlightDocsType, normalizePhone, ADT } from '../utils';
import { setPrebookData } from '../store/prebookSlice';
import { setShowLoading, setBookingLoading } from '../store/loading';
import envConfigs from '../configs/envConfigs';
import { setAppConfigs } from '../store/appConfigs';
import Booking from '../components/somon_air/booking';
import { setDesignSettings } from '../store/designSettingsSlice';
import MainLoading from '../components/somon_air/mainLoading';
import { withTranslation } from 'react-i18next';

const BookingPage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { rec_id, session_id, passengers, searchData } = state;
    const { isLoad, recId } = useSelector(state => state.prebook);
    const { isAuth } = useSelector(state => state.authSettings);
    const { currency } = useSelector(state => state.currencySettings);
    const { language, t } = i18next;

    const dispatch = useDispatch();

    const [error, setError] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [isLoadingBook, setIsLoadingBook] = useState(false);
    const [order, setOrder] = useState(null);

    useEffect(() => {
        if (rec_id && session_id) {
            prebook({ rec_id, session_id })
                .then(response => {
                    const { routes, rec_id, price, fare_rules, included, partner_fees, client_fee } = response;

                    const routesCountries = getRoutesCountries(routes, included);
                    const flightDocsType = getFlightDocsType(routesCountries);

                    dispatch(
                        setPrebookData({
                            routes,
                            recId: rec_id,
                            totalPrice: price,
                            partnerFees: partner_fees,
                            clientFees: client_fee,
                            fareRules: fare_rules,
                            included: included,
                            flightType: flightDocsType,
                            loadStatus: true,
                        })
                    );
                })
                .catch(e => {
                    setError(e.response);
                });
        }

        dispatch(
            setDesignSettings({
                showSearchBlock: false,
            })
        );

        return () => {
            dispatch(
                setPrebookData({
                    routes: [],
                    fareRules: [],
                    included: [],
                    recId: null,
                    totalPrice: null,
                    isLoad: false,
                    loadStatus: false,
                })
            );

            dispatch(
                setDesignSettings({
                    showSearchBlock: true,
                })
            );
        };
    }, []);

    useEffect(() => {
        if (isLoad && !isLoadingBook) {
            dispatch(setShowLoading(false));
        } else {
            if (isLoadingBook) {
                dispatch(setBookingLoading(true));
            }
            dispatch(setShowLoading(true));
        }
    }, [isLoad, isLoadingBook]);

    useEffect(() => {
        document.title = `${t('page_booking')} | ${envConfigs.appTitle}`;
    }, [language]);

    const redirectToResult = () => {
        navigate('/result', { state: searchData });
    };

    const handleBookClick = data => {
        setIsLoadingBook(true);

        const passengers = [];

        data.passengers.forEach((passenger, index) => {
            const {
                name,
                surname,
                middle_name,
                citizenship,
                gender,
                type,
                document_type,
                document_number,
                email,
                date_of_birth,
                phone,
                expiration_date,
            } = passenger;

            const passengerPhone = type === ADT ? normalizePhone(phone) : normalizePhone(data.passengers[0].phone);
            const passengerEmail = type === ADT ? email : data.passengers[0].email;
            const expirationDate = expiration_date ? expiration_date.split('.').join('-') : '12-12-2099';

            passengers[index] = {
                index,
                name: name,
                surname: surname,
                middle_name: middle_name || '',
                citizenship: citizenship,
                gender: gender,
                type: type,
                document_type: document_type,
                document_number: document_number,
                email: passengerEmail,
                phone: passengerPhone,
                expiration_date: expirationDate,
                date_of_birth: date_of_birth.split('.').join('-'),
            };
        });

        const params = {
            passengers,
            rec_id: recId,
            partner_fees: data.partnerFees || 0,
            session_id: session_id,
            payer_phone: normalizePhone(data.payer_phone),
            payer_email: data.payer_email,
            meta: {
                currency: currency,
                language,
            },
        };

        book(params)
            .then(response => {
                setOrder(response.data);
                setRedirect(true);
            })
            .catch(error => {
                setError(error.response.data);
            });
    };

    if (error) {
        return null; // TODO: Error modal show
    }

    if (redirect) {
        if (isAuth) {
            navigate(`/order/${order.order_id}`);
            return null;
        }

        navigate(`/order/${order.order_id}?key=${order?.key}`);
        return null;
    }

    if (!rec_id || !session_id) {
        navigate('/');
        return null;
    }

    if (!isLoad || isLoadingBook) return <MainLoading />;

    return (
        <Booking
            sessionId={session_id}
            passengers={passengers}
            handleBookClick={handleBookClick}
            redirectToResult={redirectToResult}
        />
    );
};

export default withTranslation()(BookingPage);
