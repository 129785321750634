import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { normalizePrice } from '../../../../utils';
import {
    Bottom,
    LineDivider,
    PriceItem,
    PriceTitle,
    PriceValue,
    Top,
    TotalItem,
    TotalTitle,
    TotalValue,
    Wrapper,
} from './components';

const Price = () => {
    const { t } = i18next;
    const { currency } = useSelector(state => state.currencySettings);
    const { totalPrice } = useSelector(state => state.prebook);

    return (
        <Wrapper>
            <Top>
                <PriceItem>
                    <PriceTitle>{t('flights_tickets')}:</PriceTitle>
                    <PriceValue>
                        {normalizePrice(totalPrice[currency].toFixed(2))} <span>{currency}</span>
                    </PriceValue>
                </PriceItem>
            </Top>

            <LineDivider />
            
            <Bottom>
                <TotalItem>
                    <TotalTitle>{t('total')}:</TotalTitle>
                    <TotalValue>
                        {normalizePrice(totalPrice[currency].toFixed(2))} <span>{currency}</span>
                    </TotalValue>
                </TotalItem>
            </Bottom>
        </Wrapper>
    );
};

export default Price;
