import _ from 'lodash';
import Error from '@mui/icons-material/Error';

import {
    BrandsBlock,
    BrandsContainer,
    BrandsMessageBlock,
    BrandsMessageIcon,
    BrandsMessageTitle,
    BrandsWrapper,
    LoadingWrapper,
} from './components';
import i18next from 'i18next';
import { StyledCircularProgress } from '../../loadingBlock/components';

const Tariffs = require('./tariffs').default;
const DesktopTariffsBlock = props => {
    const { t } = i18next;
    if (props.brandFares) {
        return (
            <BrandsWrapper>
                <BrandsContainer>
                    <BrandsMessageBlock>
                        <BrandsMessageIcon>
                            <Error fontSize="small" />
                        </BrandsMessageIcon>
                        <BrandsMessageTitle>{t('route_tariffs_message')}</BrandsMessageTitle>
                    </BrandsMessageBlock>

                    <BrandsBlock>
                        <Tariffs {...props} />
                    </BrandsBlock>
                </BrandsContainer>
            </BrandsWrapper>
        );
    }

    return (
        <LoadingWrapper>
            <StyledCircularProgress color={'primary'} />
        </LoadingWrapper>
    );
};

export default DesktopTariffsBlock;
