import { Wrapper } from './components';
import { noun, verb } from 'plural-ru';
import i18next from 'i18next';

const FlightsCount = ({ recommendationsCount }) => {
    const { t } = i18next;

    return (
        <Wrapper>
            {noun(recommendationsCount, t('found_one'), t('found_more'), t('found_more'))} {`${recommendationsCount} `}
            {verb(recommendationsCount, t('flight_one'), t('flights_more'), t('flights_many'))}
        </Wrapper>
    );
};

export default FlightsCount;
