import styled from 'styled-components';
import {
    WHITE_COLOR,
    LIGHT_GREY_COLOR,
    MIDNIGHT_COLOR,
    GREY_COLOR,
    BRIGHT_COLOR,
    MEDIUM_DARK_COLOR,
    DARK_YELLOW_COLOR,
} from '../../constants';
export const Wrapper = styled.main`
    width: 925px;
    margin: 4rem auto;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 899px) {
        height: 100%;
        width: 100%;
        margin: 2rem auto;
    }
`;
export const Title = styled.div`
    margin: 58px auto;
    height: 26px;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    font-family: Raleway;
    letter-spacing: 0px;

    @media (max-width: 899px) {
        font-size: 20px;
        margin: 27px auto;
    }
`;

export const FormSubtitle = styled.div`
    font-size: 20px;
    color: ${BRIGHT_COLOR};
    text-align: center;
    margin-bottom: 58px;
    font-weight: bold;

    @media (max-width: 1279px) {
        font-size: 16px;
        padding: 0 1rem;
    }
`;
export const MainGrid = styled.div`
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    row-gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const SubGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 2rem;
    text-align: center;
    @media (max-width: 899px) {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 374px;

    @media (max-width: 899px) {
        justify-content: center;
    }
    @media (max-width: 599px) {
        width: 291px;
    }
`;
export const Label = styled.div`
    height: 20px;
    margin-bottom: 11px;
    text-align: left;
    font: italic normal 300 17px/20px Raleway;
    letter-spacing: 0px;
    color: #414141;
`;

export const Button = styled.button`
    width: 296px;
    height: 64px;
    background: ${DARK_YELLOW_COLOR} 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    margin: 46px auto 17px;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 899px) {
        max-width: 240px;
        height: 52px;
        font-size: 17px;
        margin-top: 36px;
    }

    &:disabled {
        opacity: .6;
    }
`;
export const Text = styled.div`
    width: 664px;
    height: 32px;
    margin: 0px auto 17px;
    font-size: 14px;
    font-weight: medium;
    font-family: Raleway;
    font-style: italic;
    text-align: center;
    & > span {
        color: ${MEDIUM_DARK_COLOR};
        font-weight: bold;
    }
    @media (max-width: 899px) {
        max-width: 260px;
        font-size: 10px;
    }
`;
