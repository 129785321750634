import i18next from 'i18next';
import { F, M } from '../../../../../../utils/constants/booking';
import Select from '../../../../ui/select';

const GenderField = props => {
    const { t } = i18next;

    const genderItems = [
        { value: '', label: t('choose_gender'), disabled: true },
        { value: M, label: t('male') },
        { value: F, label: t('female') },
    ];

    return <Select {...props} items={genderItems} />;
};

export default GenderField;
