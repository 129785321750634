import { authRoutes, nonAuthRoutes } from './routes';
export * from './mainConfigs';
export * from './routes';
export * from './defaultCurrencies';
export * from './defaultLanguages';
export * from './defaultCountries';

export default {
    authRoutes,
    nonAuthRoutes,
};
