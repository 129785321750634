import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import i18next from 'i18next';

import { Container } from '../../ui/common';
import {
    CurrentBalance,
    Line,
    Header,
    Balance,
    Currency,
    Left,
    PaymentMethod,
    MainHeader,
    PaymentMethodsBlock,
    AmountBlock,
    InputLabel,
    AmountBlockAll,
    PaymentDiscription,
    PaymentImgBG,
    ButtonContainer,
    PaymentMethodsGrid,
    InputCurrency,
    InputError,
    Input,
} from './components';
import Button from '../../ui/button';
import { Wrapper } from './components';
import { useDispatch, useSelector } from 'react-redux';
import envConfigs from '../../../../configs/envConfigs';
import { addBalance, getPaymentSystems } from '../../../../protocols/rest/services';
import kortMilli from '../../../../assets/creditCards/kortMilli.png';
import { setCurrencySettings } from '../../../../store/currencySettingsSlice';
import { ReactComponent as TickIcon } from '../../../../assets/somon_air/icons/tick.svg';
import { GREEN_COLOR, DARK_GREEN_COLOR, WHITE_COLOR } from '../../constants';
import DcPaymentPopup from '../../dcPaymentPopup';
import VisaPaymentPopup from '../../visaPaymentPopup';

function RefillPage() {
    const { t, language } = i18next;
    const { balance } = useSelector(state => state.authSettings);
    const { currency } = useSelector(state => state.currencySettings);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [dcPaymentModal, setDcPaymentModal] = useState(null);
    const [visaPaymentModal, setVisaPaymentModal] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setCurrencySettings({
                isFullScreenSearchBlock: false,
            })
        );

        billingPaymentSystems();
    }, []);

    useEffect(() => {
        document.title = `${t('page_add_balance')} | ${envConfigs.appTitle}`;
    }, [language]);

    const validationSchema = Yup.object().shape({
        amount: Yup.number()
            .typeError(t('only_numbers'))
            .test('minAmount', `${t('minimum_amount')}: 1 ${currency}`, val => val >= 1)
            .test('maxAmount', `${t('maximum_amount')}: 10000 ${currency}`, val => val <= 10000)
            .required(t('required')),
        payment_system_id: Yup.string().required(t('required')),
    });

    const chargeBalance = async values => {
        setLoadingButton(true);

        await addBalance({
            amount: values.amount,
            payment_system_id: values.payment_system_id,
            return_url: window.location.origin,
            fail_url: window.location.origin,
        }).then(res => {
            const { payUrl, payform } = res.message;

            if (payform) {
                setDcPaymentModal({
                    showPopup: true,
                    handleClose: () => setDcPaymentModal(null),
                    payUrl,
                    payform,
                });
            } else if (typeof payUrl === 'object') {
                setVisaPaymentModal({
                    showPopup: true,
                    handleClose: () => setVisaPaymentModal(null),
                    data: payUrl,
                });
            } else {
                window.location.href = `${payUrl}`;
            }
        });
    
        setLoadingButton(false);
    };

    const { values, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            amount: 1,
            payment_system_id: paymentMethods[0]?.id,
        },
        validationSchema,
        validateOnChange: true,
        onSubmit: chargeBalance,
    });

    const billingPaymentSystems = () => {
        setIsLoading(true);
    
        getPaymentSystems().then(result => {
            const methods = result.filter(item => item.id !== 0);
            setPaymentMethods(methods);
            setIsLoading(false);
            setFieldValue('payment_system_id', methods[0]?.id);
        });
    };

    const normalizeCurrency = value => {
        let newValue = value;

        if (newValue.length === 2 && /[0][0-9]/s.test(newValue)) {
            newValue = newValue.substring(1);
        }

        const re = /([0-9]*[.|,]{0,1}[0-9]{0,2})/s;

        return newValue.match(re)[0];
    };

    return (
        <>
            <Container>
                <MainHeader>{t('recharging_balance')}</MainHeader>

                <Wrapper>
                    <Header>
                        <CurrentBalance>{t('current_balance')}:</CurrentBalance>
                        <Left>
                            <Balance>{balance}</Balance>
                            <Currency>{currency}</Currency>
                        </Left>
                    </Header>

                    <Line />

                    <PaymentMethod>{t('deposit_method')}</PaymentMethod>

                    <PaymentMethodsGrid>
                        {!isLoading && paymentMethods.map((item, index) => {
                            return (
                                <PaymentMethodsBlock
                                    key={`chargeBalance-${index}`}
                                    error={errors.payment_system_id}
                                    active={values.payment_system_id === item.id}
                                    onClick={() => setFieldValue('payment_system_id', item.id)}
                                >
                                    <PaymentImgBG
                                        src={item.logo ? `${envConfigs.uploadHost}${item.logo}` : kortMilli}
                                    />

                                    {item.description[language] ? (
                                        <PaymentDiscription>{item.description[language]}</PaymentDiscription>
                                    ) : null}
                                </PaymentMethodsBlock>
                            );
                        })}
                    </PaymentMethodsGrid>

                    <PaymentMethod>{t('recharging_amount')}</PaymentMethod>

                    <AmountBlockAll>
                        <div style={{ flexGrow: 1 }}>
                            <AmountBlock>
                                <InputLabel>{t('amount')}:</InputLabel>
                                <Input
                                    value={values.amount}
                                    onChange={event => setFieldValue('amount', normalizeCurrency(event.target.value))}
                                />
                                <InputCurrency>{currency}</InputCurrency>
                            </AmountBlock>
                            {errors.amount && <InputError>{errors.amount}</InputError>}
                        </div>

                        <ButtonContainer>
                            <Button
                                title={t('continue')}
                                fontSize={32}
                                opacity="1"
                                type="submit"
                                onClick={handleSubmit}
                                backgroundColor={GREEN_COLOR}
                                iconBackgroundColor={DARK_GREEN_COLOR}
                                borderColor={GREEN_COLOR}
                                textTransform="none"
                                icon={<TickIcon />}
                                iconTransition
                                color="#fff"
                                loading={loadingButton}
                                loadingColor={WHITE_COLOR}
                            >
                                {t('recharge')}
                            </Button>
                        </ButtonContainer>
                    </AmountBlockAll>
                </Wrapper>
            </Container>

            <DcPaymentPopup
                showPopup={dcPaymentModal?.showPopup}
                handleClose={dcPaymentModal?.handleClose}
                payUrl={dcPaymentModal?.payUrl}
                payform={dcPaymentModal?.payform}
            />

            <VisaPaymentPopup
                showPopup={visaPaymentModal?.showPopup}
                handleClose={visaPaymentModal?.handleClose}
                data={visaPaymentModal?.data}
            />
        </>
    );
}

export default RefillPage;
