import { basicInstance } from '../../instances';

export const refundOrder = async data => {
    return basicInstance()
        .post('refund-order', data, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        })
        .then(response => response.data)
        .catch(error => error.response);
};
