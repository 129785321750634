import styled from 'styled-components';
import { ALTO_COLOR, BRIGHT_COLOR, ORANGE_COLOR, RUBIK_FONT, ZAMBEZI_COLOR } from '../../../constants';

export const TransferBlock = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;

    @media (min-width: 900px) and (max-width: 1279px) {
        padding: 5px 0;
    }

    @media (max-width: 599px) {
        padding: 15px 0;
        border-top: 1px solid ${ALTO_COLOR};
        border-bottom: 1px solid ${ALTO_COLOR};
    }
`;

export const TransferIconBlock = styled.div``;

export const TransferTime = styled.div`
    margin-left: 10px;
    line-height: 1;
    font-family: ${RUBIK_FONT};
    color: ${BRIGHT_COLOR};

    @media (max-width: 1279px) {
        font-size: 14px;
        line-height: 1.3;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    gap: 30px;

    @media (min-width: 900px) and (max-width: 1279px) {
        gap: calc(15px + (30 - 15) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        gap: calc(10px + (20 - 10) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
`;

export const AirlineLogoBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(100px + (140 - 100) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        width: calc(100px + (120 - 100) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
    }
`;

export const AirlineLogo = styled.div`
    height: 30px;
    width: 100%;
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-position: center center;
`;

export const FlightInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 510px;
    height: 170px;
    border: 2px solid ${ORANGE_COLOR};
    border-radius: 30px;

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(320px + (510 - 320) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        width: calc(310px + (470 - 310) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        width: 100%;
        height: auto;
        border: none;
    }
`;

export const FlightPartInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 33%;

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(36% + (33 - 36) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        width: calc(36% + (33 - 36) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        width: 35%;
    }

    & > .date {
        font-family: ${RUBIK_FONT};
        font-size: 12px;
    }

    & > .time {
        font-family: ${RUBIK_FONT};
        font-size: 34px;
        font-weight: 500;
        color: ${BRIGHT_COLOR};

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: calc(26px + (34 - 26) * ((100vw - 900px) / (1280 - 900)));
        }

        @media (min-width: 600px) and (max-width: 899px) {
            font-size: calc(26px + (34 - 26) * ((100vw - 600px) / (900 - 600)));
        }

        @media (max-width: 599px) {
            font-size: 22px;
        }
    }

    & > .city {
        font-family: ${RUBIK_FONT};
        font-size: 15px;
        color: ${ZAMBEZI_COLOR};

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: calc(13px + (15 - 13) * ((100vw - 900px) / (1280 - 900)));
        }

        @media (min-width: 600px) and (max-width: 899px) {
            font-size: calc(13px + (15 - 13) * ((100vw - 600px) / (900 - 600)));
        }

        @media (max-width: 599px) {
            font-size: 13px;
        }
    }

    & > .airport {
        font-family: ${RUBIK_FONT};
        font-size: 15px;
        color: ${ZAMBEZI_COLOR};
        text-align: center;

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: calc(13px + (15 - 13) * ((100vw - 900px) / (1280 - 900)));
        }

        @media (min-width: 600px) and (max-width: 899px) {
            font-size: calc(13px + (15 - 13) * ((100vw - 600px) / (900 - 600)));
        }

        @media (max-width: 599px) {
            font-size: 13px;
        }
    }

    & > .iataCode {
        font-family: ${RUBIK_FONT};
        font-size: 15px;
        font-weight: bold;
        color: ${ZAMBEZI_COLOR};

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: calc(13px + (15 - 13) * ((100vw - 900px) / (1280 - 900)));
        }

        @media (min-width: 600px) and (max-width: 899px) {
            font-size: calc(13px + (15 - 13) * ((100vw - 600px) / (900 - 600)));
        }

        @media (max-width: 599px) {
            font-size: 13px;
        }
    }
`;

export const DurationInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 34%;

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(28% + (34 - 28) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        width: calc(28% + (34 - 28) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        width: 28%;
    }

    & > .duration {
        display: flex;
        font-family: ${RUBIK_FONT};
        font-size: 14px;
        color: ${ZAMBEZI_COLOR};
        text-transform: lowercase;
        gap: 5px;

        & > span {
            font-family: ${RUBIK_FONT};
        }

        @media (max-width: 1279px) {
            flex-direction: column;
            align-items: center;
            gap: 2px;
        }

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: calc(12px + (14 - 12) * ((100vw - 900px) / (1280 - 900)));
        }

        @media (min-width: 600px) and (max-width: 899px) {
            font-size: calc(12px + (14 - 12) * ((100vw - 600px) / (900 - 600)));
        }
    }
`;

export const TransfersArrow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
    position: relative;

    & > .circle {
        border: 2px solid ${ZAMBEZI_COLOR};
        background-color: ${ZAMBEZI_COLOR};
        border-radius: 50%;
        height: 6px;
        width: 6px;
        top: 10px;
        position: absolute;
    }

    & > .line {
        top: 12px;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: ${ZAMBEZI_COLOR};
    }

    & > .circle-bottom {
        border: 2px solid ${ZAMBEZI_COLOR};
        background-color: ${ZAMBEZI_COLOR};
        border-radius: 50%;
        height: 6px;
        width: 6px;
        right: 0px;
        top: 10px;
        position: absolute;
    }
`;

export const AdditionalInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 260px;

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(110px + (260 - 110) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        width: calc(100px + (200 - 100) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        // padding-top: 15px;
        // border-top: 2px solid ${ALTO_COLOR};
    }
`;

export const SegmentServiceClass = styled.div`
    color: ${BRIGHT_COLOR};
    font-size: 19px;
    font-family: ${RUBIK_FONT};

    & > span {
        font-family: ${RUBIK_FONT};
        font-weight: 500;
    }

    @media (max-width: 1279px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: calc(14px + (19 - 14) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        font-size: calc(14px + (19 - 14) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        font-size: 14px;
        align-items: flex-start; 
    }
`;

export const FlightIconsAndBaggage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    @media (max-width: 599px) {
        align-items: flex-end;
        gap: 5px;
    }
`;

export const SegmentBaggage = styled.div`
    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: calc(12px + (16 - 12) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        font-size: calc(12px + (16 - 12) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        font-size: 12px;
    }
`;
