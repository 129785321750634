import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bookingLoading: false,
    showLoading: true,
    searchLoading: true,
    searchData: null,
};

export const loading = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setAllLoadingData: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setBookingLoading: (state, action) => {
            return {
                ...state,
                bookingLoading: action.payload,
            };
        },
        setShowLoading: (state, action) => {
            return {
                ...state,
                showLoading: action.payload,
            };
        },
        setSearchLoading: (state, action) => {
            return {
                ...state,
                searchLoading: action.payload,
            };
        },
        setSearchData: (state, action) => {
            return {
                ...state,
                searchData: action.payload,
            };
        },
    },
});
export const { setAllLoadingData, setBookingLoading, setSearchData, setSearchLoading, setShowLoading } =
    loading.actions;

export default loading.reducer;
