import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterParams } from '../../../../../../../store/filtersSlice';
import InputUI from '../../../../../../ui/InputUI';
import FilterBlock from '../filterBlock';
import { Block } from './components';

const FlightNumbersFilter = () => {
    const { t } = i18next;
    const dispatch = useDispatch();
    const { filterParams } = useSelector(state => state.filters);
    const { isActive, values } = filterParams.flightNumbers;
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(values.join(' '));
    }, []);

    const keyPress = e => {
        if (e.keyCode === 13) {
            dispatch(
                setFilterParams({
                    type: 'flightNumbers',
                    values: {
                        isActive: e.target.value.length > 0,
                        values: e.target.value.split(' '),
                    },
                })
            );
        }
    };

    const handleBlur = () => {
        dispatch(
            setFilterParams({
                type: 'flightNumbers',
                values: {
                    isActive: value.length > 0,
                    values: value.split(' '),
                },
            })
        );
    };

    return (
        <FilterBlock title={t('flight_number')} isOpen={isActive} borderBottom>
            <Block>
                <InputUI
                    label={t('multiple_numbers_separated_by_spaces')}
                    onKeyDown={keyPress}
                    onBlur={handleBlur}
                    value={value}
                    onChange={value => setValue(value)}
                />
            </Block>
        </FilterBlock>
    );
};

export default FlightNumbersFilter;
