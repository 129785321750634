import i18next from 'i18next';
import { useEffect, useState } from 'react';
import defaultCountries from '../../../../../../configs/defaultCountries';
import { getCountryIcon } from '../../../../../../lib/getImage';
import { getCountries } from '../../../../../../protocols';
import Input from '../../../../ui/input';
import TransitionDropdown from '../../../../ui/transiotionDropdown';
import { CountryFlag, CountryItem, CountryLabel, SelectedCountryFlag, Wrapper } from './components';

const CitizenshipField = ({ citizenshipValue, touched, error, onChange, onBlur, clearFieldError }) => {
    const { language, t } = i18next;
    const [value, setValue] = useState('');
    const [citizenship, setCitizenship] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [countries, setCountries] = useState(defaultCountries);

    const handleOnSelect = country => {
        setCitizenship(country);
        setValue(country.country[language]);
        onChange(country.code);
        setShowDropdown(false);
    };

    const handleChangeInput = newValue => {
        setValue(newValue);

        if (newValue.length > 1) {
            getCountries(newValue).then(response => setCountries(response));
        } else {
            setCountries(defaultCountries);
        }

        if (newValue !== citizenship?.country[language]) {
            setCitizenship(null);
            onChange('');
        }
    };

    const renderCountryItems = () => {
        return countries.map((country, index) => {
            return (
                <CountryItem key={index} onClick={() => handleOnSelect(country)}>
                    <CountryLabel>{country.country[language]}</CountryLabel>
                    <CountryFlag backgroundImage={getCountryIcon(country.code)} />
                </CountryItem>
            );
        });
    };

    return (
        <Wrapper>
            <Input
                value={value}
                onChange={handleChangeInput}
                label={t('citizenship')}
                placeholder={t('citizenship')}
                touched={touched}
                error={!showDropdown ? error : null}
                onBlur={onBlur}
                onClick={() => setShowDropdown(true)}
                onFocus={clearFieldError}
                boxShadow
                required
            />

            {citizenship && <SelectedCountryFlag backgroundImage={getCountryIcon(citizenship.code)} />}

            {showDropdown && (
                <TransitionDropdown show={showDropdown} onClickOutside={() => setShowDropdown(false)} noPadding>
                    {renderCountryItems()}
                </TransitionDropdown>
            )}
        </Wrapper>
    );
};

export default CitizenshipField;
