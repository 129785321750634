import styled from 'styled-components';
import { LINEN_COLOR, ORANGE_COLOR, ROMANTIC_COLOR, RUBIK_FONT } from '../../../../constants';

export const Wrapper = styled.div`
    position: relative;
`;

export const CountryItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    font-size: 14px;
    position: relative;
    cursor: pointer;

    &:hover {
        background: ${ORANGE_COLOR}4D;
    }

    &:active {
        background: ${ROMANTIC_COLOR};
    }

    &:nth-of-type(1) {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &:nth-last-of-type(1) {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

export const CountryLabel = styled.span`
    font-family: ${RUBIK_FONT};
`;

export const CountryFlag = styled.div`
    width: 24px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px 24px;
    background-image: url(${props => props.backgroundImage});
`;

export const SelectedCountryFlag = styled(CountryFlag)`
    position: absolute;
    bottom: 22px;
    right: 16px;

    @media (max-width: 899px) {
        right: 8px;
        bottom: 15px;
    }
`;
