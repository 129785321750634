import styled from "styled-components";
import { FilledButton } from "../../../ui/button/components";

export const Wrapper = styled.div`
    display: flex;
    background-color: ${props => props.background == 'success' ? '#F6FFFA' : '#FFF6F6'};
    box-shadow: 0px 0px 10px #00000033;
    padding: 16px;
    flex-direction: column;
    margin: 16px 0;
`;

export const Title = styled.h3`
    margin: 0;
    font-weight: 500;
`;

export const Date = styled.span`
    font-weight: light;
`;

export const OrderSuccess = styled.span`
    color: green;
`;

export const ActionButton = styled.button`
    background-color: #F1B740;
    width: 160px;
    height: 45px;
    text-align: center;
    display: flex;
    align-items: center;
`;

export const TitleBlock = styled.div`
    width: 100%;
    display: flex;
    padding: 8px;
    gap: 1rem;
    flex-wrap: wrap;
`;

export const StatusButtonBlock = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    flex-wrap: wrap;
`;

export const Button = styled.button`
    padding: 8px 16px;
    background-color: #F1B740;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-top: 16px;

    &:hover {
        opacity: .8;
        cursor: pointer;
    }

    @media (max-width: 499px) {
        width: 100%;
        height: 36px;
        text-align: center;
        justify-content: center;
        font-size: 18px;
    }
`;

export const ActionBlock = styled.span`
    margin: 0 4px;
`;

export const IconBlock = styled.span`
    margin: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: ${props => props.rotate ? 'rotate(0deg)' : 'rotate(180deg)'};
`;

export const ActionsBlock = styled.div`
    margin: 16px 0;
    padding: 16px;
    border-top: 2px solid #F1B740;
    display: flex;
    gap: 1rem;
`;

export const ActionBlocksButton = styled(FilledButton)`
    border: 0;
    width: auto;
    display: flex;
    gap: 0.5rem;
    color: #b6b6b6;
    font-size: 15px;
    padding: 8px 16px;
    
    &:disabled {
        pointer-events: none;
        opacity: .6;
    }

    &:hover {
        background-color: #F1B740;
        color: #000;
    }

    @media (max-width: 899px) {
        padding: 4px;
        & svg {
            width: 20px;
            height: 20px;
        }
    }

    @media (max-width: 499px) {
        & svg {
            display: none;
        }
    }
`;