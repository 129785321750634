import React from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { GREY_COLOR, NERO_COLOR, ORANGE_COLOR, WHITE_COLOR } from '../../somon_air/constants';

const useStylesInput = makeStyles({
    root: {
        border: `1px solid ${GREY_COLOR} `,
        overflow: 'hidden',
        borderRadius: 4,
        paddingLeft: 8,
        backgroundColor: `${WHITE_COLOR}`,
        color: `${NERO_COLOR}`,
        fontSize: 18,
        '&:hover': {
            backgroundColor: `${WHITE_COLOR}`,
        },
    },
    focused: {
        backgroundColor: `${WHITE_COLOR}`,
        borderColor: `${ORANGE_COLOR}`,
    },
});

function InputTextField(props) {
    const classes = useStylesInput();
    const { InputProps, input } = props;

    return (
        <TextField
            {...props}
            {...input}
            autoComplete="off"
            InputProps={{
                ...InputProps,
                classes,
                disableUnderline: true,
            }}
        />
    );
}

export default function InputUI(props) {
    const { onChange } = props;

    const handelChange = e => {
        onChange(e.target.value);
    };

    return (
        <InputTextField
            {...props}
            onChange={handelChange}
            variant="filled"
            fullWidth
            InputLabelProps={{
                shrink: true,
                color: 'primary',
            }}
        />
    );
}

InputUI.defaultProps = {
    onChange: () => {},
};
