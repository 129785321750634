import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 40px;
    padding: 60px 0;

    @media (max-width: 1279px) {
        gap: calc(20px + (40 - 20) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (max-width: 899px) {
        flex-direction: column;
        gap: 20px;
        padding: 30px 0;
    }
`;

export const InfoBlock = styled.div`
    width: 1060px;
    order: 1;

    @media (min-width: 1280px) and (max-width: 1529px) {
        width: calc(910px + (1060 - 910) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(600px + (910 - 600) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (max-width: 899px) {
        width: 100%;
        order: 2;
    }
`;

export const PriceBlock = styled.div`
    width: 400px;
    order: 2;

    @media (max-width: 1499px) {
        width: calc(300px + (400 - 300) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (max-width: 1279px) {
        width: calc(250px + (300 - 250) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (max-width: 899px) {
        width: 100%;
        order: 1;
    }
`;
