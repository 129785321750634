import { useEffect, useState } from 'react';
import { Container } from '../../ui/common';
import i18next from 'i18next';
import { MainHeader, NoResultsBlock, TableBlock, Table, TableHead, Td, Tr, Th, PaginationBlock } from './components';
import { normalizePrice } from '../../../../utils';
import { getTransactions } from '../../../../protocols/rest/services';
import Pagination from '../../../ui/pagination';
import moment from 'moment';

function TransactionsPage() {
    const { t } = i18next;
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState([]);
    const [getPagination, setGetPagination] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        transactions();
    }, []);

    useEffect(() => {
        transactions();
    }, [page]);

    const transactions = async () => {
        setIsLoading(true);
        await getTransactions({ page }).then(res => {
            const pagination = res;
            const transactions = res.data;
            setState(transactions);
            setGetPagination(pagination);
            setIsLoading(false);
        });
    };
    const handleChangePage = value => {
        setPage(value);
    };

    return (
        <div>
            <Container>
                <MainHeader>{t('transactions')}</MainHeader>
                {state.length == 0 ? (
                    <NoResultsBlock>{t('no_result')}</NoResultsBlock>
                ) : (
                    <TableBlock>
                        <Table>
                            <TableHead>
                                <Tr>
                                    <Th>{t('date')}</Th>
                                    <Th>{t('income')}</Th>
                                    <Th>{t('spending')}</Th>
                                    <Th>{t('remainder')}</Th>
                                    <Th>{t('description')}</Th>
                                </Tr>
                            </TableHead>
                            <tbody>
                                {state.map((item, index) => {
                                    return (
                                        <Tr>
                                            <Td>{moment(item.updated_at).format('DD.MM.YYYY')}</Td>
                                            <Td>
                                                {item.amount >= 0 && `${normalizePrice(item.amount)} ${item.currency}`}
                                            </Td>
                                            <Td>
                                                {item.amount < 0 && `${normalizePrice(item.amount)} ${item.currency}`}
                                            </Td>
                                            <Td>
                                                {normalizePrice(item.balance)} {item.currency}
                                            </Td>
                                            <Td>{item.comment}</Td>
                                        </Tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </TableBlock>
                )}

                {getPagination.last_page > 1 && (
                    <PaginationBlock>
                        <Pagination
                            currentPage={page}
                            lastPage={getPagination.last_page}
                            setPage={handleChangePage}
                            shape="rounded"
                            variant="outlined"
                        />
                    </PaginationBlock>
                )}
            </Container>
        </div>
    );
}

export default TransactionsPage;
