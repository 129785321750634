import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from './utils';
import { ConfigurationContainer } from './containers';
import SnackbarProvider from './contexts/snackbarContext';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ErrorBoundary>
                <ConfigurationContainer>
                    <SnackbarProvider>
                        <App />
                    </SnackbarProvider>
                </ConfigurationContainer>
            </ErrorBoundary>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
