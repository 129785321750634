import styled from 'styled-components';

export const DateBlock = styled.div`
    max-width: 165px;
    padding: 16px 8px;
    text-align: center;
    color: #8F8F8F;
    box-shadow: 0px 0px 10px #00000033;
    border-radius: 5px;
    margin: 16px auto;
`;