import styled from 'styled-components';
import { BRIGHT_RED_COLOR } from '../../constants';

export const Wrapper = styled.div``;

export const CheckboxBlock = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 40px;
`;

export const AgreementText = styled.div`
    margin-left: 10px;
    font-size: 20px;

    @media (max-width: 899px) {
        font-size: 18px;
    }

    @media (max-width: 599px) {
        font-size: 14px;
    }
`;

export const AgreementBookLink = styled.span`
    color: ${BRIGHT_RED_COLOR};
    cursor: pointer;
    padding: 0 4px;
`;

export const SubmitBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
`;
