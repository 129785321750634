import {
    AirlineLogoBlock,
    AirlineLogo,
    FlightInfo,
    FlyTo,
    FlyToIcon,
    InfoCard,
    OrderInfo,
    AirlineInfo,
    RouteInfo,
    TimeRouteInfo,
    CityCode,
    InfoBlock,
    ClassInfo,
    AdditionalInfoIcons,
    AdditionalInfos,
    AmountBlock,
    ChooseButton,
    PlaceAmount,
    LuggageAmount,
    MoreDetails,
    Balance,
    Currency,
    DurationBlock,
    Details,
    TransferBlock,
    HideRouteTariffContainer,
    StyledHideTariffsButton,
    TariffCloseBlock,
    ChangeTariffContainer,
    TransfersArrow,
    RouteTitle,
    FlightBlock,
    DetailsCard,
    RouteCard,
    AmountContainer,
    Result,
    Circle,
    CircleBlock,
    Circle2,
    Circle3,
} from './components';
import flyIcon from '../../../../../../assets/somon_air/icons/resultPage/flightRouteIcons/1_flight-takeoff.svg';
import { COLOR_THEME } from '../../constants';
import noun from 'plural-ru';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getSupplierIcon } from '../../../../../../lib/getImage';
import _ from 'lodash';
import { useState } from 'react';
import { RT } from '../../../../../../containers/search/constants';
import { getDayMonthYearFormat } from '../../../../../result/common';
import { getServiceClass } from '../../utils';
import FlightInfoIcons from '../../flightInfoIcons';
import AdditionallyForm from '../../additionallyForm';
import { normalizePrice } from '../../../../../../lib/normalize';
import { Divider } from './components';

function DesktopRecommendation({ recommendation, onBookingClick, getBrandFares, getFareRules, specialText }) {
    const { brandFares, included, session } = useSelector(state => state.resultData);
    const { currency } = useSelector(state => state.currencySettings);
    const { flightType } = useSelector(state => state.searchParams);
    const { t, language } = i18next;
    const [showAdditionalInfoItems, setShowAdditionalInfoItems] = useState(
        recommendation.routes.map(route => {
            return {
                showDetailsTariffsButtons: false,
                showDetails: false,
                showTariffs: false,
            };
        })
    );
    const getCityString = (airportCode, cityCode) => {
        const { city, airport } = included;

        const cityName = city[cityCode].name[language];
        const airportName = airport[airportCode].name[language];

        if (cityName === airportName) {
            return cityName;
        } else {
            return (
                <div>
                    <div>{cityName},</div>
                    <div>{airportName} </div>
                </div>
            );
        }
    };

    const renderDepartureInfo = departureSegment => {
        const { time, airport, city } = departureSegment.departure;

        return (
            <InfoBlock>
                <RouteInfo>{getDayMonthYearFormat(time, language)}</RouteInfo>
                <TimeRouteInfo>{time.split(' ')[1]}</TimeRouteInfo>
                <RouteInfo>{getCityString(airport, city)}</RouteInfo>
                <CityCode>{airport}</CityCode>
            </InfoBlock>
        );
    };

    const renderArrivalInfo = arrivalSegment => {
        const { time, airport, city } = arrivalSegment.arrival;

        return (
            <InfoBlock>
                <RouteInfo>{getDayMonthYearFormat(time, language)}</RouteInfo>
                <TimeRouteInfo>{time.split(' ')[1]}</TimeRouteInfo>
                <RouteInfo>{getCityString(airport, city)}</RouteInfo>
                <CityCode>{airport}</CityCode>
            </InfoBlock>
        );
    };
    const renderDurationTime = duration => {
        const hours = Math.trunc(duration / 3600);
        const minutes = Math.floor((duration - hours * 3600) / 60);

        return (
            <span>
                {t('on_way')}: {hours} {t('hour_short')} {minutes} {t('minutes_short_3')}
            </span>
        );
    };

    const renderTransfersInfo = segments => {
        const transfersCount = segments.length - 1;

        if (transfersCount === 0) {
            return <div>{t('no_transfers')}</div>;
        }

        const TRANSFER_COUNT_LABEL = {
            [1]: t('transfer_1'),
            [2]: t('transfer_2_4'),
            [3]: t('transfer_2_4'),
            [4]: t('transfer_2_4'),
            [5]: t('transfer_5'),
        };

        const transfersCodeList = segments
            .slice(0, -1)
            .map(segment => segment.arrival.airport)
            .join(', ');

        return (
            <TransferBlock>
                <div>{transfersCount}</div>
                <span>&nbsp;</span>
                <div>{TRANSFER_COUNT_LABEL[transfersCount]}:</div>
                <span>&nbsp;</span>
                <div>{transfersCodeList}</div>
            </TransferBlock>
        );
    };
    const renderDuration = route => {
        return (
            <>
                {renderDurationTime(route.duration)}
                <TransfersArrow>
                    {route.segments.length === 2 && (
                        <CircleBlock>
                            <Circle />
                        </CircleBlock>
                    )}
                    {route.segments.length === 3 && (
                        <CircleBlock>
                            <Circle3 /> <Circle2 />{' '}
                        </CircleBlock>
                    )}
                    {route.segments.length === 4 && (
                        <CircleBlock>
                            <Circle3 /> <Circle /> <Circle2 />
                        </CircleBlock>
                    )}

                    <span className="circle" />
                    <span className="line" />
                    <span className="circle-bottom" />
                </TransfersArrow>
                {renderTransfersInfo(route.segments)}
            </>
        );
    };
    const getFreeSeatsLabel = freeSeats => {
        if (freeSeats === 1) {
            return freeSeats + ' ' + t('free_seats_1');
        }

        if (freeSeats > 1 && freeSeats < 5) {
            return freeSeats + ' ' + t('free_seats_2_4');
        }

        if (freeSeats > 4 && freeSeats < 9) {
            return freeSeats + ' ' + t('free_seats_5');
        }

        if (freeSeats > 8) {
            return '9+ ' + t('free_seats_5');
        }
    };

    const hideRouteTariffDetails = routeIndex => {
        let newValues = [...showAdditionalInfoItems];
        newValues[routeIndex] = {
            showDetailsTariffsButtons: false,
            showDetails: false,
            showTariffs: false,
        };
        setShowAdditionalInfoItems(newValues);
    };

    const handleShowRouteDetails = routeIndex => {
        let newValues = [...showAdditionalInfoItems];
        newValues[routeIndex] = {
            showDetailsTariffsButtons: true,
            showDetails: true,
            showTariffs: false,
        };
        setShowAdditionalInfoItems(newValues);
    };

    const handleShowTariffs = (recId, routeIndex) => {
        let newValues = [...showAdditionalInfoItems];
        newValues[routeIndex] = {
            showDetailsTariffsButtons: true,
            showDetails: false,
            showTariffs: true,
        };
        setShowAdditionalInfoItems(newValues);
        getBrandFares(recId);
    };

    const renderBaggage = (baggage, baggage_weight) => {
        switch (baggage) {
            case _.isNull(baggage):
                baggage = 0;
                break;
            case '0':
            case '0N':
            case '0 PC':
            case '0PC':
            case 'N/A':
            case 'NO':
            case '0 N/A':
                baggage = 0;
                break;
            case '1':
            case '1P':
            case '1 PC':
            case '10K':
            case '10 KG':
            case '20 KG':
            case '23 KG':
            case '30 KG':
            case '35 KG':
            case '40 KG':
            case '1PC':
                baggage = 1;
                break;
            case '2P':
            case '2 PC':
                baggage = 2;
                break;
            default:
                break;
        }
        return (
            <div style={{ fontSize: '12px', textAlign: 'center' }}>
                {baggage !== 0
                    ? `${t('baggage')}: ${baggage} ${noun(baggage, t('place_one'), t('place_more'), t('place_many'))} ${
                          baggage_weight ? `(${baggage_weight} ${t('weight_unit')})` : ''
                      }`
                    : t('no_baggage')}
            </div>
        );
    };

    const shortTariffInfo = (departureSegment, recId, route) => {
        const { service_class, free_seats, baggage, baggage_weight } = departureSegment;
        const { showDetails, showTariffs } = showAdditionalInfoItems[route.index];
        const routeIndex = route.index;
        const freeSeatsLabel = getFreeSeatsLabel(free_seats);

        return (
            <div>
                <OrderInfo>
                    <ClassInfo>{getServiceClass(service_class, t('class'))}</ClassInfo>
                    <AdditionalInfoIcons>
                        <FlightInfoIcons data={departureSegment} />
                    </AdditionalInfoIcons>
                    <AdditionalInfos>
                        <PlaceAmount>{freeSeatsLabel}</PlaceAmount>
                        <LuggageAmount>{renderBaggage(baggage, baggage_weight)}</LuggageAmount>
                    </AdditionalInfos>
                    <ChangeTariffContainer>
                        {showDetails ? (
                            <HideRouteTariffContainer onClick={() => hideRouteTariffDetails(routeIndex)}>
                                <StyledHideTariffsButton type="button" />
                                <TariffCloseBlock>{t('collapse')}</TariffCloseBlock>
                            </HideRouteTariffContainer>
                        ) : (
                            <MoreDetails onClick={() => handleShowRouteDetails(routeIndex)}>
                                {t('flight_details')}
                            </MoreDetails>
                        )}
                        {/* {hasBrandedTariffs && (
                            <MoreDetails onClick={() => handleShowTariffs(recId, routeIndex)}>
                                {t('change_tariff')}
                            </MoreDetails>
                        )} */}
                        {showTariffs ? (
                            <HideRouteTariffContainer onClick={() => hideRouteTariffDetails(routeIndex)}>
                                <StyledHideTariffsButton type="button" />
                                <TariffCloseBlock>{t('collapse')}</TariffCloseBlock>
                            </HideRouteTariffContainer>
                        ) : (
                            <MoreDetails onClick={() => handleShowTariffs(recId, routeIndex)}>
                                {t('change_tariff')}
                            </MoreDetails>
                        )}
                    </ChangeTariffContainer>
                </OrderInfo>
            </div>
        );
    };
    const renderRoute = (recommendation, route, index) => {
        const { validating_supplier, brandRecId, brand_name, rec_id, lowcost } = recommendation;
        const { showDetails, showTariffs } = showAdditionalInfoItems[route.index];
        const brandFaresItem = brandFares[rec_id];

        let actualRoute = JSON.parse(JSON.stringify(route));
        if (brandFaresItem) {
            const currentBrandFare = brandFaresItem.find(item => item.rec_id === brandRecId);

            if (currentBrandFare) {
                actualRoute.segments = currentBrandFare.routes[index].segments;
            }
        }
        const getDepartureTitle = index => {
            if (flightType === RT) {
                if (index === 0) {
                    return t('there_title');
                } else {
                    return t('back_title');
                }
            }

            return t('departure_title');
        };
        const renderSupplierBlock = validatingSupplier => {
            const { supplier } = included;
            const supplierArray = _.find(supplier, { iata: validatingSupplier });
            const validatingSupplierName = supplierArray ? supplierArray.name[language] : null;

            return <>{validatingSupplierName && `${validatingSupplierName} — ${t('validates_flights')}`}</>;
        };

        const departureSegment = _.first(actualRoute.segments);
        const arrivalSegment = _.last(actualRoute.segments);
        const themeColor = COLOR_THEME[index];
        return (
            <div key={index}>
                <InfoCard>
                    <AirlineLogoBlock>
                        <FlyTo>
                            <FlyToIcon imgUrl={flyIcon} themeColor={themeColor} />
                            <RouteTitle themeColor={themeColor}>{getDepartureTitle(index)}</RouteTitle>
                        </FlyTo>
                        <AirlineLogo imgUrl={getSupplierIcon(validating_supplier)} />
                        {recommendation.routes.length === 1 && (
                            <AirlineInfo> {renderSupplierBlock(validating_supplier)}</AirlineInfo>
                        )}

                        {flightType === RT && route.index === 1 && (
                            <AirlineInfo> {renderSupplierBlock(validating_supplier)}</AirlineInfo>
                        )}
                    </AirlineLogoBlock>
                    <FlightInfo>
                        <FlightBlock>
                            {renderDepartureInfo(departureSegment)}
                            <DurationBlock>{renderDuration(route)}</DurationBlock>
                            {renderArrivalInfo(arrivalSegment)}
                        </FlightBlock>
                    </FlightInfo>
                </InfoCard>

                {(showDetails || showTariffs) && (
                    <Details>
                        <Divider />
                        <AdditionallyForm
                            route={actualRoute}
                            recommendation={recommendation}
                            included={included}
                            routeIndex={index}
                            recId={rec_id}
                            brandRecId={brandRecId}
                            brandFares={brandFaresItem}
                            showTariffs={showTariffs}
                            showDetails={showDetails}
                            setShowRouteDetails={handleShowRouteDetails}
                            setShowTariffs={handleShowTariffs}
                            getFareRules={getFareRules}
                            brandName={brand_name}
                            currency={currency}
                        />
                        {recommendation.routes.length === 2 && <Divider />}
                    </Details>
                )}
            </div>
        );
    };
    const renderInfo = (recommendation, route, index) => {
        const { has_branded_tariffs, rec_id, lowcost } = recommendation;
        let actualRoute = JSON.parse(JSON.stringify(route));
        const departureSegment = _.first(actualRoute.segments);
        return (
            <div>
                {shortTariffInfo(departureSegment, rec_id, actualRoute, has_branded_tariffs, lowcost)}
                {index === 0 && renderButtonContainer(recommendation)}
            </div>
        );
    };
    const renderSubmit = recommendation => {
        const { brandRecId, rec_id } = recommendation;
        const recId = brandRecId || rec_id;
        return (
            <>
                <ChooseButton type="submit" onClick={() => onBookingClick(recId, session)}>
                    {t('choose')}
                </ChooseButton>
            </>
        );
    };
    const renderButtonContainer = recommendation => {
        const { total_price, brandRecId } = recommendation;
        let actualTotalPrice = total_price;

        if (brandRecId) {
            const { rec_id } = recommendation;
            const actualBrandFare = _.find(brandFares[rec_id], { rec_id: brandRecId });

            actualTotalPrice = actualBrandFare.total_price;
        }

        return (
            <AmountContainer roundTrip={recommendation.routes.length > 1 ? 1 : 0}>
                <AmountBlock>
                    <Balance>{normalizePrice(actualTotalPrice[currency])}</Balance>
                    <Currency>{currency}</Currency>
                </AmountBlock>
                {renderSubmit(recommendation)}
            </AmountContainer>
        );
    };

    return (
        <Result>
            <RouteCard>
                {recommendation.routes.map((route, number) => renderRoute(recommendation, route, number))}
            </RouteCard>
            <DetailsCard roundTrip={recommendation.routes.length > 1 ? 1 : 0}>
                {recommendation.routes.map((route, number) => renderInfo(recommendation, route, number))}
            </DetailsCard>
        </Result>
    );
}

export default DesktopRecommendation;
