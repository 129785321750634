import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { B2C, DCD, EPA, PDC, SPT, UNT, UZC, VSL } from '../../../../containers/order/constants';
import milliCard from '../../../../assets/creditCards/kortMilli.png';
import { NotEnoughBalanceText, PaymentDescription, PaymentImgBG, PaymentMethodsBlock } from './components';
import { setPaymentMethod } from '../../../../store/orderSlice';
import { getImgFromUploads } from '../../../../lib/getImage';

function PaymentMethods(props) {
    const dispatch = useDispatch();
    const { t } = i18next;
    const { checkedPaymentMethod, methodData } = props;
    const { code, logo } = methodData;
    const { balance } = useSelector(state => state.authSettings);
    const { orderData } = useSelector(state => state.order);

    const PAYMENT_METHODS_DATA = [
        { code: B2C, namePaymentLabel: t('payment_with_dpt') },
        { code: SPT, namePaymentLabel: t('payment_with_spt') },
        { code: UNT, namePaymentLabel: t('payment_with_unt') },
        { code: UZC, namePaymentLabel: t('payment_with_uzc') },
        { code: VSL, namePaymentLabel: t('payment_with_vsl') },
        { code: EPA, namePaymentLabel: t.payment_with_vsl },
        { code: PDC, namePaymentLabel: t.payment_with_vsl },
        { code: DCD, namePaymentLabel: t.payment_with_vsl },
    ];

    const method = _.find(PAYMENT_METHODS_DATA, { code: code });

    if (_.isUndefined(method)) {
        return null;
    }

    const handelOnChange = () => {
        if (code !== checkedPaymentMethod) {
            if (code !== B2C) {
                dispatch(setPaymentMethod({ method: code }));
            }

            if (checkEnoughBalance() && code === B2C) {
                dispatch(setPaymentMethod({ method: code }));
            }
        }
    };

    const checkEnoughBalance = () => {
        return parseFloat(balance) > parseFloat(orderData.total_price);
    };

    const cardLogo = () => {
        if (method.code !== B2C) {
            return <PaymentImgBG src={logo ? getImgFromUploads(logo) : milliCard} />;
        } else {
            return <PaymentDescription>{t('payment_with_dpt')}</PaymentDescription>;
        }
    };

    return (
        <PaymentMethodsBlock onClick={handelOnChange} checked={checkedPaymentMethod === code}>
            {cardLogo()}

            {code === B2C && !checkEnoughBalance() && (
                <NotEnoughBalanceText>{t('not_enough_balance')}</NotEnoughBalanceText>
            )}
        </PaymentMethodsBlock>
    );
}

export default PaymentMethods;
