import { baseParams, basicInstance } from '../..';
import { mainConfigs } from '../../../../configs';

const formatCitiesResponse = data => {
    const result = [];

    for (const item of data) {
        result.push({
            code: item['item_code'],
            countryCode: item['country_code'],
            countryName: item['country'],
            value: item['item'],
            isMain: 1,
            haveAirports: item['airports'].length,
        });

        if (item['airports'].length > 1) {
            item['airports'].forEach(airport => {
                result.push({
                    code: airport['airport_code'],
                    countryCode: item['country_code'],
                    countryName: item['country'],
                    value: airport['airport'],
                    isMain: 0,
                    haveAirports: 0,
                });
            });
        }
    }

    return result;
};

export const getCities = async value => {
    const params = {
        ...baseParams,
        value,
        limit: mainConfigs.countSearchCity,
    };

    return basicInstance()
        .post('cities', params)
        .then(response => formatCitiesResponse(response.data.data))
        .catch(() => []);
};

export const getCountries = async value => {
    const params = {
        ...baseParams,
        value,
        limit: mainConfigs.countSearchCity,
    };

    return basicInstance()
        .post('countries', params)
        .then(response => response.data.data)
        .catch(() => []);
};
