import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuth: false,
    isLoaded: false,
    firstName: '',
    lastName: '',
    fatherName: '',
    login: '',
    email: '',
    phone: '',
    balance: '',
    currency: 'RUB',
};

export const authSettingsSlice = createSlice({
    name: 'authSettings',
    initialState,
    reducers: {
        setAuthSettings: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setAuthSettings } = authSettingsSlice.actions;

export default authSettingsSlice.reducer;
