import { baseParams, basicInstance } from '../..';
import envConfigs from '../../../../configs/envConfigs';

export const login = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .post('login', params)
        .then(response => response.data);
};

export const register = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .post('create', params)
        .then(response => response.data);
};

export const activation = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .post('activate', params)
        .then(response => response.data);
};

export const forgotPassword = async data => {
    const params = {
        ...baseParams,
        ...data,
        url: envConfigs.hostURL,
    };

    return basicInstance()
        .post('reset/send', params)
        .then(response => response.data)
        .catch(error => error.response.data);
};

export const resetPassword = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .post('/reset', params)
        .then(response => response.data)
        .catch(error => error.response.data);
};
