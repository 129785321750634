import _ from 'lodash';
import { mainConfigs } from '../../configs/mainConfigs';
import { ALL_COUNTRY, FRIENDLY_COUNTRY, ONLY_RU_COUNTRY, RU } from '../../containers/booking/constants';

export const getRoutesCountries = (routes, included) => {
    let airportList = [];

    _.map(routes, function (route) {
        _.map(route.segments, function (segment) {
            airportList = [...airportList, segment.arrival.airport, segment.departure.airport];
        });
    });

    airportList = _.uniq(airportList);

    return _.uniq(
        _.map(airportList, function (item) {
            return included.airport[item].country;
        })
    );
};

export const getFlightDocsType = routesCountries => {
    if (routesCountries.length === 1 && _.first(routesCountries) === RU) {
        return ONLY_RU_COUNTRY;
    }

    let isFriendly = true;

    _.forEach(routesCountries, function (item) {
        if (
            _.findIndex(mainConfigs.friendlyCountriesCodes, function (o) {
                return o === item;
            }) === -1
        ) {
            isFriendly = false;
        }
    });

    return isFriendly ? FRIENDLY_COUNTRY : ALL_COUNTRY;
};

export const normalizePhone = phone => {
    const formattedPhone = phone.replace(/[^\d]/g, '');

    return `+${formattedPhone}`;
};

export const getRouteCity = (route, included, language) => {
    const departureSegment = _.first(route.segments);
    const arrivalSegment = _.last(route.segments);
    const departureTime = departureSegment.departure.time;
    const departureCode = departureSegment.departure.city;
    const arrivalCode = arrivalSegment.arrival.city;
    const arrivalCity = included.city[arrivalCode].name[language];
    const departureCity = included.city[departureCode].name[language];

    return [departureCity, arrivalCity, departureTime];
};
