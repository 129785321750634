import { ORANGE_COLOR } from '../../../constants';
import { LoaderBlock, LoadingContainer, StyledCircularProgress } from './components';

const LoadingBlock = ({ withoutIcon, style }) => {
    if (withoutIcon) {
        const styleBlock = {
            color: `${ORANGE_COLOR}`,
            position: 'relative',
        };

        return <StyledCircularProgress style={style || styleBlock} size={24} />;
    }

    return (
        <LoadingContainer>
            <LoaderBlock />
        </LoadingContainer>
    );
};

export default LoadingBlock;
