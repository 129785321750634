import i18next from 'i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { normalizePrice } from '../../../../utils';
import {
    Bottom,
    LineDivider,
    PriceItem,
    PriceTitle,
    PriceValue,
    Top,
    TotalItem,
    TotalTitle,
    TotalValue,
    Wrapper,
} from './components';
import PassengersAmount from './passengersAmount';

const Price = () => {
    const { t } = i18next;
    const { total_price, passengers_data, currency } = useSelector(state => state.order.orderData);

    return (
        <Wrapper>
            <Top>
                <PriceItem>
                    <PriceTitle>{t('flights_tickets')}:</PriceTitle>
                    <PriceValue>
                        {normalizePrice(_.toFinite(total_price).toFixed(2))} <span>{currency}</span>
                    </PriceValue>
                </PriceItem>

                <PassengersAmount passengers={passengers_data} currency={currency} />
            </Top>

            <LineDivider />

            <Bottom>
                <TotalItem>
                    <TotalTitle>{t('total')}:</TotalTitle>
                    <TotalValue>
                        {normalizePrice(_.toFinite(total_price).toFixed(2))} <span>{currency}</span>
                    </TotalValue>
                </TotalItem>
            </Bottom>
        </Wrapper>
    );
};

export default Price;
