import _ from 'lodash';
import { baseParams, basicInstance } from '../..';

const formattedRoutesData = routes => {
    return routes.map(route => {
        const newRoute = { ...route };

        newRoute.segments = newRoute.segments.map(segment => {
            const newSegment = { ...segment };

            switch (newSegment['baggage']) {
                case _.isNull(newSegment['baggage']):
                    newSegment['baggage'] = 0;
                    break;
                case '0':
                case '0N':
                case '0 PC':
                case '0PC':
                case 'N/A':
                case 'NO':
                case '0 N/A':
                    newSegment['baggage'] = 0;
                    break;
                case '1':
                case '1P':
                case '1 PC':
                case '10K':
                case '10 KG':
                case '20 KG':
                case '23 KG':
                case '30 KG':
                case '35 KG':
                case '40 KG':
                case '1PC':
                    newSegment['baggage'] = 1;
                    break;
                case '2P':
                case '2 PC':
                    newSegment['baggage'] = 2;
                    break;
                default:
                    break;
            }

            switch (newSegment['hand_luggage']) {
                case _.isNull(newSegment['hand_luggage']):
                    newSegment['hand_luggage'] = 0;
                    break;
                case '0':
                case '0 PC':
                case '0PC':
                case 'N/A':
                case '0 N/A':
                    newSegment['hand_luggage'] = 0;
                    break;
                case '1':
                case '1P':
                case '1 PC':
                case '10K':
                case '5 KG':
                case '7 KG':
                case '8 KG':
                case '10 KG':
                case '1PC':
                    newSegment['hand_luggage'] = 1;
                    break;
                case '2':
                case '2P':
                    newSegment['hand_luggage'] = 2;
                    break;
                default:
                    break;
            }

            switch (newSegment['baggage_weight']) {
                case _.isNull(newSegment['baggage_weight']):
                    newSegment['baggage_weight'] = 0;
                    break;
                case '0 N/A':
                case 'N/A':
                    newSegment['baggage_weight'] = 0;
                    break;
                case '10 KG':
                    newSegment['baggage_weight'] = 10;
                    break;
                case '20 KG':
                    newSegment['baggage_weight'] = 20;
                    break;
                case '23 KG':
                    newSegment['baggage_weight'] = 23;
                    break;
                case '25 KG':
                    newSegment['baggage_weight'] = 25;
                    break;
                case '30 KG':
                    newSegment['baggage_weight'] = 30;
                    break;
                case '35 KG':
                    newSegment['baggage_weight'] = 35;
                    break;
                case '40 KG':
                    newSegment['baggage_weight'] = 40;
                    break;
            }

            switch (newSegment['hand_luggage_weight']) {
                case _.isNull(newSegment['hand_luggage_weight']):
                    newSegment['hand_luggage_weight'] = 0;
                    break;
                case '0 N/A':
                case '1 PC':
                case 'N/A':
                    newSegment['hand_luggage_weight'] = 0;
                    break;
                case '5 KG':
                    newSegment['hand_luggage_weight'] = 5;
                    break;
                case '7 KG':
                    newSegment['hand_luggage_weight'] = 7;
                    break;
                case '8 KG':
                    newSegment['hand_luggage_weight'] = 8;
                    break;
                case '10 KG':
                    newSegment['hand_luggage_weight'] = 10;
                    break;
            }

            return newSegment;
        });

        return newRoute;
    });
};

const searchResultFormat = result => {
    const { supplier } = result.included;

    result['flights'].forEach(recommendation => {
        const supplierData = Object.keys(supplier).map(
            key => supplier[key].iata === recommendation['validating_supplier']
        );
        recommendation['lowcost'] = supplierData ? supplierData.lowcost : false;

        formattedRoutesData(recommendation.routes);
    });

    return result;
};

const searchBrandFares = result => {
    result['flights'].forEach(recommendation => {
        formattedRoutesData(recommendation.routes);
    });

    return result;
};

export const getChangeBaggage = data => {
    const currentData = { ...data };
    currentData.routes = formattedRoutesData(currentData.routes);

    return currentData;
};

export const search = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .get('search', { params })
        .then(response => searchResultFormat(response.data.data));
};

export const takeBrandFares = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .post('brand_fares', params)
        .then(response => searchBrandFares(response.data.data))
        .catch(error => error.response.data);
};

export const prebook = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .post('prebook', params)
        .then(response => getChangeBaggage(response.data.data));
};
