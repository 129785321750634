import styled from "styled-components";
import { Container } from '../../ui/common' ;


export const MainContainer = styled(Container)`
    padding: 0 5px;
`;

export const OrdersTitle = styled.h2`
    font-weight: 500;
    text-align: center;
`;

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 40px;
    padding: 60px 0;

    @media (max-width: 1279px) {
        gap: calc(20px + (40 - 20) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (max-width: 899px) {
        flex-direction: column;
        gap: 20px;
        padding: 30px 0;
    }
`;

export const OrderMainBlock = styled.div`
    width: 1030px;
    order: 1;

    @media (min-width: 1280px) and (max-width: 1529px) {
        width: calc(880px + (1030 - 880) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (min-width: 900px) and (max-width: 1279px) {
        width: calc(600px + (880 - 600) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (max-width: 899px) {
        width: 100%;
        order: 2;
    }
`;

export const FilterMainBlock = styled.div`
    padding: 16px;
    width: 430px;
    height: 350px;
    order: 2;
    background-color: #fff;
    margin-top: 82px;
    box-shadow: 0px 0px 10px #00000033;

    @media (max-width: 1499px) {
        margin-top: 82px;
        width: calc(330px + (430 - 330) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (max-width: 1279px) {
        margin-top: 82px;
        width: calc(250px + (330 - 250) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (max-width: 899px) {
        width: 100%;
        order: 1;
    }
`;