import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import designSettingsReducer from './designSettingsSlice';
import currencySettingsReducer from './currencySettingsSlice';
import authSettingsReducer from './authSettingsSlice';
import mainLoadingReducer from './mainLoadingSlice';
import searchParamsReducer from './searchParamsSlice';
import ordersSliceReducer from './ordersSlice';
import resultDataReducer from './resultDataSlice';
import filtersReducer from './filtersSlice';
import loadingReducer from './loading';
import prebookReducer from './prebookSlice';
import orderReducer from './orderSlice';

export const store = configureStore({
    reducer: {
        designSettings: designSettingsReducer,
        currencySettings: currencySettingsReducer,
        authSettings: authSettingsReducer,
        mainLoading: mainLoadingReducer,
        searchParams: searchParamsReducer,
        ordersSlice: ordersSliceReducer,
        resultData: resultDataReducer,
        filters: filtersReducer,
        loading: loadingReducer,
        prebook: prebookReducer,
        order: orderReducer,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});
