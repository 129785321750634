import React, { Suspense, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainLoading from '../components/somon_air/mainLoading';

import { setDesignSettings } from '../store/designSettingsSlice';

const RegisterPage = () => {
    const RegistrationPage = React.lazy(() => import(`../components/somon_air/pages/registerPage`));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuth } = useSelector(state => state.authSettings);

    useEffect(() => {
        if (isAuth) {
            navigate('/');
        }

        dispatch(
            setDesignSettings({
                isStickyHeader: true,
                showSearchBlock: true,
                isSmallSearchBlock: true,
            })
        );
    }, []);

    return (
        <Suspense fallback={<MainLoading />}>
            <RegistrationPage />
        </Suspense>
    );
};

export default withTranslation()(RegisterPage);
