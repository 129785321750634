import i18next from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import noun from 'plural-ru';

import { formatDates, getAgeType, getDocumentType, getGender } from '../../../../result/common';
import { PassengerName, PassengerParamTitle, PassengerParamValue, PassengerRow, Wrapper } from './components';

const Passengers = () => {
    const { passengers_data } = useSelector(state => state.order.orderData);
    const { t } = i18next;

    return passengers_data.map((passenger, index) => {
        const age = moment().diff(moment(passenger.date_of_birth, 'YYYY-MM-DD'), 'years');

        let ageString;

        if (age > 0) {
            ageString = noun(age, '%d ' + t('year_one'), '%d ' + t('year_more'), '%d ' + t('years'));
        } else {
            const ageMonth = moment().diff(moment(passenger.date_of_birth, 'YYYY-MM-DD'), 'months');

            ageString = noun(ageMonth, '%d ' + t('month_one'), '%d ' + t('months_more'), '%d ' + t('months'));
        }

        return (
            <Wrapper key={index}>
                <PassengerName>
                    {passenger.name} {passenger.middle_name} {passenger.surname}
                </PassengerName>
                
                <PassengerRow>
                    <PassengerParamTitle>{t('document')}</PassengerParamTitle>
                    <PassengerParamValue>
                        {getDocumentType(passenger.document_type)}, {passenger.document_number}, {t('before')}{' '}
                        {formatDates(passenger.expiration_date)}
                    </PassengerParamValue>
                </PassengerRow>

                <PassengerRow>
                    <PassengerParamTitle>{t('citizenship')}</PassengerParamTitle>
                    <PassengerParamValue>{passenger.citizenship}</PassengerParamValue>
                </PassengerRow>

                <PassengerRow>
                    <PassengerParamTitle>{t('age')}</PassengerParamTitle>
                    <PassengerParamValue>
                        {ageString}, {getAgeType(passenger.type)}
                    </PassengerParamValue>
                </PassengerRow>

                <PassengerRow>
                    <PassengerParamTitle>{t('date_of_birth')}</PassengerParamTitle>
                    <PassengerParamValue>{formatDates(passenger.date_of_birth)}</PassengerParamValue>
                </PassengerRow>

                <PassengerRow>
                    <PassengerParamTitle>{t('gender')}</PassengerParamTitle>
                    <PassengerParamValue>{getGender(passenger.gender)}</PassengerParamValue>
                </PassengerRow>
            </Wrapper>
        );
    });
};

export default Passengers;
