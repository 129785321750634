import { Button, ButtonIcon, Buttons, Wrapper } from './components';
import prevBtn from '../../../assets/somon_air/icons/prev.svg';
import nextBtn from '../../../assets/somon_air/icons/next.svg';
const Paging = ({ currentPage, lastPage, setPage }) => {
    let paging = [];
    const limit = 3;
    const sidesLimit = 2;

    if (lastPage > 1) {
        if (currentPage > 1) {
            paging.push({ number: null, type: 'prev' });
        }
        if (lastPage > 7) {
            for (let i = 1; i <= lastPage; i++) {
                if (currentPage <= limit) {
                    if (i < 7 || i > lastPage - 1) {
                        paging.push({ number: i, type: i === currentPage ? 'active' : 'page' });
                    } else {
                        if (paging[paging.length - 1].type === 'empty') continue;
                        else {
                            paging.push({ number: null, type: 'empty' });
                        }
                    }
                } else if (currentPage > lastPage - 3) {
                    if (i < 2 || i > lastPage - 6) {
                        paging.push({ number: i, type: i === currentPage ? 'active' : 'page' });
                    } else {
                        if (paging[paging.length - 1].type === 'empty') continue;
                        else {
                            paging.push({ number: null, type: 'empty' });
                        }
                    }
                } else {
                    const min = currentPage - sidesLimit;
                    const max = currentPage + sidesLimit;
                    if (i < 2 || i > lastPage - 1 || (i >= min && i <= max)) {
                        paging.push({ number: i, type: i === currentPage ? 'active' : 'page' });
                    } else {
                        if (paging[paging.length - 1].type === 'empty') continue;
                        else {
                            paging.push({ number: null, type: 'empty' });
                        }
                    }
                }
            }
        } else {
            for (let i = 1; i <= lastPage; i++) {
                paging.push({ number: i, type: i === currentPage ? 'active' : 'page' });
            }
        }

        if (currentPage < lastPage) {
            paging.push({ number: null, type: 'next' });
        }
    } else {
        paging.push({ number: 1, type: 'active' });
    }

    const renderNextButton = index => (
        <Button key={`paging-button-${index}`} onClick={() => setPage(currentPage + 1)}>
            <ButtonIcon src={nextBtn} alt="right-black-icon" />
        </Button>
    );

    const renderPrevButton = index => (
        <Button key={`paging-button-${index}`} onClick={() => setPage(currentPage - 1)}>
            <ButtonIcon src={prevBtn} alt="left-black-icon" />
        </Button>
    );

    const renderPageButton = (number, index) => (
        <Button key={`paging-button-${index}`} onClick={() => setPage(number)}>
            {number}
        </Button>
    );

    const renderActiveButton = (number, index) => (
        <Button key={`paging-button-${index}`} className="active">
            {number}
        </Button>
    );

    const renderEmptyButton = index => <Button key={`paging-button-${index}`}>...</Button>;

    const renderPages = () => {
        return paging.map((value, index) => {
            switch (value.type) {
                case 'page':
                    return renderPageButton(value.number, index);
                case 'active':
                    return renderActiveButton(value.number, index);
                case 'empty':
                    return renderEmptyButton(index);
                case 'next':
                    return renderNextButton(index);
                case 'prev':
                    return renderPrevButton(index);
                default:
                    break;
            }
        });
    };

    return (
        <Wrapper>
            <Buttons>{renderPages()}</Buttons>
        </Wrapper>
    );
};

export default Paging;
