import i18next from 'i18next';
import React from 'react'; 
import { getBookStatus, getColorStatus, checkInProgressStatus } from '../../../../../protocols';
import { OrderStatusBlock, StatusString, StatusStringBlock, Span } from './components';


const BookingStatus = props => {
    const { bookingStatus, orderStatus, timelimit } = props;

    const isInProgress = checkInProgressStatus(bookingStatus, orderStatus);
    const statusColor = getColorStatus(bookingStatus);

    return (
        <OrderStatusBlock>
            {isInProgress ? (
                <InProgressTimelimit timelimit={timelimit} statusColor={statusColor} />
            ) : (
                <SomeStatusBlock bookingStatus={bookingStatus} orderStatus={orderStatus} statusColor={statusColor} />
            )}
        </OrderStatusBlock>
    );
};

const InProgressTimelimit = props => {
    const { t } = i18next;
    const { statusColor, timelimit } = props;

    return (
        <StatusString>
            {t('status')}:
            <StatusStringBlock statusColor={statusColor}>
                {timelimit < 0 ? t('time_expired') : <LeftTimelimitTime timelimitSeconds={timelimit} />}
            </StatusStringBlock>
        </StatusString>
    );
};

const SomeStatusBlock = props => {
    const { t } = i18next;
    const { bookingStatus, orderStatus, statusColor } = props;

    return (
        <StatusString>
            {t('status')}: 
            <StatusStringBlock statusColor={statusColor}>
                {getBookStatus(bookingStatus, orderStatus, t)}
            </StatusStringBlock>
        </StatusString>
    );
};

const LeftTimelimitTime = props => {
    const { t } = i18next;
    const { timelimitSeconds } = props;

    return (
        <span>
            {t('left')} {GetTimeLimitString(timelimitSeconds)}
        </span>
    );
};

const GetTimeLimitString = timelimitSecond => {
    const { t } = i18next;

    if (timelimitSecond < 60) {
        return <span>{t('less_than_minute')}</span>;
    }

    const minutes = Math.floor((timelimitSecond / 60) % 60);
    const hours = Math.floor((timelimitSecond / (60 * 60)) % 24);
    const days = Math.floor(timelimitSecond / (60 * 60 * 24));

    return (
        <span>
            {days > 0 && (
                <Span>
                    {days} {t('day_short')}
                </Span>
            )}
            {hours > 0 && (
                <Span>
                    {hours} {t('hour_short')}
                </Span>
            )}
            {minutes > 0 && (
                <Span>
                    {minutes} {t('minutes_short_3')}
                </Span>
            )}
        </span>
    );
};
    
    
export default BookingStatus;