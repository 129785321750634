import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show: true,
    searchLoading: true,
    searchData: null,
};

export const mainLoadingSlice = createSlice({
    name: 'mainLoading',
    initialState,
    reducers: {
        setMainLoadingData: (state, action) => {
            state = { ...state, ...action.payload };
        },
    },
});

export const { setMainLoadingData } = mainLoadingSlice.actions;

export default mainLoadingSlice.reducer;