import { Component, InputBlock, Label, Input, ErrorText } from './components';

const TextInput = ({
    value = null,
    onChange = () => null,
    error = null,
    label = null,
    required = false,
    placeholder = null,
    onBlur = () => null,
    touched = false,
    borderRadius = 5,
    boxShadow = false,
}) => {
    return (
        <Component>
            {label && (
                <Label marginBottom>
                    {label} {required && <span>*</span>}
                </Label>
            )}
            <InputBlock error={touched && error} borderRadius={borderRadius} boxShadow={boxShadow}>
                <Input value={value} onChange={onChange} placeholder={placeholder} type="file" onBlur={onBlur} />
            </InputBlock>
            {touched && error && <ErrorText>{error}</ErrorText>}
        </Component>
    );
};

export default TextInput;
