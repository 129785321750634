import styled from 'styled-components';
import {
    BRIGHT_COLOR,
    ORANGE_COLOR,
    GREY_DARK_COLOR,
    LIGHT_GREY_COLOR,
    PANTONE_COLOR,
    PINK_SWAN_COLOR,
    WHITE_COLOR,
    DARK_COLOR,
    GREY_GAINSBORO_COLOR,
    ZAMBEZI_COLOR,
    RHINO_COLOR,
} from '../../../../constants';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const HeaderDivider = styled.div`
    margin: 0 auto;
    width: 95%;
    height: 0px;
    border: 0.5px solid ${GREY_GAINSBORO_COLOR};
`;
export const Logo = styled.div`
    display: flex;
    justify-content: center;
    margin: 25px auto;
    height: 35px;
`;

export const Direction = styled.div`
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-family: Rubik;
    font-weight: 500;
    font-size: 15px;
    color: ${BRIGHT_COLOR};
    margin-bottom: 6px;
`;
export const TabsComponent = styled(Tabs)`
    & .MuiTabs-indicator {
        display: none;
    }
`;
export const TariffsTab = styled(Tab)`
    color: ${RHINO_COLOR};
    font-size: 14px;
    &.Mui-selected {
        color: ${ORANGE_COLOR};
        &.MuiTab-textColorPrimary {
            color: ${ORANGE_COLOR};
        }
    }
`;
export const TabContent = styled.div`
    padding: 8px 16px;
`;
export const RouteDest = styled.div`
    font-size: 18px;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    @media (max-width: 409px) {
        flex-direction: column;
    }
`;

export const FlightInfo = styled.div`
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Flight = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const TransfersArrow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 30%;
    height: 20px;
    position: relative;
    & > .circle {
        border: 2px solid;
        background-color: ${ZAMBEZI_COLOR};
        border-radius: 50%;
        height: 5px;
        width: 5px;
        top: 9.45px;
        position: absolute;
        z-index: 2;
    }

    & > .line {
        top: 11.4px;
        position: absolute;
        width: 100%;
        height: 0px;
        border: 1px solid ${ZAMBEZI_COLOR};
    }
    & > .circle-bottom {
        border: 2px solid;
        background-color: ${ZAMBEZI_COLOR};
        border-radius: 50%;
        height: 5px;
        width: 5px;
        right: 0px;
        top: 9.45px;
        position: absolute;
        z-index: 2;
    }
`;
export const SegmentBlock = styled.div`
    width: 100%;
    margin: 30px 0;
`;
export const Divider = styled.div`
    width: 100%;
    height: 0px;
    border: 1px solid ${ORANGE_COLOR};
`;

export const AirlineInfo = styled.div`
    margin-top: 11px;
    margin-bottom: -20px;
    text-align: center;
    font: italic normal 300 13px/15px Rubik;
    color: ${GREY_DARK_COLOR};
`;
export const TimeBlock = styled.div`
    margin-top: 27px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const TimeInfo = styled.div`
    height: 68px;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;
export const Line = styled.div`
    width: 30%;
    height: 1px;
    background-color: ${BRIGHT_COLOR};
`;
export const DateInfo = styled.div`
    color: ${GREY_DARK_COLOR};
    font-family: Rubik;
`;

export const Destination = styled.div`
    width: 30%;
    height: 3px;
    background-color: ${BRIGHT_COLOR};
`;

export const OrderInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const ClassInfo = styled.div`
    color: ${BRIGHT_COLOR};
    font: normal normal normal 14px/23px Rubik;
`;
export const AdditionalInfoIcons = styled.div`
    margin-top: 14px;
    display: flex;
    justify-content: center;
`;
export const IconContainer = styled.div`
    pointer-events: auto;
    position: relative;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-bottom: 19px;
    background: ${LIGHT_GREY_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px ${PANTONE_COLOR};
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Icon = styled.div`
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    width: 9px;
    height: 10px;
`;
export const AdditionalInfos = styled.div`
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font: normal normal normal 14px/17px Rubik;
    color: ${PINK_SWAN_COLOR};
`;
export const ChooseButton = styled.button`
    margin-bottom: 22px;
    width: 100%;
    height: 55px;
    background: ${ORANGE_COLOR} 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border-color: ${WHITE_COLOR};
    font-family: Raleway;
    font-weight: 600;
    font-size: 17px;
    color: ${DARK_COLOR};
    display: flex;
    align-items: center;
    justify-content: center;
`;
