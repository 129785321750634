import styled from 'styled-components';
import { Accordion } from '@mui/material';
import { DARK_ORANGE, MIDNIGHT_COLOR, ORANGE_COLOR } from '../../../../constants';

export const RulesBlockAccordion = styled(Accordion)`
    color: ${ORANGE_COLOR};
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 16px;
`;

export const SelectBlock = styled.div`
    margin-bottom: 16px;
`;

export const StyledPaper = styled.div`
    padding: 0 16px;
    max-height: 600px;
    border: 1px solid ${MIDNIGHT_COLOR};
    overflow: auto;

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px grey;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${ORANGE_COLOR};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${DARK_ORANGE};
    }
`;
