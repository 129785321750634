import axios from 'axios';
import envConfigs from '../../../configs/envConfigs';
import { mainConfigs } from '../../../configs';
import qs from 'qs';
import { storageService } from '../..';

const https = require('https');

const getToken = () => {
    const accessToken = storageService.getItem('token');

    if (accessToken) {
        return `${mainConfigs.tokenType} ${accessToken}`;
    }
};

export const baseParams = {
    company_req_id: envConfigs.companyRequisitesId,
    language: mainConfigs.language,
};

export const basicInstance = () =>
    axios.create({
        baseURL: envConfigs.apiHost,
        timeout: 500000,
        withCredentials: false,
        httpsAgent: new https.Agent({ keepAlive: true }),
        paramsSerializer: params => qs.stringify(params, { indices: true }),
    });

export const authorizedInstance = () =>
    axios.create({
        baseURL: envConfigs.apiHost,
        timeout: 100000,
        withCredentials: false,
        headers: {
            Authorization: getToken(),
        },
        paramsSerializer: params => qs.stringify(params, { indices: true }),
        httpsAgent: new https.Agent({ keepAlive: true }),
    });
