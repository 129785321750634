import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    flightType: 'RT',
    cabin: 'all',
    passengers: {
        adt: 1,
        chd: 0,
        ins: 0,
        inf: 0,
    },
    routes: [
        {
            from: null,
            to: null,
            date_from: null,
        },
        {
            from: null,
            to: null,
            date_from: null,
        },
    ],
    hasData: false,
    notFilledFields: {
        from: false,
        to: false,
        date: false,
    },
};

export const searchParamsSlice = createSlice({
    name: 'searchParams',
    initialState,
    reducers: {
        setSearchParams: (state, action) => {
            return { ...state, ...action.payload };
        },
        setFlightType: (state, action) => {
            return { ...state, flightType: action.payload };
        },
        setCabin: (state, action) => {
            return { ...state, cabin: action.payload };
        },
        setPassengers: (state, action) => {
            return { ...state, passengers: action.payload };
        },
        setRoutes: (state, action) => {
            return { ...state, routes: action.payload };
        },
        setHasData: (state, action) => {
            return { ...state, hasData: action.payload };
        },
        setNotFilledFields: (state, action) => {
            return { ...state, notFilledFields: action.payload };
        },
    },
});

export const { setSearchParams, setFlightType, setCabin, setPassengers, setRoutes, setHasData, setNotFilledFields } =
    searchParamsSlice.actions;

export default searchParamsSlice.reducer;
