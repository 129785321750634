import i18next from 'i18next';
import _ from 'lodash';

import Select from '../../../../ui/select';

const DocumentTypeField = props => {
    const { t } = i18next;

    return <Select {...props} items={props.availableDocumentTypes} />;
};

export default DocumentTypeField;
