import { createSlice } from '@reduxjs/toolkit';
import { mainConfigs } from '../configs/mainConfigs';

const initialState = {
    showHeader: true,
    showFooter: true,

    showSearchBlock: true,
    isStickySearchBlock: false,
    isSmallSearchBlock: false,
    isFullScreenSearchBlock: true,
    shouldMinimize: true,

    currency: mainConfigs.defaultWebCurrency,
    availableCurrencies: ['RUB', 'TJS', 'USD', 'UZS'],
    isAuth: false,
    logoUrl: null,
};

export const appConfigs = createSlice({
    name: 'appConfigs',
    initialState,
    reducers: {
        setAppConfigs: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setCurrency: (state, action) => {
            return {
                ...state,
                currency: action.payload,
            };
        },
    },
});
export const { setAppConfigs, setCurrency } = appConfigs.actions;

export default appConfigs.reducer;
