import i18next from 'i18next';
import _ from 'lodash';
import { ADT, CHD, getPassTypeCountString, INF, INS, normalizePrice } from '../../../../../utils';
import { PassengerAmountTitle, PassengerAmountValue, PassengerBlock, PassengerItem } from './components';

const PassengersAmount = ({ passengers, currency }) => {
    const { t } = i18next;

    const adtCount = _.filter(passengers, passenger => passenger.type === ADT).length;
    const chdCount = _.filter(passengers, passenger => passenger.type === CHD).length;
    const insCount = _.filter(passengers, passenger => passenger.type === INS).length;
    const infCount = _.filter(passengers, passenger => passenger.type === INF).length;

    let adtAmount = 0;
    let chdAmount = 0;
    let insAmount = 0;
    let infAmount = 0;

    if (adtCount > 0) {
        adtAmount = _.find(passengers, passenger => passenger.type === ADT).total_price;
    }

    if (chdCount > 0) {
        chdAmount = _.find(passengers, passenger => passenger.type === ADT).total_price;
    }

    if (insCount > 0) {
        insAmount = _.find(passengers, passenger => passenger.type === ADT).total_price;
    }

    if (infCount > 0) {
        infAmount = _.find(passengers, passenger => passenger.type === ADT).total_price;
    }

    if (_.isNull(adtAmount)) {
        return null;
    }

    return (
        <PassengerBlock>
            <PassengerItem>
                <PassengerAmountTitle>
                    {adtCount > 1 && adtCount} {getPassTypeCountString(adtCount, ADT)}
                </PassengerAmountTitle>
                <PassengerAmountValue>
                    {normalizePrice(adtAmount)} <span>{currency}</span> {adtCount > 1 && 'х' + adtCount}
                </PassengerAmountValue>
            </PassengerItem>

            {chdCount > 0 && (
                <PassengerItem>
                    <PassengerAmountTitle>
                        {chdCount > 1 && chdCount} {getPassTypeCountString(chdCount, CHD)}
                    </PassengerAmountTitle>
                    <PassengerAmountValue>
                        {normalizePrice(chdAmount)} <span>{currency}</span> {chdCount > 1 && 'х' + chdCount}
                    </PassengerAmountValue>
                </PassengerItem>
            )}

            {insCount > 0 && (
                <PassengerItem>
                    <PassengerAmountTitle>
                        {insCount > 1 && insCount} {getPassTypeCountString(insCount, INS)} (
                        {t('inf_with_seat_second_label')})
                    </PassengerAmountTitle>
                    <PassengerAmountValue>
                        {normalizePrice(insAmount)} <span>{currency}</span> {insCount > 1 && 'х' + insCount}
                    </PassengerAmountValue>
                </PassengerItem>
            )}

            {infCount > 0 && (
                <PassengerItem>
                    <PassengerAmountTitle>
                        {infCount > 1 && infCount} {getPassTypeCountString(infCount, INF)}
                    </PassengerAmountTitle>
                    <PassengerAmountValue>
                        {normalizePrice(infAmount)} <span>{currency}</span> {infCount > 1 && 'х' + infCount}
                    </PassengerAmountValue>
                </PassengerItem>
            )}
        </PassengerBlock>
    );
};

export default PassengersAmount;
