import {
    TimeInfo,
    InfoCard,
    DurationInfo,
    LocalTime,
    AirlineInfoBlock,
    SegmentInfo,
    DetailsTimeInfo,
    CityInfo,
    SegmentBlock,
    ResultContainer,
    Segment,
    Transfer,
    TransferSegment,
    TechStopInfo,
    Line,
    DetailsBlock,
} from './components';
import i18next from 'i18next';
import { AirlineLogo } from './components';
import { getCityAndAirportNames, getDurationTime, getServiceClass, getTechStops, getTransferTime } from '../../utils';
import { getSupplierIcon } from '../../../../../../lib/getImage';

function FlightDetails({ route, recommendation, included, routeIndex }) {
    const { t, language } = i18next;
    const { segments } = route;

    const renderFlightDetails = (segment, index) => {
        const { departure, service_class, arrival, carrier_number, aircraft, tech_stops, operation_supplier } = segment;
        const isTechStops = segment.tech_stops.length > 0;
        const isTransfer = index > 0;
        let aircraftName = aircraft;

        if (included.aircraft[aircraft]) {
            aircraftName = included.aircraft[aircraft].name.en;
        } else {
            aircraftName = '';
        }

        let transferTime = '';

        if (isTransfer) {
            const actualRoute = recommendation.routes[routeIndex];

            transferTime = getTransferTime(actualRoute, index, t('hour_short'), t('minutes_short_3'));
        }

        return (
            <ResultContainer>
                {isTransfer && (
                    <TransferSegment>
                        <TimeInfo>
                            <LocalTime>{t('local_time_message')}</LocalTime>
                        </TimeInfo>
                        <Transfer>
                            <Line />
                            {t('transfer')} {transferTime}
                        </Transfer>
                    </TransferSegment>
                )}
                <InfoCard key={index}>
                    <AirlineInfoBlock>
                        <AirlineLogo imgUrl={getSupplierIcon(operation_supplier)} />
                        <div style={{ fontFamily: 'Rubik' }}>
                            {t('flight')} <span>&nbsp;</span>
                            {carrier_number}, <span>&nbsp;</span>
                        </div>
                        <div style={{ fontFamily: 'Rubik' }}>
                            {aircraftName ? ` ${aircraftName},` : ''}
                            <span>&nbsp;</span>
                        </div>
                        <div style={{ fontFamily: 'Rubik' }}>{getServiceClass(service_class, t('class'))}</div>
                    </AirlineInfoBlock>
                    <Segment>
                        <TimeInfo>
                            <DurationInfo>
                                {getDurationTime(segment.duration, t('hour_short'), t('minutes_short_3'))}
                            </DurationInfo>
                        </TimeInfo>
                        <SegmentBlock>
                            <SegmentInfo>
                                <span className="circle" />
                                <span className="line" />
                                <DetailsBlock>
                                    <DetailsTimeInfo>{departure.time.split(' ')[1]}</DetailsTimeInfo>
                                    <DetailsTimeInfo>{departure.airport}</DetailsTimeInfo>
                                </DetailsBlock>

                                <CityInfo>
                                    {getCityAndAirportNames(included.city, included.airport, departure, language)}
                                </CityInfo>
                                <span className="circle-bottom" />
                            </SegmentInfo>
                            <TechStopInfo>
                                {isTechStops && (
                                    <div>
                                        {getTechStops(
                                            tech_stops,
                                            included.city,
                                            language,
                                            t('hour_short'),
                                            t('minutes_short_3'),
                                            t('teh_stop')
                                        )}
                                    </div>
                                )}
                            </TechStopInfo>
                            <SegmentInfo>
                                <DetailsBlock>
                                    <DetailsTimeInfo>{arrival.time.split(' ')[1]}</DetailsTimeInfo>
                                    <DetailsTimeInfo>{arrival.airport}</DetailsTimeInfo>
                                </DetailsBlock>

                                <CityInfo>
                                    {getCityAndAirportNames(included.city, included.airport, arrival, language)}
                                </CityInfo>
                            </SegmentInfo>
                        </SegmentBlock>
                    </Segment>
                </InfoCard>
            </ResultContainer>
        );
    };
    return <>{segments.map((segment, index) => renderFlightDetails(segment, index))}</>;
}

export default FlightDetails;
