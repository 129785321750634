import { GREY_COLOR, RED_COLOR } from '../../constants';
import { InputGroup, Label, InputBlock, IconBlock, InputElement, Error, MaskedInputElement } from './components';

const Input = ({
    value,
    onChange = () => null,
    onBlur = () => null,
    onClick = () => null,
    onFocus = () => null,
    type = 'text',
    placeholder = null,
    required = false,
    mask = null,
    normalize = null,

    label = null,
    icon = null,
    borderColor = GREY_COLOR,
    borderRadius = 5,
    boxShadow = false,
    touched = false,
    error = null,
    autoFocus = false,
    overflowHidden = false,
    disabled,
}) => {
    const handleChange = e => {
        if (normalize) {
            onChange(normalize(e.target.value));
        } else {
            onChange(e.target.value);
        }
    };

    return (
        <InputGroup>
            {label && (
                <Label>
                    {label} {required && <span>*</span>}
                </Label>
            )}
            <InputBlock
                borderColor={touched && error ? RED_COLOR : borderColor}
                borderRadius={borderRadius}
                boxShadow={boxShadow}
            >
                {icon && <IconBlock>{icon}</IconBlock>}
                {mask ? (
                    <MaskedInputElement
                        value={value}
                        onChange={handleChange}
                        placeholder={placeholder}
                        type={type}
                        onBlur={onBlur}
                        onClick={onClick}
                        onFocus={onFocus}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        mask={mask}
                        overflowHidden={overflowHidden}
                    />
                ) : (
                    <InputElement
                        value={value}
                        onChange={handleChange}
                        placeholder={placeholder}
                        type={type}
                        onBlur={onBlur}
                        onClick={onClick}
                        onFocus={onFocus}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        overflowHidden={overflowHidden}
                    />
                )}
            </InputBlock>
            {touched && error && <Error>{error}</Error>}
        </InputGroup>
    );
};

export default Input;
