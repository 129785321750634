import styled from 'styled-components';
import { BRIGHT_COLOR, WHITE_COLOR, ORANGE_COLOR, GREY_DARK_COLOR, MIDNIGHT_COLOR } from '../../../../constants';

export const Wrapper = styled.div`
    overflow: hidden;
    display: none;
    position: relative;

    @media screen and (max-width: 899px) {
        display: unset;
        text-align: center;
    }
    @media (max-width: 599px) {
    }
`;
export const Card = styled.div`
    padding: 10px;
    max-width: 899px;
    min-height: 100px;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px ${MIDNIGHT_COLOR};
    display: flex;
    justify-content: space-between;
    @media (max-width: 599px) {
    }
`;

export const FlightInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;
export const AmountBlock = styled.div`
    display: flex;
`;
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const Balance = styled.div`
    margin-bottom: 15px;
    font-family: Rubik;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: 0px;
    color: ${ORANGE_COLOR};
    @media (max-width: 899px) {
        font-size: 30px;
    }
`;
export const Currency = styled.div`
    margin-top: 15px;
    margin-left: 5px;
    height: 46px;
    font-family: Rubik;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    @media (max-width: 899px) {
        font-size: 20px;
        margin-top: 9px;
    }
`;

export const TimeBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;
export const RouteInfo = styled.div`
    font-family: Rubik;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
export const CityCode = styled.div`
    font-size: 15px;
    color: ${GREY_DARK_COLOR};
`;
export const Time = styled.div`
    font-weight: 500;
    color: ${BRIGHT_COLOR};
    font-family: Rubik;
    text-align: left;
    font-size: 15px;
`;
export const DurationInfo = styled.div`
    color: ${BRIGHT_COLOR};
    margin-left: 15px;
`;
export const TotalDurationInfo = styled.div`
    display: flex;
    color: ${BRIGHT_COLOR};
    margin-left: 15px;
    text-transform: none;
    @media (max-width: 409px) {
        margin: 10px 0;
    }
`;
export const Info = styled.div`
    font-size: 15px;
    color: ${GREY_DARK_COLOR};
`;
export const TotalInfo = styled.div`
    font-size: 15px;
    color: ${GREY_DARK_COLOR};

    margin-right: 5px;
`;
export const Duration = styled.div`
    font-weight: 500;
    color: ${BRIGHT_COLOR};
    font-family: Rubik;
    font-size: 16px;
    text-transform: none;
    @media (max-width: 399px) {
        font-size: 15px;
    }
`;
export const TotalDuration = styled.div`
    font-weight: 500;
    color: ${BRIGHT_COLOR};
    font-family: Rubik;
    font-size: 16px;
`;
export const Transfer = styled.div`
    color: ${BRIGHT_COLOR};
    font-size: 15px;
`;

export const TransferCity = styled.div`
    font-family: Rubik;
    font-weight: 500;
    color: ${BRIGHT_COLOR};
`;
export const OrderInfo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;

    margin-top: ${props => (props.transfer ? '0' : '18px')};
    margin-left: 5px;
`;
export const Logo = styled.img`
    width: 84px;
    height: 30px;
`;
