import { createSlice } from '@reduxjs/toolkit';
import { handleFilterParamsChange } from '../lib/filters';
import { BY_MIN_PRICE } from '../containers/result/constants';

export const initialState = {
    filterParams: {
        baggage: {
            isActive: false,
            values: {
                withBaggage: true,
                withoutBaggage: true,
            },
        },

        transfers: {
            isActive: false,
            values: {
                withoutTransfers: true,
                oneTransfer: true,
                manyTransfers: true,
            },
        },

        sorting: {
            isActive: false,
            value: BY_MIN_PRICE,
        },

        airlines: {
            isActive: false,
            values: [],
        },

        flightNumbers: {
            isActive: false,
            values: [],
        },

        price: {
            isActive: false,
            values: {
                min: 0,
                max: 100,
            },
        },

        airports: {
            writable: true,
            isActive: false,
            values: [], // { routeIndex: 0, departures: ['LED', 'DYU'], arrivals: ['LED', 'DME'] }
        },

        duration: {
            isActive: false,
            values: [], // { routeIndex: 0, values: { min: 0, max: 100 } }
        },

        departureTime: {
            isActive: false,
            values: [], // { routeIndex: 0, values: { min: 0, max: 100 } }
        },

        arrivalTime: {
            writable: true,
            isActive: false,
            values: [], // { routeIndex: 0, values: { min: 0, max: 100 } }
        },

        pricesTable: {
            isActive: false,
            values: {
                type: null,
                supplier: null,
                price: null,
            },
        },
    },

    filtersData: {
        priceRange: {
            min: 0,
            max: 100,
        },
        durationRange: {
            min: null,
            max: null,
        },
        routesData: [],
    },
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            handleFilterParamsChange(action.payload);

            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    [action.payload.type]: action.payload.values,
                },
            };
        },
        setAllFilterParams: (state, action) => {
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    ...action.payload,
                },
            };
        },
        setFiltersData: (state, action) => {
            return {
                ...state,
                filtersData: {
                    ...state.filtersData,
                    ...action.payload,
                },
            };
        },
        resetAllFiltersParams: state => {
            return {
                ...state,
                filterParams: initialState.filterParams,
            };
        },
    },
});
export const { setFilterParams, setAllFilterParams, setFiltersData, resetAllFiltersParams } = filtersSlice.actions;

export default filtersSlice.reducer;
