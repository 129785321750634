import { authorizedInstance, baseParams } from '../..';

export const book = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return authorizedInstance()
        .post('book', params)
        .then(response => response.data);
};
