import { ExpandMore } from '@mui/icons-material';
import i18next from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import envConfigs from '../../../../../configs/envConfigs';
import { ADT, CHD, INF, INS } from '../../../../../utils';
import { FRIENDLY_COUNTRY, NP, ONLY_RU_COUNTRY, PS, PSP, RU, SR } from '../../../../../utils/constants/booking';
import Input from '../../../ui/input';
import CitizenshipField from './citizenshipField';

import { AccordionBody, AccordionComponent, AccordionHeader, FormWrapper, InputBlock } from './components';
import DocumentTypeField from './documentTypeField';
import GenderField from './genderField';

const PassengerItem = ({ passenger, index, form }) => {
    const { errors, touched, handleChange, handleBlur, setFieldValue, setValues } = form;
    const { t } = i18next;
    const { flight_type } = useSelector(state => state.prebook);

    const passengersItems = {
        [ADT]: { mainLabel: t('adt_main_label') },
        [CHD]: { mainLabel: t('chd_main_label') },
        [INS]: { mainLabel: `${t('inf_main_label')} (${t('inf_with_seat_second_label')})` },
        [INF]: { mainLabel: t('inf_main_label') },
    };

    const allDocuments = [
        { value: PS, label: t('russian_passport') },
        { value: SR, label: t('birth_certificate') },
        { value: PSP, label: t('international_passport') },
        { value: NP, label: t('foreign_document') },
    ];

    const [isShowMiddleName, setIsShowMiddleName] = useState(
        passenger.document_type !== PS && passenger.document_type !== SR
    );
    const [availableDocumentTypes, setAvailableDocumentTypes] = useState(
        passenger.document_items.map(item => allDocuments.find(value => value.value === item))
    );

    const handleChangeCitizenship = code => {
        setFieldValue(`passengers[${index}].document_number`, '');
        setFieldValue(`passengers[${index}].expiration_date`, '');
        
        const passengerType = passenger.type;

        let actualDocument = PSP;
        let actualAvailableDocumentTypes;

        if (code === RU) {
            setIsShowMiddleName(true);

            if (flight_type === ONLY_RU_COUNTRY) {
                actualAvailableDocumentTypes = allDocuments.filter(doc =>
                    passengerType === ADT ? doc.value !== NP : doc.value !== NP && doc.value !== PS
                );

                setAvailableDocumentTypes(actualAvailableDocumentTypes);

                actualDocument = passengerType === ADT ? PS : SR;
            } else if (flight_type === FRIENDLY_COUNTRY) {
                actualAvailableDocumentTypes = allDocuments.filter(doc =>
                    passengerType === ADT ? doc.value !== NP && doc.value !== SR : doc.value !== PSP
                );

                setAvailableDocumentTypes(actualAvailableDocumentTypes);

                actualDocument = passengerType === ADT ? PS : PSP;
            } else {
                actualAvailableDocumentTypes = allDocuments.filter(doc => doc.value === PSP);

                setAvailableDocumentTypes(actualAvailableDocumentTypes);
            }

            setFieldValue(`passengers[${index}].document_type`, actualDocument);
        } else {
            setIsShowMiddleName(false);
            setFieldValue(`passengers[${index}].middle_name`, '');

            actualAvailableDocumentTypes = allDocuments.filter(doc => doc.value === NP);
            setAvailableDocumentTypes(actualAvailableDocumentTypes);
            setFieldValue(`passengers[${index}].document_type`, NP);
        }

        setFieldValue(`passengers[${index}].citizenship`, code);
    };

    return (
        <AccordionComponent defaultExpanded disabled={false}>
            <AccordionHeader expandIcon={<ExpandMore />}>{passengersItems[passenger.type].mainLabel}</AccordionHeader>

            <AccordionBody>
                <FormWrapper>
                    <InputBlock>
                        <Input
                            value={passenger.surname}
                            touched={touched?.passengers && touched?.passengers[index]?.surname}
                            error={
                                touched?.passengers &&
                                touched?.passengers[index]?.surname &&
                                errors?.passengers &&
                                errors?.passengers[index]?.surname
                            }
                            onChange={handleChange(`passengers[${index}].surname`)}
                            onBlur={handleBlur(`passengers[${index}].surname`)}
                            placeholder={t('surname_as_in_document')}
                            label={t('surname_as_in_document')}
                            normalize={value => value.toUpperCase()}
                            boxShadow
                            required
                        />
                    </InputBlock>

                    <InputBlock>
                        <Input
                            value={passenger.name}
                            touched={touched?.passengers && touched?.passengers[index]?.name}
                            error={
                                touched?.passengers &&
                                touched?.passengers[index]?.name &&
                                errors?.passengers &&
                                errors?.passengers[index]?.name
                            }
                            onChange={handleChange(`passengers[${index}].name`)}
                            onBlur={handleBlur(`passengers[${index}].name`)}
                            placeholder={t('name_as_in_document')}
                            label={t('name_as_in_document')}
                            normalize={value => value.toUpperCase()}
                            boxShadow
                            required
                        />
                    </InputBlock>

                    <InputBlock removeOnMobile={isShowMiddleName ? 0 : 1}>
                        {isShowMiddleName && (
                            <Input
                                value={passenger.middle_name}
                                touched={touched?.passengers && touched?.passengers[index]?.middle_name}
                                error={
                                    touched?.passengers &&
                                    touched?.passengers[index]?.middle_name &&
                                    errors?.passengers &&
                                    errors?.passengers[index]?.middle_name
                                }
                                onChange={handleChange(`passengers[${index}].middle_name`)}
                                onBlur={handleBlur(`passengers[${index}].middle_name`)}
                                placeholder={t('middlename_as_in_document')}
                                label={t('middlename_as_in_document')}
                                normalize={value => value.toUpperCase()}
                                boxShadow
                                required
                            />
                        )}
                    </InputBlock>

                    <InputBlock>
                        <CitizenshipField
                            touched={touched?.passengers && touched?.passengers[index]?.citizenship}
                            error={
                                touched?.passengers &&
                                touched?.passengers[index]?.citizenship &&
                                errors?.passengers &&
                                errors?.passengers[index]?.citizenship
                            }
                            onChange={handleChangeCitizenship}
                            onBlur={handleBlur(`passengers[${index}].citizenship`)}
                            clearFieldError={() => form.setFieldError(`passengers[${index}].citizenship`, '')}
                        />
                    </InputBlock>

                    <InputBlock>
                        <GenderField
                            value={passenger.gender}
                            touched={touched?.passengers && touched?.passengers[index]?.gender}
                            error={
                                touched?.passengers &&
                                touched?.passengers[index]?.gender &&
                                errors?.passengers &&
                                errors?.passengers[index]?.gender
                            }
                            onChange={handleChange(`passengers[${index}].gender`)}
                            onBlur={handleBlur(`passengers[${index}].gender`)}
                            placeholder={t('gender')}
                            label={t('gender')}
                            boxShadow
                            required
                        />
                    </InputBlock>

                    <InputBlock>
                        <Input
                            value={passenger.date_of_birth}
                            touched={touched?.passengers && touched?.passengers[index]?.date_of_birth}
                            error={
                                touched?.passengers &&
                                touched?.passengers[index]?.date_of_birth &&
                                errors?.passengers &&
                                errors?.passengers[index]?.date_of_birth
                            }
                            onChange={handleChange(`passengers[${index}].date_of_birth`)}
                            onBlur={handleBlur(`passengers[${index}].date_of_birth`)}
                            placeholder={t('date_of_birth')}
                            label={t('date_of_birth')}
                            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            boxShadow
                            required
                        />
                    </InputBlock>

                    <InputBlock>
                        <DocumentTypeField
                            value={passenger.document_type}
                            availableDocumentTypes={availableDocumentTypes}
                            touched={touched?.passengers && touched?.passengers[index]?.document_type}
                            error={
                                touched?.passengers &&
                                touched?.passengers[index]?.document_type &&
                                errors?.passengers &&
                                errors?.passengers[index]?.document_type
                            }
                            onChange={handleChange(`passengers[${index}].document_type`)}
                            onBlur={handleBlur(`passengers[${index}].document_type`)}
                            placeholder={t('document')}
                            label={t('document')}
                            boxShadow
                            required
                        />
                    </InputBlock>

                    <InputBlock>
                        <Input
                            value={passenger.document_number}
                            touched={touched?.passengers && touched?.passengers[index]?.document_number}
                            error={
                                touched?.passengers &&
                                touched?.passengers[index]?.document_number &&
                                errors?.passengers &&
                                errors?.passengers[index]?.document_number
                            }
                            onChange={handleChange(`passengers[${index}].document_number`)}
                            onBlur={handleBlur(`passengers[${index}].document_number`)}
                            placeholder={t('series_and_number')}
                            label={t('series_and_number')}
                            boxShadow
                            required
                        />
                    </InputBlock>

                    <InputBlock
                        removeOnMobile={passenger.document_type !== PS && passenger.document_type !== SR ? 0 : 1}
                    >
                        {passenger.document_type !== PS && passenger.document_type !== SR && (
                            <Input
                                value={passenger.expiration_date}
                                touched={touched?.passengers && touched?.passengers[index]?.expiration_date}
                                error={
                                    touched?.passengers &&
                                    touched?.passengers[index]?.expiration_date &&
                                    errors?.passengers &&
                                    errors?.passengers[index]?.expiration_date
                                }
                                onChange={handleChange(`passengers[${index}].expiration_date`)}
                                onBlur={handleBlur(`passengers[${index}].expiration_date`)}
                                placeholder={t('valid_until')}
                                label={t('valid_until')}
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                boxShadow
                                required
                            />
                        )}
                    </InputBlock>

                    <InputBlock removeOnMobile={passenger.type === ADT ? 0 : 1}>
                        {passenger.type === ADT && (
                            <Input
                                value={passenger.email}
                                touched={touched?.passengers && touched?.passengers[index]?.email}
                                error={
                                    touched?.passengers &&
                                    touched?.passengers[index]?.email &&
                                    errors?.passengers &&
                                    errors?.passengers[index]?.email
                                }
                                onChange={handleChange(`passengers[${index}].email`)}
                                onBlur={handleBlur(`passengers[${index}].email`)}
                                placeholder={t('email')}
                                label={t('email')}
                                boxShadow
                                required
                            />
                        )}
                    </InputBlock>

                    <InputBlock removeOnMobile={passenger.type === ADT ? 0 : 1}>
                        {passenger.type === ADT && (
                            <Input
                                value={passenger.phone}
                                touched={touched?.passengers && touched?.passengers[index]?.phone}
                                error={
                                    touched?.passengers &&
                                    touched?.passengers[index]?.phone &&
                                    errors?.passengers &&
                                    errors?.passengers[index]?.phone
                                }
                                onChange={handleChange(`passengers[${index}].phone`)}
                                onBlur={handleBlur(`passengers[${index}].phone`)}
                                placeholder={t('phone_number')}
                                label={t('phone_number')}
                                mask={envConfigs.phoneMask}
                                boxShadow
                                required
                            />
                        )}
                    </InputBlock>

                    <InputBlock removeOnMobile={1} />
                </FormWrapper>
            </AccordionBody>
        </AccordionComponent>
    );
};

export default PassengerItem;
