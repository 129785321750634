import { authorizedInstance, baseParams } from '../..';

export const getUserInfo = async () => {
    return authorizedInstance()
        .get('auth/clients', baseParams)
        .then(response => response.data);
};

export const editProfile = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return authorizedInstance()
        .post('profile/edit', params)
        .then(response => response.data)
        .catch(error => error.response.data);
};
