import styled from 'styled-components';
import { BRIGHT_COLOR, RUBIK_FONT, ZAMBEZI_COLOR } from '../../../constants';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
`;

export const Title = styled.h3`
    margin: 0;
    margin-bottom: 25px;
    font-size: 35px;
    font-weight: bold;
    color: ${BRIGHT_COLOR};
    text-align: center;

    @media (max-width: 899px) {
        font-size: 25px;
    }
`;

export const Description = styled.h4`
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 20px;

    & > svg {
        margin-right: 10px;
        min-width: 22px;
    }

    & > span {
        font-family: ${RUBIK_FONT};
        font-size: 17px;
        font-weight: 400;
        color: ${ZAMBEZI_COLOR};

        @media (max-width: 899px) {
            font-size: 14px;
        }
    }
`;

export const FormBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    @media (max-width: 599px) {
        grid-template-columns: 1fr;
        gap: 20px;
    }
`;

export const InputBlock = styled.div`
    flex-grow: 1;
`;
