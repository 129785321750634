import styled from 'styled-components';
import { DARK_GREY_COLOR, NERO_COLOR } from '../../../../../constants';

export const BrandFareInfoItem = styled.div`
    padding-top: 6px;
    font-size: 16px;
    color: ${props => (props.color ? `${NERO_COLOR}` : `${DARK_GREY_COLOR}`)};
`;

export const BrandOptionIcon = styled.div`
    display: inline-block;
`;

export const BrandOptionTitle = styled.div`
    display: inline-block;
    position: relative;
    bottom: 4px;
    margin-left: 6px;
`;
