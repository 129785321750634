import { useContext, useState } from 'react';
import i18next from 'i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { SnackbarContext } from '../../../../../contexts/snackbarContext';
import { Item, Title, Label, ClearButton, Button, DateBlockContainer, DateItem, Input, ButtonBlock } from './components';
import { CircularProgress, MenuItem, InputLabel, FormGroup } from '@mui/material';
import { Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ALL_ORDERS_CODE, BOOK_DONE_CODE, CANCEL_DONE_CODE, ERROR_ORDER_CODE, PARTIAL_REFUND_DONE_CODE, PAY_DONE_CODE, REFUND_DONE_CODE, TICKET_DONE_CODE, VOID_DONE_CODE, WAIT_REFUND_CODE } from '../../../../../utils/constants';
import { getOrdersList } from '../../../../../protocols';
import { setOrdersData } from '../../../../../store/ordersSlice';

const Filters = () => {
    const { t } = i18next;

    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useContext(SnackbarContext);
    const dispatch = useDispatch();

    const validationSchemaReset = Yup.object().shape({
        searchTerm: Yup.string().min(6, t('min_6_symbol')),
        bookingStatus: Yup.string().min(3, t('min_6_symbol')),
        timestampStart: Yup.string().min(4, t('min_6_symbol')),
        timestampEnd: Yup.string().min(4, t('min_6_symbol'))
    });

    const handleClear = () => {
        resetForm();
    }

    const handleActivateFormSubmit = values => {
        if (isLoading) {
            return;
        }

        getOrdersList(values)
            .then(response => {
                dispatch(
                    setOrdersData({
                        ordersData: response
                    })
                );
            });

        setIsLoading(true);

    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            searchTerm: '',
            bookingStatus: '',
            timestampStart: '',
            timestampEnd: ''
        },
        validationSchema: validationSchemaReset,
        onSubmit: handleActivateFormSubmit,
        validateOnBlur: true,
    });

    const items = [
        { value: ALL_ORDERS_CODE, label: t('all_orders_label') },
        { value: BOOK_DONE_CODE, label: t('book_done_label') },
        { value: PAY_DONE_CODE, label: t('pay_done_label') },
        { value: TICKET_DONE_CODE, label: t('ticket_done_label') },
        { value: CANCEL_DONE_CODE, label: t('cancel_done_label') },
        { value: WAIT_REFUND_CODE, label: t('wait_refund_label') },
        { value: REFUND_DONE_CODE, label: t('refund_done_label') },
        { value: PARTIAL_REFUND_DONE_CODE, label: t('partial_refund_done_label') },
        { value: VOID_DONE_CODE, label: t('void_done_label') },
        { value: ERROR_ORDER_CODE, label: t('error_order_label') },
    ]

    return (
        <>
                <Item>
                    <Input
                        value={values.searchTerm}
                        onChange={handleChange('searchTerm')}
                        type="text"
                        error={errors.searchTerm}
                        placeholder="Введите Фамилию, локатор или город"
                        onBlur={handleBlur('searchTerm')}
                        touched={touched.searchTerm}
                    />
                </Item>
                <Item>
                    <FormGroup>
                        <InputLabel id="choose-label-id">{t('choose')}</InputLabel>
                        <Select labelId="choose-label-id" value={values.bookingStatus} onChange={handleChange('bookingStatus')} placeholder={t('choose')}>
                            {items.map(item => (
                                <MenuItem value={item.value} key={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormGroup>
                </Item>
                <DateBlockContainer>

                    <DateItem>
                        <Input
                            value={values.timestampStart}
                            onChange={handleChange('timestampStart')}
                            error={errors.timestampStart}
                            placeholder={t('date_create_from')}
                            onBlur={handleBlur('timestampStart')}
                            touched={touched.timestampStart}
                            overflowHidden={true}
                        />
                    </DateItem>
                    <DateItem>
                        <Input
                            value={values.timestampEnd}
                            onChange={handleChange('timestampEnd')}
                            error={errors.timestampEnd}
                            placeholder={t('date_create_to')}
                            onBlur={handleBlur('timestampEnd')}
                            touched={touched.timestampEnd}
                            overflowHidden={true}
                        />
                    </DateItem>
                </DateBlockContainer>
                <ButtonBlock>
                    <ClearButton onClick={handleClear} type="submit">
                        {t('clear')}
                    </ClearButton>
                    <Button onClick={handleSubmit} type="submit">
                        {isLoading ? <CircularProgress color="inherit" size={26} /> : `${t('search')}`}
                    </Button>
                </ButtonBlock>
                </>
    );
};

export default Filters;