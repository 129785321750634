import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

export default function TooltipUI(props) {
    const { title, children } = props;
    if (_.isNull(title)) {
        return <div>{children}</div>;
    }

    return (
        <LightTooltip title={title} placement="top" TransitionComponent={Zoom}>
            {children}
        </LightTooltip>
    );
}

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#ffffff',
        color: '#212121',
        fontSize: '11px',
        marginBottom: '5px',
        textAlign: 'center',
    },
}))(Tooltip);

TooltipUI.defaultProps = {
    title: null,
};
