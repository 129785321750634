import styled from 'styled-components';
import { LIGHT_GREY_COLOR, PANTONE_COLOR } from '../../../constants';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Icon = styled.span`
    cursor: pointer;
    pointer-events: auto;
    position: relative;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin-right: 8px;
    margin-bottom: ${props => props.marginBottom}px;
    background: ${LIGHT_GREY_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px ${PANTONE_COLOR};
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-last-of-type(1) {
        margin-right: 0;
    }
`;
