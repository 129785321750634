import styled from 'styled-components';
import { BRIGHT_COLOR, WHITE_COLOR } from '../../constants';

export const Wrapper = styled.div`
    width: 870px;
    margin: 15px auto;
    padding: 15px;
    background: ${WHITE_COLOR};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 899px) {
        width: 100%;
        margin: 15px 0;
    }
`;

export const Title = styled.h3`
    margin: 25px 0 40px;
    text-align: center;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    font-size: 24px;
    font-weight: bold;

    @media (max-width: 899px) {
        font-size: 20px;
    }
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 570px;
    margin-bottom: 30px;

    @media (max-width: 899px) {
        justify-content: center;
        margin-bottom: 20px;
    }

    @media (max-width: 599px) {
        width: 100%;
    }
`;

export const PassengersTitle = styled.h2`
    text-align: center;
    color: #404b5a;
    margin: 32px 0;
`;

export const PassengerItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 570px;
    margin-bottom: 30px;

    @media (max-width: 899px) {
        justify-content: center;
        margin-bottom: 20px;
    }

    @media (max-width: 599px) {
        width: 100%;
    }
`;

export const PassengerRemoveBlock = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const PassengerRemoveButton = styled.button`
    color: #ff0000;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
`;

export const PassengerFormInput = styled.div`
    margin-bottom: 20px;
`;

export const PassengerAddButtonBlock = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const PassengerAddButton = styled.button`
    padding: 12px 24px;
    font-size: 14px;
    font-weight: bold;
    background-color: #e4e9f3;
    color: #404b5a;
    border: none;
    border-radius: 12px;
    cursor: pointer;
`;

export const SubmitButtonBlock = styled.div`
    width: 300px;
`;
