import { useDispatch, useSelector } from 'react-redux';
import { setFilterParams } from '../../../../../../../store/filtersSlice';
import FormCheckbox from '../../../../../../ui/FormCheckbox';
import { CheckboxBlock, Label } from './components';
import FilterBlock from '../filterBlock';
import i18next from 'i18next';

const AirlinesFilter = () => {
    const { t, language } = i18next;
    const dispatch = useDispatch();
    const { filterParams } = useSelector(state => state.filters);
    const { isActive, values } = filterParams.airlines;
    const { included } = useSelector(state => state.resultData);
    const { supplier } = included;
    const airlines = Object.keys(supplier).map(key => supplier[key]);

    const handleChange = (item, value) => {
        let newAirlineFilter = [];

        if (!value) {
            newAirlineFilter = values.filter(val => {
                return item.iata !== val;
            });
        } else {
            newAirlineFilter = [...values, item.iata];
        }

        dispatch(
            setFilterParams({
                type: 'airlines',
                values: {
                    isActive: newAirlineFilter.length > 0,
                    values: newAirlineFilter,
                },
            })
        );
    };

    return (
        <FilterBlock title={t('airlines')} isOpen={isActive} borderBottom>
            {airlines.map(item => (
                <CheckboxBlock key={item.iata}>
                    <FormCheckbox
                        onSelect={value => handleChange(item, value)}
                        isChecked={values.includes(item.iata)}
                    />

                    <Label>{item.name[language]}</Label>
                </CheckboxBlock>
            ))}
        </FilterBlock>
    );
};

export default AirlinesFilter;
