import React, { Suspense, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setDesignSettings } from '../store/designSettingsSlice';

const HomePage = () => {
    const OurAdvantages = React.lazy(() => import(`../components/somon_air/pages/ourAdvantages`));

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setDesignSettings({
                isStickyHeader: false,
                showSearchBlock: true,
                isSmallSearchBlock: false,
            })
        );
    }, [location]);

    return (
        <Suspense>
            <OurAdvantages />
        </Suspense>
    );
};

export default withTranslation()(HomePage);
