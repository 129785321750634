import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { WHITE_COLOR, NERO_COLOR, ORANGE_COLOR, DARK_GREY_COLOR, MIDNIGHT_COLOR } from '../../../../../constants';

export const Wrapper = styled.div`
    max-width: 326px;
    height: 100px;
    margin-right: 30px;
    opacity: 0.8;
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: ${WHITE_COLOR};
    display: inline-table;
    cursor: pointer;
    position: relative;

    @media (max-width: 959px) {
        max-width: 100%;
        width: 100%;
        height: auto;
        margin-bottom: 24px;
    }
`;

export const FareGrid = styled(Grid)`
    padding: 20px;
`;

export const BrandNameGrid = styled(Grid)`
    width: 80%;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    color: ${NERO_COLOR};
`;

export const BrandCheckedIconGrid = styled(Grid)`
    position: absolute;
    right: 16px;
    top: 16px;
    color: ${ORANGE_COLOR};
    text-align: right;
    cursor: pointer;
`;

export const BrandTotalPriceGrid = styled(Grid)`
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    font-family: Rubik;
`;

export const BrandFareInfoGrid = styled(Grid)`
    width: 100%;
`;

export const BrandTariffsRulesGrid = styled(Grid)`
    padding-top: 12px;
    font-size: 16px;
    font-weight: 500;
`;

export const BrandTariffsRulesLabel = styled.div`
    cursor: pointer;
    color: ${props => (props.loading ? `${DARK_GREY_COLOR}` : `${ORANGE_COLOR}`)};
    display: flex;
    align-items: center;
`;
