import styled, { keyframes } from 'styled-components';
import { WHITE_COLOR } from '../../constants';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    
    to {
        opacity: 0;
    }
`;

export const Wrapper = styled.div`
    display: ${props => (props.active ? 'block' : 'none')};
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 151;
    min-width: 100%;
`;

export const Dropdown = styled.div`
    display: block;
    background-color: ${WHITE_COLOR};
    animation-name: ${props => (props.active ? fadeIn : fadeOut)};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    border-radius: 5px;
    padding: ${props => props.noPadding ? 0 : '10px'};
`;
