import styled, { keyframes } from 'styled-components';
import { BRIGHT_COLOR } from '../../constants';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    
    to {
        opacity: 0;
    }
`;

export const Wrapper = styled.div`
    display: ${props => (props.active ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 150;
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 151;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    animation-name: ${props => (props.active ? fadeIn : fadeOut)};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    overflow-y: ${props => (props.position == 'bottom' ? 'hidden' : 'auto')};
`;

export const ModalComponent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: ${props => (props.fixedWidth ? `${props.maxWidth}px` : 'auto')};
    max-width: ${props => props.maxWidth}px;
    min-width: 320px;
    position: relative;
    border-radius: ${props => (props.position == 'bottom' ? '0' : '15px')};
    padding: 20px;
    margin: 30px auto;
    ${props =>
        props.position == 'bottom' &&
        `
        height: 80%;
    `}

    @media (max-width: 899px) {
        max-width: 100%;
        ${props =>
            props.position == 'top'
                ? `
            margin: 0;
        `
                : `
            top: 100%;
            transform: translateY(-100%);
        `}
        padding: 10px;
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    padding: 10px;
    color: ${BRIGHT_COLOR};
    cursor: pointer;
`;

export const Title = styled.div`
    font-size: 18px;
    padding: 0 0 8px;
    margin-bottom: 16px;
    font-weight: bold;

    @media (max-width: 899px) {
        padding: 8px 0 8px 8px;
    }
`;
