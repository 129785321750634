import i18next from 'i18next';
import { Description, FormBlock, InputBlock, Title, Wrapper } from './components';
import { ReactComponent as InfoIcon } from '../../../../../assets/somon_air/icons/info.svg';
import Input from '../../../ui/input';
import envConfigs from '../../../../../configs/envConfigs';

const PayerBlock = ({ form }) => {
    const { t } = i18next;

    const { values, errors, touched, handleBlur, setFieldValue } = form;

    const handleChangeEmail = value => {
        setFieldValue('payer_email', value);
        setFieldValue('passengers[0].email', value);
    };

    const handleChangePhone = value => {
        setFieldValue('payer_phone', value);
        setFieldValue('passengers[0].phone', value);
    };

    return (
        <Wrapper>
            <Title>{t('payer_contacts')}</Title>

            <Description>
                <InfoIcon />
                <span>{t('for_payer_mess')}.</span>
            </Description>

            <FormBlock>
                <InputBlock>
                    <Input
                        value={values.payer_email}
                        touched={touched.payer_email}
                        error={touched.payer_email && errors.payer_email}
                        onChange={handleChangeEmail}
                        onBlur={handleBlur('payer_email')}
                        placeholder={t('email')}
                        label={t('email')}
                        boxShadow
                        required
                    />
                </InputBlock>

                <InputBlock>
                    <Input
                        value={values.payer_phone}
                        touched={touched.payer_phone}
                        error={touched.payer_phone && errors.payer_phone}
                        onChange={handleChangePhone}
                        onBlur={handleBlur('payer_phone')}
                        placeholder={t('phone_number')}
                        label={t('phone_number')}
                        boxShadow
                        required
                        mask={envConfigs.phoneMask}
                    />
                </InputBlock>
            </FormBlock>
        </Wrapper>
    );
};

export default PayerBlock;
