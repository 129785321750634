import styled from 'styled-components';
import {
    BRIGHT_COLOR,
    WHITE_COLOR,
    ORANGE_COLOR,
    SUVA_GREY_COLOR,
    GREY_DARK_COLOR,
    RED_SANGRIA_COLOR,
} from '../../../../constants';

export const ResultContainer = styled.div`
    max-width: 479px;
    padding: 15px;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1279px) {
        max-width: 929px;
    }
`;

export const TechStopInfo = styled.div`
    height: 24px;
    font-size: 12px;
    padding-left: 24px;
    color: ${GREY_DARK_COLOR};
`;
export const InfoCard = styled.div`
    max-width: 759px;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1279px) {
        max-width: 929px;
    }
`;

export const TimeInfo = styled.div`
    width: 204px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1350px) {
        width: 180px;
    }
`;
export const DurationInfo = styled.div`
    width: 204px;
    font-size: 27px;
    font-family: Rubik;
    font-weight: 500;
    letter-spacing: 0px;
    color: ${ORANGE_COLOR};
    text-align: right;
    @media screen and (max-width: 1279px) {
        width: 107px;
        font-size: 21px;
    }
`;
export const LocalTime = styled.div`
    height: 30px;
    width: 204px;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    font-family: Rubik;
    font-style: italic;
    letter-spacing: 0px;
    color: ${GREY_DARK_COLOR};
    @media screen and (max-width: 1279px) {
        max-width: 107px;
        font-size: 11px;
    }
`;

export const Segment = styled.div`
    display: flex;
    align-items: center;
`;
export const SegmentBlock = styled.div`
    display: flex;
    align-items: center;
    margin-top: 36px;
    @media screen and (max-width: 1279px) {
        width: 400px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const TransferBlock = styled.div`
    max-width: 759px;
`;
export const TransferSegment = styled.div`
    max-width: 759px;
    display: flex;
    height: 76px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1279px) {
        max-width: 929px;
    }
`;
export const Transfer = styled.div`
    width: 484px;
    display: flex;
    align-items: center;
    font: italic normal normal 18px/22px Rubik;
    letter-spacing: 0px;
    color: ${RED_SANGRIA_COLOR};
    @media screen and (max-width: 1279px) {
        width: 320px;
        font-size: 15px;
    }
`;
export const Line = styled.div`
    border-left: 2px dashed ${RED_SANGRIA_COLOR};
    height: 76px;
    margin-right: 15px;
    margin-left: 5px;
    @media screen and (max-width: 1279px) {
        margin-left: 0px;
    }
`;
export const SegmentInfo = styled.div`
    display: flex;
    width: 484px;
    height: 50px;
    position: relative;
    @media screen and (max-width: 1279px) {
        width: 410px;
    }
    & > .circle {
        background-color: ${WHITE_COLOR};
        border: 2px solid ${ORANGE_COLOR};
        border-radius: 50%;
        height: 15px;
        width: 15px;
        position: absolute;
        z-index: 2;
        top: 2px;
    }

    & > .line {
        background-color: ${ORANGE_COLOR};
        bottom: 0;
        left: 7px;
        position: absolute;
        top: 10px;
        height: 80px;
        width: 2px;
    }
    & > .circle-bottom {
        background-color: ${WHITE_COLOR};
        border: 2px solid ${ORANGE_COLOR};
        border-radius: 50%;
        height: 15px;
        width: 15px;
        position: absolute;
        z-index: 2;
        top: 78px;
    }
`;
export const DetailsBlock = styled.div`
    display: flex;
    margin-left: 23px;
    @media screen and (max-width: 1279px) {
    }
`;
export const DetailsTimeInfo = styled.div`
    height: 22px;
    width: 80px;
    text-align: left;
    font-size: 18px;
    font-family: Rubik;
    font-weight: 500;
    letter-spacing: 0px;
    color: ${BRIGHT_COLOR};
    @media screen and (max-width: 1279px) {
        width: 50px;
    }
`;
export const CityInfo = styled.div`
    height: 22px;
    max-width: 290px;
    font: normal normal medium 18px/22px Rubik;
    font-weight: 500;
    letter-spacing: 0px;
    color: ${SUVA_GREY_COLOR};
    @media screen and (max-width: 1279px) {
        font-size: 14px;
    }
`;
export const AirlineLogo = styled.div`
    width: 141px;
    height: 48px;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
`;
export const AirlineInfoBlock = styled.div`
    margin-top: 8px;
    width: 529px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: Rubik;
    font-weight: 500;
    color: ${BRIGHT_COLOR};
    @media screen and (max-width: 1279px) {
        font-size: 11px;
    }
`;
export const AirlineInfo = styled.div`
    width: 287px;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;
