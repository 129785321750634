import styled from 'styled-components';
import { DARK_ORANGE, ORANGE_COLOR } from '../../../../../constants';

export const ScrollBlock = styled.div`
    height: 270px;
    max-width: 679px;
    padding: 0 15px;
    margin-top: 10px;
    overflow-y: hidden;
    overflow-x: auto;

    @media screen and (max-width: 1279px) {
        max-width: 609px;
    }

    ::-webkit-scrollbar {
        width: 20px;
        height: 10px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px grey;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${ORANGE_COLOR};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${DARK_ORANGE};
    }
`;
export const ScrollContainer = styled.div``;
