import envConfigs from '../configs/envConfigs';

export const getSupplierIcon = validatingSupplier => {
    try {
        return require('../images/suppliers/' + validatingSupplier + '.png')?.default;
    } catch (error) {
        console.warn(error);
    }
};

export const getFlightIcon = index => {
    return require('../assets/somon_air/icons/resultPage/flightRouteIcons/' + index + '_flight-takeoff.png');
};

export const getTransferArrowIcon = transferCount => {
    return require('../images/transfersArrow/' + transferCount + '_transfer.png');
};

export const getRectangleImg = item => {
    return require('../images/recommendationGuide/' + item + '_rectangle.png');
};

export const getCountryIcon = code => {
    try {
        return require('../images/flags/' + code + '.png')?.default;
    } catch (error) {
        console.warn(error);
    }
};

export const getImgFromUploads = img => {
    return `${envConfigs.uploadHost}${img}`;
};
