import { BY_MAX_PRICE, BY_MIN_PRICE } from '../containers/result/constants';
import { initialState } from '../store/filtersSlice';

export const handleFilterParamsChange = async payload => {
    const search = window.location.search;
    let searchParams = new URLSearchParams(search);
    searchParams = paramsToArrayObject(searchParams.entries());

    switch (payload.type) {
        case 'baggage':
            handleChangeBaggage(searchParams, payload.values);
            break;
        case 'transfers':
            handleChangeTransfers(searchParams, payload.values);
            break;
        case 'sorting':
            handleChangeSorting(searchParams, payload.values);
            break;
        case 'airlines':
            handleChangeAirlines(searchParams, payload.values);
            break;
        case 'flightNumbers':
            handleChangeFlightNumbersList(searchParams, payload.values);
            break;
        case 'price':
            handleChangePrice(searchParams, payload.values);
            break;
        case 'airports':
            handleChangeAirports(searchParams, payload.values);
            break;
        case 'duration':
            handleChangeDuration(searchParams, payload.values);
            break;
        case 'departureTime':
            handleChangeDepartureTime(searchParams, payload.values);
            break;
        case 'arrivalTime':
            handleChangeArrivalTime(searchParams, payload.values);
            break;
        default:
            break;
    }
};

const paramsToArrayObject = entries => {
    const result = [];

    for (const [key, value] of entries) {
        result.push({ key, value });
    }

    return result;
};

const paramsToObject = entries => {
    const result = {};

    for (const [key, value] of entries) {
        result[key] = value;
    }

    return result;
};

const objectsToParams = objects => {
    if (objects.length > 0) {
        let params = '';

        objects.forEach((value, index) => {
            if (index === 0) {
                params = `?${value.key}=${value.value}`;
            } else {
                params = `${params}&${value.key}=${value.value}`;
            }
        });

        return params;
    } else {
        return window.location.pathname;
    }
};

const handleChangeBaggage = (searchParams, baggage) => {
    let params = [...searchParams];
    const { withBaggage, withoutBaggage } = baggage.values;

    if (withBaggage) {
        params = params.filter(value => value.key !== 'withBaggage');
    } else {
        if (!params.find(x => x.key === 'withBaggage')) {
            params.push({ key: 'withBaggage', value: 0 });
        }
    }

    if (withoutBaggage) {
        params = params.filter(value => value.key !== 'withoutBaggage');
    } else {
        if (!params.find(x => x.key === 'withoutBaggage')) {
            params.push({ key: 'withoutBaggage', value: 0 });
        }
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeTransfers = (searchParams, transfers) => {
    let params = [...searchParams];
    const { withoutTransfers, oneTransfer, manyTransfers } = transfers.values;

    if (withoutTransfers) {
        params = params.filter(value => value.key !== 'withoutTransfers');
    } else {
        if (!params.find(x => x.key === 'withoutTransfers')) {
            params.push({ key: 'withoutTransfers', value: 0 });
        }
    }

    if (oneTransfer) {
        params = params.filter(value => value.key !== 'oneTransfer');
    } else {
        if (!params.find(x => x.key === 'oneTransfer')) {
            params.push({ key: 'oneTransfer', value: 0 });
        }
    }

    if (manyTransfers) {
        params = params.filter(value => value.key != 'manyTransfers');
    } else {
        if (!params.find(x => x.key === 'manyTransfers')) {
            params.push({ key: 'manyTransfers', value: 0 });
        }
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeSorting = (searchParams, sorting) => {
    let params = [...searchParams];

    if (sorting.value === BY_MIN_PRICE) {
        params = params.filter(value => value.key !== 'sorting');
    } else {
        if (!params.find(x => x.key === 'sorting')) {
            params.push({ key: 'sorting', value: sorting.value });
        }
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeAirlines = (searchParams, airlines) => {
    let params = [...searchParams];
    const { isActive, values } = airlines;

    params = params.filter(value => value.key !== 'airlines');

    if (isActive) {
        params.push({ key: 'airlines', value: values.join() });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangePrice = (searchParams, prices) => {
    let params = [...searchParams];
    const { isActive, values } = prices;

    params = params.filter(value => value.key !== 'price');

    if (isActive) {
        params.push({ key: 'price', value: `${values.min}-${values.max}` });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeFlightNumbersList = (searchParams, flightNumbers) => {
    let params = [...searchParams];
    const { isActive, values } = flightNumbers;

    params = params.filter(value => value.key !== 'flightNumbers');

    if (isActive) {
        params.push({ key: 'flightNumbers', value: values.join(',') });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeAirports = (searchParams, airports) => {
    let params = [...searchParams];
    const { isActive, values } = airports;

    params = params.filter(value => value.key !== 'airports');

    if (isActive) {
        const string = values.map(value => {
            let result = '';

            if (value.departures.length) {
                result = `${result}${value.routeIndex}_departures-${value.departures.join()};`;
            }

            if (value.arrivals.length) {
                result = `${result}${value.routeIndex}_arrivals-${value.arrivals.join()};`;
            }

            return result;
        });

        params.push({ key: 'airports', value: string.join('') });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeDuration = (searchParams, duration) => {
    let params = [...searchParams];
    const { isActive, values } = duration;

    params = params.filter(value => value.key !== 'duration');

    if (isActive) {
        const string = values.map(value => {
            return `${value.routeIndex}_${value.values.min}-${value.values.max};`;
        });

        params.push({ key: 'duration', value: string.join('') });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeDepartureTime = (searchParams, departureTime) => {
    let params = [...searchParams];
    const { isActive, values } = departureTime;

    params = params.filter(value => value.key !== 'departureTime');

    if (isActive) {
        const string = values.map(value => {
            return `${value.routeIndex}_${value.values.min}-${value.values.max};`;
        });

        params.push({ key: 'departureTime', value: string.join('') });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

const handleChangeArrivalTime = (searchParams, arrivalTime) => {
    let params = [...searchParams];
    const { isActive, values } = arrivalTime;

    params = params.filter(value => value.key !== 'arrivalTime');

    if (isActive) {
        const string = values.map(value => {
            return `${value.routeIndex}_${value.values.min}-${value.values.max};`;
        });

        params.push({ key: 'arrivalTime', value: string.join('') });
    }

    window.history.pushState(null, null, objectsToParams(params));
};

export const decodeParamsFromUrl = priceRange => {
    const search = window.location.search;
    let searchParams = new URLSearchParams(search);
    searchParams = paramsToObject(searchParams.entries());

    let result = {};

    if (searchParams.withBaggage || searchParams.withoutBaggage) {
        result['baggage'] = {
            isActive: true,
            values: {
                withBaggage: searchParams.withBaggage !== '0',
                withoutBaggage: searchParams.withoutBaggage !== '0',
            },
        };
    }

    if (searchParams.withoutTransfers || searchParams.oneTransfer || searchParams.manyTransfers) {
        result['transfers'] = {
            isActive: true,
            values: {
                withoutTransfers: searchParams.withoutTransfers !== '0',
                oneTransfer: searchParams.oneTransfer !== '0',
                manyTransfers: searchParams.manyTransfers !== '0',
            },
        };
    }

    if (searchParams?.sorting === BY_MAX_PRICE) {
        result['sorting'] = {
            isActive: true,
            value: BY_MAX_PRICE,
        };
    }

    if (searchParams.airlines) {
        result['airlines'] = {
            isActive: true,
            values: searchParams.airlines.split(','),
        };
    }

    if (searchParams.price) {
        const prices = searchParams.price.split('-');
        if (prices.length === 2) {
            result['price'] = {
                isActive: true,
                values: {
                    min: parseFloat(prices[0]) >= priceRange.min ? parseFloat(prices[0]) : priceRange.min,
                    max: parseFloat(prices[1]) <= priceRange.max ? parseFloat(prices[1]) : priceRange.max,
                },
            };
        }
    } else {
        result['price'] = {
            isActive: false,
            values: { min: priceRange.min, max: priceRange.max },
        };
    }

    if (searchParams.flightNumbers) {
        result['flightNumbers'] = {
            isActive: true,
            values: searchParams.flightNumbers.split(','),
        };
    }

    if (searchParams.airports) {
        const routes = searchParams.airports.split(';');

        if (routes.length > 0) {
            let airports = {
                isActive: true,
                values: [],
            };

            routes.forEach(route => {
                const [key, value] = route.split('-');
                if (key) {
                    const [routeIndex, type] = key.split('_');

                    if (!!airports.values.find(route => route.routeIndex === routeIndex)) {
                        airports.values = airports.values.map(airport => {
                            if (airport.routeIndex === routeIndex) {
                                return {
                                    routeIndex: parseInt(routeIndex),
                                    arrivals: type === 'arrivals' ? value.split(',') : airport.arrivals,
                                    departures: type === 'departures' ? value.split(',') : airport.departures,
                                };
                            }

                            return airport;
                        });
                    } else {
                        airports.values.push({
                            routeIndex: parseInt(routeIndex),
                            arrivals: type === 'arrivals' ? value.split(',') : [],
                            departures: type === 'departures' ? value.split(',') : [],
                        });
                    }
                }
            });

            result['airports'] = airports;
        }
    }

    if (searchParams.duration) {
        const duration = searchParams.duration.split(';');

        if (duration.length > 0) {
            let durations = {
                isActive: true,
                values: [],
            };

            duration.forEach(value => {
                const [routeIndex, values] = value.split('_');

                if (routeIndex) {
                    const [min, max] = values.split('-');

                    durations.values.push({
                        routeIndex: parseInt(routeIndex),
                        values: { min, max },
                    });
                }
            });

            result['duration'] = durations;
        }
    }

    if (searchParams.departureTime) {
        const departureTime = searchParams.departureTime.split(';');

        if (departureTime.length > 0) {
            let departureResults = {
                isActive: true,
                values: [],
            };

            departureTime.forEach(value => {
                const [routeIndex, values] = value.split('_');

                if (routeIndex) {
                    const [min, max] = values.split('-');

                    departureResults.values.push({
                        routeIndex: parseInt(routeIndex),
                        values: { min, max },
                    });
                }
            });

            result['departureTime'] = departureResults;
        }
    }

    if (searchParams.arrivalTime) {
        const arrivalTime = searchParams.arrivalTime.split(';');

        if (arrivalTime.length > 0) {
            let arrivalResuls = {
                isActive: true,
                values: [],
            };

            arrivalTime.forEach(value => {
                const [routeIndex, values] = value.split('-');

                if (routeIndex) {
                    const [min, max] = values.split('-');

                    arrivalResuls.values.push({
                        routeIndex: parseInt(routeIndex),
                        values: { min, max },
                    });
                }
            });

            result['arrivalTime'] = arrivalResuls;
        }
    }

    if (searchParams.pricesTable) {
        const params = searchParams.pricesTable.split('-');

        if (params.length === 3) {
            result['pricesTable'] = {
                isActive: true,
                values: {
                    type: params[0],
                    supplier: params[1],
                    price: params[2],
                },
            };
        }
    }

    return result;
};
export const resetFilters = filtersData => {
    const filters = JSON.parse(JSON.stringify(initialState.filterParams));

    filters.price = { isActive: false, values: filtersData.priceRange };
    return filters;
};
