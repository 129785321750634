import styled from 'styled-components';

export const Container = styled.div`
    padding: '16px 32px';
    font-size: '14px';
`;
export const TitleBlock = styled.div`
    padding-bottom: '8px';
    font-weight: '500';
`;
export const TitleContent = styled.div`
    font-style: 'Rubik';
`;
export const Session = styled.div`
    font-weight: '500';
`;
