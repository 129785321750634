import { useFormik } from 'formik';
import i18next from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import _ from 'lodash';

import { PS, SR, PSP } from '../../../../containers/booking/constants';
import { ADT, CHD, INF, INS } from '../../../../utils';
import { AgreementBookLink, AgreementText, CheckboxBlock, SubmitBlock, Wrapper } from './components';
import PayerBlock from './payerBlock';
import PassengerItem from './passengerItem';
import { Title } from './payerBlock/components';
import Button from '../../ui/button';
import { ALL_DOCUMENTS_ITEMS, FRIENDLY_COUNTRY, ONLY_RU_COUNTRY } from '../../../../utils/constants/booking';
import { useState } from 'react';
import Checkbox from '../../ui/checkbox';
import RulesModal from '../../pages/resultPage/tariffsBlock/rulesModal';
import CovidRules from '../covidRules';

const BookingForm = ({ sessionId, passengers, handleBookClick, redirectToResult }) => {
    const { t } = i18next;
    const { routes, flightType, included, fareRules } = useSelector(state => state.prebook);
    const { isAuth, login, phone } = useSelector(state => state.authSettings);
    const [agreeWithRules, setAgreeWithRules] = useState(false);
    const [showRulesModal, setShowRulesModal] = useState(false);

    const lastRoute = _.last(routes);
    const deadlineDate = _.last(lastRoute.segments).arrival.time;

    const getPassengers = () => {
        let passengersArray = [];
        let chdArray = [];
        let insArray = [];

        _.forEach(passengers, (count, passengerType) => {
            if (passengerType === ADT) {
                _.times(count, () => {
                    passengersArray.push([passengerType]);
                });
            }

            if (passengerType === CHD) {
                _.times(count, () => {
                    chdArray.push(passengerType);
                });
            }

            if (passengerType === INS) {
                _.times(count, () => {
                    insArray.push(passengerType);
                });
            }

            if (passengerType === INF) {
                _.times(count, index => {
                    passengersArray[index] = [...passengersArray[index], passengerType];
                });
            }
        });

        const passengersList = [..._.flatten(passengersArray), ...chdArray, ...insArray];

        return passengersList.map((value, index) => {
            let documentItems = [];

            if (flightType === ONLY_RU_COUNTRY) {
                documentItems = value === ADT ? ALL_DOCUMENTS_ITEMS : ALL_DOCUMENTS_ITEMS.filter(item => item !== PS);
            } else if (flightType === FRIENDLY_COUNTRY) {
                documentItems =
                    value === ADT
                        ? ALL_DOCUMENTS_ITEMS.filter(item => item !== SR)
                        : ALL_DOCUMENTS_ITEMS.filter(item => item !== PS && item !== SR);
            } else {
                documentItems = ALL_DOCUMENTS_ITEMS.filter(item => item !== PS && item !== SR);
            }

            return {
                type: value,
                surname: '',
                name: '',
                middle_name: '',
                citizenship: '',
                gender: '',
                document_items: documentItems,
                document_type: _.first(documentItems),
                document_number: '',
                email: index === 0 && isAuth ? login : '',
                phone: index === 0 && isAuth ? phone : '',
                expiration_date: '',
                date_of_birth: '',
            };
        });
    };

    const validationSchema = Yup.object().shape({
        payer_email: Yup.string().email(t('invalid_email')).required(t('required')),
        payer_phone: Yup.string()
            .test('validPhone', t('invalid_phone'), value => {
                const length = value?.replace(/[^\d]/g, '').length;
                return length > 11;
            })
            .required(t('required')),
        passengers: Yup.array().of(
            Yup.object().shape({
                surname: Yup.string()
                    .min(2, t('min_2_symbol'))
                    .test('onlyAZSpace', t('only_a_z_space'), value => /^[A-Z\s]*$/i.test(value))
                    .required(t('required')),
                name: Yup.string()
                    .min(2, t('min_2_symbol'))
                    .test('onlyAZSpace', t('only_a_z_space'), value => /^[A-Z\s]*$/i.test(value))
                    .required(t('required')),
                middle_name: Yup.string().when('document_type', (documentType, schema) =>
                    documentType !== PS && documentType !== SR ? schema.nullable() : schema.required(t('required'))
                ),
                citizenship: Yup.string().required(t('required')),
                gender: Yup.string().required(t('required')),
                date_of_birth: Yup.string()
                    .test('validDate', t('check_date'), date => moment(date, 'DD.MM.YYYY', true).isValid())
                    .when('type', (type, schema) => {
                        if (type === ADT) {
                            const min = moment('01.01.1900', 'DD.MM.YYYY');
                            const max = moment(deadlineDate, 'DD.MM.YYYY').add(-12, 'years');

                            return schema.test('checkDateForADT', t('incorrect_date_from_adt'), value => {
                                const inspect = moment(value, 'DD.MM.YYYY HH:mm');

                                return (
                                    (min.isBefore(inspect) || min.isSame(inspect)) &&
                                    (max.isAfter(inspect) || max.isSame(inspect))
                                );
                            });
                        } else if (type === CHD) {
                            const min = moment(deadlineDate, 'DD.MM.YYYY').add(-12, 'years');
                            const max = moment(deadlineDate, 'DD.MM.YYYY').add(-2, 'years');

                            return schema.test('checkDateForCHD', t('incorrect_date_from_chd'), value => {
                                const inspect = moment(value, 'DD.MM.YYYY HH:mm');

                                return min.isBefore(inspect) && (max.isAfter(inspect) || max.isSame(inspect));
                            });
                        } else {
                            return schema.test('checkInfDate', t('incorrect_date_from_inf'), value => {
                                const inspect = moment(value, 'DD.MM.YYYY HH:mm');

                                if (moment().isBefore(inspect) || moment().isSame(inspect)) return false;

                                const min = moment(deadlineDate, 'DD.MM.YYYY').add(-2, 'years');
                                const max = moment(deadlineDate, 'DD.MM.YYYY').add(-14, 'days');

                                return min.isBefore(inspect) && max.isAfter(inspect);
                            });
                        }
                    })
                    .when(['type', 'document_type'], (type, documentType, schema) => {
                        if (type === ADT && documentType === SR) {
                            return schema.test('checkADTDateOfBirth', t('check_date_and_change_doc'), value => {
                                const inspect = moment(value, 'DD.MM.YYYY HH:mm');
                                const deadline = moment(deadlineDate, 'DD.MM.YYYY').add(-14, 'years');
                                return deadline.isBefore(inspect);
                            });
                        }

                        if (type === ADT && documentType === PS) {
                            return schema.test('checkADTDateOfBirth', t('check_date_and_change_doc'), value => {
                                const inspect = moment(value, 'DD.MM.YYYY HH:mm');
                                const deadline = moment(deadlineDate, 'DD.MM.YYYY').add(-14, 'years');
                                return deadline.isAfter(inspect) || deadline.isSame(inspect);
                            });
                        }

                        return schema;
                    })
                    .required(t('required')),
                document_type: Yup.string().required(t('required')),
                document_number: Yup.string().when('document_type', (documentType, schema) => {
                    if (documentType === PS) {
                        return schema
                            .test(
                                'documentTypeIsPS',
                                t('ps_should_be_10_digits'),
                                value => !isNaN(Number(value)) && value.length === 10
                            )
                            .required(t('required'));
                    }
                    if (documentType === PSP) {
                        return schema
                            .test(
                                'documentTypeIsPS',
                                t('psp_should_be_9_digits'),
                                value => !isNaN(Number(value)) && value.length === 9
                            )
                            .required(t('required'));
                    }
                    if (documentType === SR) {
                        return schema
                            .test('incorrectDocNumber', t('incorrect_doc_number'), value => {
                                let count = value?.length;

                                if (count < 9 || count > 12) {
                                    return false;
                                } else {
                                    if (isNaN(Number(value?.substr(-6)))) {
                                        return false;
                                    }
                                    count = count - 6;
                                    const lettersCode = value?.substr(0, count);

                                    if (!/^[А-Я]*$/i.test(lettersCode?.substr(-2))) {
                                        return false;
                                    }
                                    count = count - 2;
                                    const latinLetters = lettersCode?.substr(0, count);

                                    if (!/^[IVX]*$/i.test(latinLetters)) {
                                        return false;
                                    }
                                }

                                return true;
                            })
                            .required(t('required'));
                    }

                    return schema.max(15, t('incorrect_doc_number')).required(t('required'));
                }),
                expiration_date: Yup.string().when('document_type', {
                    is: documentType => documentType !== PS && documentType !== SR,
                    then: schema =>
                        schema
                            .test('validDate', t('check_date'), date => moment(date, 'DD.MM.YYYY', true).isValid())
                            .test('expirationDate', t('expired_date'), date => {
                                const inspect = moment(date, 'DD.MM.YYYY HH:mm');
                                const deadline = moment(deadlineDate, 'DD.MM.YYYY');

                                return deadline.isBefore(inspect);
                            })
                            .required(t('required')),
                }),
                email: Yup.string().when('type', {
                    is: ADT,
                    then: schema => schema.email(t('invalid_email')).required(t('required')),
                }),
                phone: Yup.string().when('type', {
                    is: ADT,
                    then: schema =>
                        schema
                            .test('validPhone', t('invalid_phone'), value => {
                                const length = value?.replace(/[^\d]/g, '').length;
                                return length > 11;
                            })
                            .required(t('required')),
                }),
            })
        ),
    });

    const handleSubmitFunction = values => {
        handleBookClick(values);
    };

    const form = useFormik({
        initialValues: {
            payer_email: isAuth ? login : '',
            payer_phone: isAuth ? phone : '',
            passengers: getPassengers(),
        },
        validationSchema,
        onSubmit: handleSubmitFunction,
    });

    return (
        <>
            <Wrapper>
                <PayerBlock form={form} />
                <Title>{form.values.passengers.length === 1 ? t('passenger_data') : t('passengers')}</Title>
                {form.values.passengers.map((value, index) => (
                    <PassengerItem key={index} passenger={value} index={index} form={form} />
                ))}

                <CheckboxBlock>
                    <div>
                        <Checkbox checked={agreeWithRules} onClick={() => setAgreeWithRules(prev => !prev)} />
                    </div>

                    <AgreementText>
                        {t('i_read_fares')}
                        <AgreementBookLink onClick={() => setShowRulesModal(true)}>
                            {t('agreement_book_link')}
                        </AgreementBookLink>
                        {t('i_accept_fares')}
                    </AgreementText>
                </CheckboxBlock>

                <SubmitBlock>
                    <div>
                        <Button
                            onClick={form.handleSubmit}
                            title={t('book_and_proceed_to_payment')}
                            padding="0 15px"
                            fontSize={24}
                            mobileFontSizeMultiplier={0.7}
                            textTransform="none"
                            fontWeight="700"
                            disabled={!agreeWithRules}
                        />
                    </div>
                </SubmitBlock>

                <CovidRules />
            </Wrapper>

            <RulesModal
                openModal={showRulesModal}
                handleCloseModal={() => setShowRulesModal(false)}
                fareRules={fareRules}
                routes={routes}
                included={included}
            />
        </>
    );
};

export default BookingForm;
