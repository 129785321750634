import { baseParams, basicInstance } from '../..';

export const getStaticPages = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .get('article-list', { params })
        .then(response => response.data);
};

export const getStaticPageById = async data => {
    const params = {
        ...baseParams,
        ...data,
    };

    return basicInstance()
        .get('article', { params })
        .then(response => response.data);
};
