import React from 'react';
import _ from 'lodash';
import i18next from 'i18next';
import { Block, InlineBlock, RouteTitle } from './components';
import { ReactComponent as TrendingFlatIcon } from '../../../../../assets/somon_air/icons/TrendingFlat.svg';

const RoutesInfo = (props) => {

    const { language } = i18next;
    const { routes, included } = props;
    let data = [];

    routes.map((route, index)  => {
        const { segments } = route;
        const firstSegment = _.first(segments);

        if (firstSegment) {
            if (segments.length > 1) {
                const lastSegment = _.last(segments);
                data[index] = [firstSegment.departure.city, lastSegment.arrival.city];
            } else {
                data[index] = [firstSegment.departure.city, firstSegment.arrival.city];
            }
        }
    });

    let count = data.length;

    if (count === 2 && data[0][0] === data[1][1]) {
        const departure = included.city[data[0][0]][`name_${language}`];
        const arrival = included.city[data[1][0]][`name_${language}`];

        return (
            <InlineBlock>
                <RouteTitle>{departure}</RouteTitle>
                <Block>
                    <TrendingFlatIcon width={50} />
                </Block>
                <RouteTitle>{arrival}</RouteTitle>
            </InlineBlock>
        );
    } else if (count > 0) {
        const departureFirstRoute = included.city[_.first(data)[0]][`name_${language}`];
        const arrivalFirstRoute = included.city[_.first(data)[1]][`name_${language}`];
        let departureSecondRoute = '';

        if (count > 1) {
            departureSecondRoute = included.city[data[1][0]][`name_${language}`];
        }

        return (
            <>
                <InlineBlock>
                    <RouteTitle>{departureFirstRoute}</RouteTitle>
                    <Block>
                        <TrendingFlatIcon width={50} />
                    </Block>
                    <RouteTitle>{arrivalFirstRoute}</RouteTitle>
                    {count > 1 && <div>, {departureSecondRoute}...</div>}
                </InlineBlock>
            </>
        );
    } else {
        return null;
    }
};

export default RoutesInfo;