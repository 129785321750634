import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mainConfigs } from '../../configs';
import { getRequisites, storageService } from '../../protocols';
import { setCurrencySettings } from '../../store/currencySettingsSlice';
import { getUserInfo } from '../../protocols/rest/services/user';
import { setAuthSettings } from '../../store/authSettingsSlice';

const ConfigurationContainer = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        getRequisitInfo();
        getClientInfo();
    }, []);

    const getRequisitInfo = () => {
        getRequisites().then(response => {
            const { available_currencies, currency, available_languages, logo } = response;

            configureCurrencies(available_currencies, currency);
            configureLocalization(available_languages);

            setIsLoading(false);
        });
    };

    const getClientInfo = () => {
        getUserInfo()
            .then(response => {
                dispatch(
                    setAuthSettings({
                        isAuth: true,
                        id: response.data.id,
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        fatherName: response.data.father_name,
                        login: response.data.login,
                        balance: response.data.balance,
                        email: response.data.email,
                        phone: response.data.phone,
                    })
                );
            })
            .catch(error => {
                storageService.removeItem('token');
                dispatch(
                    setAuthSettings({
                        isAuth: false,
                    })
                );
            });
    };

    const configureCurrencies = (availableCurrencies, currency) => {
        const localCurrency = storageService.getItem('currency');
        const currencies = JSON.parse(availableCurrencies);

        if (localCurrency && currencies.includes(localCurrency)) {
            dispatch(
                setCurrencySettings({
                    currency: localCurrency,
                    availableCurrencies: currencies,
                })
            );
        } else {
            dispatch(
                setCurrencySettings({
                    currency,
                    availableCurrencies: currencies,
                })
            );
            storageService.setItem('currency', currency);
        }
    };

    const configureLocalization = availableLanguages => {
        const localLanguage = storageService.getItem('language');
        const languages = Object.keys(availableLanguages);
        const resources = {};

        languages.forEach(language => {
            resources[language] = {
                translation: availableLanguages[language],
            };
        });

        let currentLanguage = '';

        if (localLanguage && languages.includes(localLanguage)) {
            currentLanguage = localLanguage;
        } else {
            currentLanguage = mainConfigs.language;
            storageService.setItem('language', currentLanguage);
        }

        i18next.use(initReactI18next).init({
            lng: currentLanguage,
            fallbackLng: languages,
            debug: true,
            resources,
        });
        moment.locale(currentLanguage === 'tj' ? 'tg' : currentLanguage);
    };

    if (isLoading) return null;

    return children;
};

export default ConfigurationContainer;
