import { Backdrop, Wrapper, ModalComponent, CloseButton, Title } from './components';
import ReactDOM from 'react-dom';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ReactComponent as CloseIcon } from '../../../../assets/somon_air/icons/close.svg';

const Modal = ({ children, show, onClose, title = '', maxWidth = 1280, fixedWidth = false, position = 'top' }) => {
    const backdropRef = useRef(null);
    const [wrapperShow, setWrapperShow] = useState(false);
    const [backdropShow, setBackdropShow] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            handleClose();
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('mousedown', handleClose);
        };
    }, []);

    useEffect(() => {
        if (show) {
            setWrapperShow(true);
            setBackdropShow(true);
            document.body.classList.add(isMobile ? 'noscroll-mobile' : 'noscroll');
        } else {
            handleClose();
        }
    }, [show]);

    const handleBackdropClick = event => {
        if (event.target === backdropRef.current) {
            handleClose();
        }
    };

    const handleClose = () => {
        setBackdropShow(false);
        setTimeout(() => {
            setWrapperShow(false);
            onClose(true);
            document.body.classList.remove(isMobile ? 'noscroll-mobile' : 'noscroll');
        }, 200);
    };

    const handleKeyPress = e => {
        if (e.key === 'Esc' || e.keyCode === 27) {
            handleClose();
        }
    };

    return ReactDOM.createPortal(
        <Wrapper active={wrapperShow}>
            <Backdrop ref={backdropRef} active={backdropShow} position={position} onClick={handleBackdropClick}>
                <ModalComponent active={wrapperShow} maxWidth={maxWidth} fixedWidth={fixedWidth} position={position}>
                    {title && <Title>{title}</Title>}
                    <CloseButton onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>
                    {children}
                </ModalComponent>
            </Backdrop>
        </Wrapper>,
        document.body
    );
};

export default Modal;
