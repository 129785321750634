import React, { useState } from 'react';
import i18next from 'i18next';
import Modal from '../../../../ui/modal';
import {
    ModalWrapper,
    Logo,
    Direction,
    FlightInfo,
    Divider,
    AirlineInfo,
    TimeBlock,
    TimeInfo,
    DateInfo,
    OrderInfo,
    ClassInfo,
    AdditionalInfoIcons,
    AdditionalInfos,
    ChooseButton,
    HeaderDivider,
    TransfersArrow,
    SegmentBlock,
    TabsComponent,
    TariffsTab,
    TabContent,
    RouteDest,
} from './components';
import _ from 'lodash';
import { getServiceClass, getDurationTime } from '../../utils';
import { normalizePrice } from '../../../../../../lib/normalize';
import { getSupplierIcon } from '../../../../../../lib/getImage';
import {
    AmountBlock,
    Balance,
    Currency,
    Duration,
    TotalDuration,
    TotalDurationInfo,
    TotalInfo,
} from '../recommendation/components';
import { useSelector } from 'react-redux';
import { LuggageAmount, PlaceAmount } from '../../desktop/recommendation/components';
import FlightInfoIcons from '../../flightInfoIcons';
import noun from 'plural-ru';
import TariffsForm from '../../tariffsBlock/mobile';
const MobileItemModal = ({ recommendation, getFareRules, getBrandFares, active, onClose, onBookingClick }) => {
    const { brandFares, brandRules, included, session } = useSelector(state => state.resultData);
    const { currency } = useSelector(state => state.currencySettings);
    const { total_price, rec_id, routes, lowcost, brandRecId, brand_name, has_branded_tariffs, validating_supplier } =
        recommendation;
    const route = _.first(routes);

    const { t, language } = i18next;
    const [show, setShow] = useState(0);
    const [currentRecId, setCurrentRecId] = useState(null);
    const handleClose = () => {
        onClose(false);
    };
    let actualTotalPrice = total_price;
    if (brandRecId) {
        const { rec_id } = recommendation;
        const actualBrandFare = _.find(brandFares[rec_id], { rec_id: brandRecId });

        actualTotalPrice = actualBrandFare.total_price;
    }

    const getTitle = () => {
        const route = routes[0];
        const { segments } = route;
        const first = _.first(segments).departure;
        const last = _.last(segments).arrival;
        return `${t('ticket')} ${first.airport} - ${last.airport}`;
    };
    const handleTabChange = (e, newActive) => {
        setShow(newActive);

        if (newActive === 1) {
            getBrandFares(rec_id);
        }
    };

    const renderDurationTime = duration => {
        const hours = Math.trunc(duration / 3600);
        const minutes = Math.floor((duration - hours * 3600) / 60);

        return (
            <>
                {hours} {t('hour_short')} {minutes} {t('minutes_short_3')}
            </>
        );
    };

    const renderRoute = (item, index) => {
        let { segments } = item;
        let actualSegments = JSON.parse(JSON.stringify(segments));

        if (brandRecId) {
            const { rec_id } = recommendation;
            const actualBrandFare = _.find(brandFares[rec_id], { rec_id: brandRecId });

            actualSegments = actualBrandFare.routes[index].segments;
        }

        const first = _.first(actualSegments).departure;
        const last = _.last(actualSegments).arrival;
        const { city } = included;
        return (
            <div key={`route-block-${Math.random()}`}>
                <RouteDest>
                    {`${city[first.city].name[language]} - ${city[last.city].name[language]}`} <br />
                    <TotalDurationInfo>
                        (<TotalInfo>{t('on_way')}</TotalInfo>
                        <TotalDuration>{renderDurationTime(route.duration)}</TotalDuration>)
                    </TotalDurationInfo>
                </RouteDest>

                {actualSegments.map((value, index) => {
                    return renderSegment(value, index);
                })}
            </div>
        );
    };

    const renderFlightAirports = value => {
        return (
            <TimeInfo>
                <DateInfo>{value.time.split(' ')[0]}</DateInfo>
                <Direction>
                    {value.time.split(' ')[1]} ({value.airport})
                </Direction>
                <Duration>{included.airport[value.airport].name[language]}</Duration>
            </TimeInfo>
        );
    };
    const getFreeSeatsLabel = freeSeats => {
        if (freeSeats === 1) {
            return freeSeats + ' ' + t('free_seats_1');
        }

        if (freeSeats > 1 && freeSeats < 5) {
            return freeSeats + ' ' + t('free_seats_2_4');
        }

        if (freeSeats > 4 && freeSeats < 9) {
            return freeSeats + ' ' + t('free_seats_5');
        }

        if (freeSeats > 8) {
            return '9+ ' + t('free_seats_5');
        }
    };
    const renderBaggage = (baggage, baggage_weight) => {
        switch (baggage) {
            case _.isNull(baggage):
                baggage = 0;
                break;
            case '0':
            case '0N':
            case '0 PC':
            case '0PC':
            case 'N/A':
            case 'NO':
            case '0 N/A':
                baggage = 0;
                break;
            case '1':
            case '1P':
            case '1 PC':
            case '10K':
            case '10 KG':
            case '20 KG':
            case '23 KG':
            case '30 KG':
            case '35 KG':
            case '40 KG':
            case '1PC':
                baggage = 1;
                break;
            case '2P':
            case '2 PC':
                baggage = 2;
                break;
            default:
                break;
        }
        return (
            <div style={{ fontSize: '12px', textAlign: 'center' }}>
                {baggage !== 0
                    ? `${t('baggage')}: ${baggage} ${noun(baggage, t('place_one'), t('place_more'), t('place_many'))} ${
                          baggage_weight ? `(${baggage_weight} ${t('weight_unit')})` : ''
                      }`
                    : t('no_baggage')}
            </div>
        );
    };

    const renderSegment = segment => {
        const {
            operation_supplier,
            carrier_number,
            arrival,
            departure,
            service_class,
            free_seats,
            baggage,
            baggage_weight,
        } = segment;

        return (
            <SegmentBlock key={`segment-${Math.random()}`}>
                <div>
                    <Direction>
                        {t('flight')} {operation_supplier}-{carrier_number}
                        <Duration>{getDurationTime(segment.duration, t('hour_short'), t('minutes_short_3'))}</Duration>
                    </Direction>
                </div>
                <Divider />
                <AirlineInfo>{t('operating_company')}</AirlineInfo>
                <Logo>
                    <img src={getSupplierIcon(operation_supplier)} alt="supplier-icon" />
                </Logo>
                <TimeBlock>
                    {renderFlightAirports(departure)}
                    <TransfersArrow>
                        <span className="circle" />
                        <span className="line" />
                        <span className="circle-bottom" />
                    </TransfersArrow>
                    {renderFlightAirports(arrival)}
                </TimeBlock>
                <Divider />
                <OrderInfo>
                    <ClassInfo>{getServiceClass(service_class, t('class'))}</ClassInfo>
                    <AdditionalInfoIcons>
                        <FlightInfoIcons data={segment} />
                    </AdditionalInfoIcons>
                </OrderInfo>
                <Divider />
                <AdditionalInfos>
                    <PlaceAmount>{getFreeSeatsLabel(free_seats)}</PlaceAmount>
                    <LuggageAmount>{renderBaggage(baggage, baggage_weight)}</LuggageAmount>
                </AdditionalInfos>
            </SegmentBlock>
        );
    };
    const TabPanel = props => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <TabContent>{children}</TabContent>}
            </div>
        );
    };
    return (
        <div>
            <Modal show={active} onClose={handleClose} maxWidth={1280} fixedWidth title={getTitle()}>
                <ModalWrapper>
                    <HeaderDivider />
                    <TabsComponent
                        value={show}
                        onChange={handleTabChange}
                        aria-label="main TabsComponent"
                        variant="fullWidth"
                    >
                        <TariffsTab label={t('main')} id="main-tab-0" />
                        {has_branded_tariffs && <TariffsTab label={t('tariffs')} id={'main-tab-1'} />}
                    </TabsComponent>
                    <TabPanel value={show} index={0}>
                        <FlightInfo>
                            <AmountBlock>
                                <Balance>{normalizePrice(actualTotalPrice[currency])}</Balance>
                                <Currency> {currency}</Currency>
                            </AmountBlock>
                        </FlightInfo>
                        {routes.map((value, index) => {
                            return renderRoute(value, index);
                        })}
                    </TabPanel>
                    {has_branded_tariffs && (
                        <TabPanel index={1} show={active} value={show}>
                            {show === 1 && (
                                <TariffsForm
                                    recId={rec_id}
                                    brandRecId={brandRecId}
                                    brandFares={brandFares[rec_id]}
                                    brandRules={brandRules}
                                    brandName={brand_name}
                                    getFareRules={getFareRules}
                                    currency={currency}
                                    currentRecId={currentRecId}
                                    setCurrentRecId={setCurrentRecId}
                                />
                            )}
                        </TabPanel>
                    )}
                    <ChooseButton type="submit" onClick={() => onBookingClick(brandRecId || rec_id, session)}>
                        {t('choose')}
                    </ChooseButton>
                </ModalWrapper>
            </Modal>
        </div>
    );
};

export default MobileItemModal;
