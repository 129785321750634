class StorageService {
    getItem = (key) => {
        try {
            const data = window.localStorage.getItem(`b2c_${key}`);

            return data ? JSON.parse(data) : null;
        } catch (e) {
            console.log('StorageService getItem() error', e);
        }
    };

    setItem = (key, value) => {
        try {
            window.localStorage.setItem(`b2c_${key}`, JSON.stringify(value));
        } catch (e) {
            console.log('StorageService setItem() error', e);
        }
    };

    removeItem = (key) => {
        try {
            window.localStorage.removeItem(`b2c_${key}`);
        } catch (e) {
            console.log('StorageService removeItem() error', e);
        }
    };
};

export default new StorageService();
