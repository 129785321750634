import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterParams } from '../../../../../../../store/filtersSlice';
import RangeSlider from '../rangeSlider';
import FilterBlock from '../filterBlock';
import { RouteBlock, RouteCities } from './components';
import i18next from 'i18next';

const DurationFilter = () => {
    const { t, language } = i18next;
    const dispatch = useDispatch();
    const { filterParams, filtersData } = useSelector(state => state.filters);
    const { included } = useSelector(state => state.resultData);
    const { isActive, values } = filterParams.duration;
    const { routesData } = filtersData;

    const handleChange = (routeIndex, newValues) => {
        let params = JSON.parse(JSON.stringify(values));
        const { duration } = routesData.find(route => route.index === routeIndex);

        if (duration.defaultRange[0] !== newValues.min || duration.defaultRange[1] !== newValues.max) {
            const index = params.findIndex(param => param.routeIndex === routeIndex);

            if (index > -1) {
                params[index].values = newValues;
            } else {
                params.push({ routeIndex, values: newValues });
            }
        } else {
            params = params.filter(param => param.routeIndex !== routeIndex);
        }

        dispatch(
            setFilterParams({
                type: 'duration',
                values: {
                    isActive: params.length > 0,
                    values: params,
                },
            })
        );
    };

    const renderDurationTime = duration => {
        const hours = Math.trunc(duration / 3600);
        const minutes = Math.floor((duration - hours * 3600) / 60);

        return (
            <span>
                {hours} {t('hour_short')} {minutes} {t('minutes_short_3')}
            </span>
        );
    };

    const TitleComponent = (min, max) => {
        return (
            <div>
                {renderDurationTime(min)} — {renderDurationTime(max)}
            </div>
        );
    };

    const renderRoute = route => {
        const { defaultRange } = route.duration;
        const rangeValues = values.find(value => value.routeIndex === route.index)?.values || {
            min: defaultRange[0],
            max: defaultRange[1],
        };

        return (
            <RouteBlock key={`${route.routeIndex}-${route.departureCityCode}-${route.arrivalCityCode}`}>
                <RouteCities>
                    {included.city[route.departureCityCode].name[language]} →{' '}
                    {included.city[route.arrivalCityCode].name[language]}
                </RouteCities>

                <RangeSlider
                    range={{ min: defaultRange[0], max: defaultRange[1] }}
                    filter={rangeValues}
                    handleChange={value => handleChange(route.index, value)}
                    titleComponent={TitleComponent}
                />
            </RouteBlock>
        );
    };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        <FilterBlock title={capitalizeFirstLetter(t('duration'))} isOpen={isActive} borderBottom>
            {routesData.map(route => renderRoute(route))}
        </FilterBlock>
    );
};

export default DurationFilter;
