import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../../../../assets/somon_air/icons/closeIcon.svg';
import { ORANGE_COLOR, WHITE_COLOR } from '../../../../constants';
export const ButtonBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 16px;
`;

export const Button = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    color: ${WHITE_COLOR};
    border: none;
    background-color: ${ORANGE_COLOR};
    text-transform: uppercase;
    border-radius: 8px;
    padding: 8px 20px;
    font-size: 20px;
    line-height: 1;
`;

export const Icon = styled(CloseIcon)`
    margin-right: 4px;
`;
