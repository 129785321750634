import moment from 'moment-timezone';
import { mainConfigs } from '../../configs';

export const formattedDateWithTimeZone = data => {
    const timeZoneName = moment.tz.guess();
    const serverMomentTime = moment.tz(data, mainConfigs.serverTimeZone);

    return serverMomentTime.clone().tz(timeZoneName).format(mainConfigs.webTransferTimeFormat);
};

export const formattedDate = data => {
    return moment(data, mainConfigs.formatDateTime).format(mainConfigs.webTransferTimeFormat);
};
