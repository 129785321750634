import styled from 'styled-components';
import { BLACK_COLOR, ORANGE_COLOR, RUBIK_FONT, WHITE_COLOR, ZAMBEZI_COLOR } from '../../../constants';

export const Wrapper = styled.div`
    padding: 22px 30px 40px;
    background-color: ${WHITE_COLOR};
    border-radius: 5px;
    box-shadow: 0px 0px 10px ${BLACK_COLOR}33;

    @media (min-width: 900px) and (max-width: 1279px) {
        padding: 18px 20px 30px;
    }

    @media (min-width: 600px) and (max-width: 899px) {
        padding: 22px 20px 40px;
    }

    @media (max-width: 599px) {
        padding: 18px 15px 30px;
    }
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const LocalTimeInfo = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 899px) {
        display: none;
    }
`;

export const LocalTimeText = styled.div`
    margin-left: 15px;
    max-width: 220px;

    @media (min-width: 900px) and (max-width: 1279px) {
        font-size: 12px;
        max-width: 200px;
        margin-left: 10px;
    }
`;

export const RouteInfo = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 899px) {
        justify-content: space-between;
        width: 100%;
    }
`;

export const RouteDepartureCities = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;

    @media (max-width: 899px) {
        align-items: flex-start;
    }

    & > span.label {
        font-family: ${RUBIK_FONT};
        font-size: 16px;

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: 12px;
        }

        @media (max-width: 599px) {
            font-size: 12px;
        }
    }

    & > span.value {
        font-family: ${RUBIK_FONT};
        font-size: 18px;
        font-weight: 500;

        @media (min-width: 900px) and (max-width: 1279px) {
            font-size: 14px;
        }

        @media (max-width: 599px) {
            font-size: 14px;
        }
    }
`;

export const RouteDuration = styled(RouteDepartureCities)`
    @media (max-width: 899px) {
        align-items: flex-end;
    }
`;

export const VerticalLine = styled.div`
    width: 1px;
    height: 44px;
    background-color: ${ZAMBEZI_COLOR};
    margin: 0 15px;

    @media (min-width: 900px) and (max-width: 1279px) {
        height: 34px;
    }

    @media (max-width: 899px) {
        display: none;
    }
`;

export const LineDivider = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${ORANGE_COLOR};
    border-radius: 2px;
    margin: 19px 0 24px;

    @media (min-width: 900px) and (max-width: 1279px) {
        margin: 15px 0;
    }

    @media (max-width: 599px) {
        margin: 15px 0;
    }
`;

export const Segments = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;
