import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { nonAuthRoutes } from '../configs';

const PrivateRoute = () => {
    const { isAuth } = useSelector(state => state.authSettings);

    if (!isAuth) {
        return <Navigate to={nonAuthRoutes.home} />;
    }

    return <Outlet />;
};

export default PrivateRoute;
