import styled from 'styled-components';
import { MIDNIGHT_COLOR, WHITE_COLOR, ZAMBEZI_COLOR } from '../../constants';

export const Block = styled.div`
    padding: 35px 100px 45px;
    background: ${WHITE_COLOR} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px ${MIDNIGHT_COLOR};
    border-radius: 5px;

    @media (min-width: 1280px) and (max-width: 1529px) {
        padding-left: calc(20px + (100 - 20) * ((100vw - 1280px) / (1530 - 1280)));
        padding-right: calc(20px + (100 - 20) * ((100vw - 1280px) / (1530 - 1280)));
    }

    @media (min-width: 900px) and (max-width: 1279px) {
        padding-left: calc(20px + (100 - 20) * ((100vw - 900px) / (1280 - 900)));
        padding-right: calc(20px + (100 - 20) * ((100vw - 900px) / (1280 - 900)));
    }

    @media (min-width: 600px) and (max-width: 899px) {
        padding-left: calc(20px + (100 - 20) * ((100vw - 600px) / (900 - 600)));
        padding-right: calc(20px + (100 - 20) * ((100vw - 600px) / (900 - 600)));
    }

    @media (max-width: 599px) {
        padding: 25px 15px 35px;
    }
`;

export const BlockTitle = styled.div`
    text-align: center;
    font-weight: 500;
    font-family: Rubik;
    font-size: 28px;
    letter-spacing: 0px;
    color: ${ZAMBEZI_COLOR};
    margin-bottom: 35px;

    @media (max-width: 599px) {
        font-size: 20px;
        margin-bottom: 25px;
    }
`;

export const PaymentMethodsBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 1279px) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    @media (max-width: 599px) {
        grid-template-columns: 1fr;
    }
`;

export const TimeCounterBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-top: 40px;

    @media (max-width: 1279px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 28px;
        gap: 20px;
    }
`;

export const TimeLeft = styled.div`
    font: normal normal 300 15px/18px Rubik;
    letter-spacing: 0px;
    color: #7a7a7a;
`;

export const TimeExpire = styled.div`
    font: normal normal normal 15px/18px Rubik;
    letter-spacing: 0px;
    color: #7a7a7a;
`;

export const ButtonContainer = styled.div`
    width: 196px;

    @media (max-width: 599px) {
        width: 100%;
    }
`;
