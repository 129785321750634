import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setDesignSettings } from '../store/designSettingsSlice';

import Orders from '../components/somon_air/pages/orders';
import { Container } from '../components/somon_air/ui/common';
import { withTranslation } from 'react-i18next';

const OrdersPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setDesignSettings({
                isStickyHeader: true,
                showSearchBlock: true,
                isSmallSearchBlock: true,
            })
        );
    }, []);

    return (
        <Container fixed>
            <Orders />;
        </Container>
    );
};

export default withTranslation()(OrdersPage);
